import React, { useEffect, useMemo, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { HiChevronLeft } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { fetchActiveLogicalTestCategory, fetchActiveLogicalTestLanguage, fetchSingleLogicalTest, fetchSingleLogicalTestQuestion } from '../../Redux/logicalTest/actions/LogicalTestActions';
import Spiner1 from '../../Components/Spiner1';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import * as YUP from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { BiPlus, BiTrash } from 'react-icons/bi';
import AddTestCase from './AddTestCase';
import axios from '../../config/axiosNode';
import { toast } from 'react-toastify'
import { Editor } from '@monaco-editor/react';

const editorConfig = {
    toolbar: [
        ["bold", "italic", "underline", "strike", "link"], // toggled buttons
        [
            "clean",
            "blockquote",
            "code-block",
            { list: "ordered" },
            { list: "bullet" },
            { align: [] },
        ],
        [{ size: ["small", false, "large", "huge"] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        ["image"],
    ],
};


export default function AddLogicalTestQuestion({ onSidedataChange, newSidedata }) {

    const { id } = useParams()
    const { state } = useLocation()

    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [showTestCaseModal, setShowTestCaseModal] = useState(false)
    const [testCase, setTestCase] = useState([])
    const [editDetails, setEditDetails] = useState(null)

    const formState = YUP.object().shape({
        category_id: YUP.string().required("Select Question Category"),
        language_id: YUP.string().required("Select Question Language"),
        question: YUP.string().required("Provide Question title"),
        description: YUP.string().required("Provide Question description"),
        sampleCode: YUP.string().required("Provide Sample Code"),
        dynamicFields: YUP.array().of(
            YUP.object().shape({
                name: YUP.string().required("Provide Dynamic Field Name"),
            })
        ).min(1, 'Provide alt list one dynamic fields'),
        // sampleCode: YUP.string().required("Provide Sample Code"),
    })

    const { register, handleSubmit, formState: { errors }, reset, control, setValue, getValues } = useForm({
        resolver: yupResolver(formState), mode: 'all', defaultValues: {
            dynamicFields: [{ name: "" }]
        }
    })

    let { fields, append, remove } = useFieldArray({ control, name: 'dynamicFields' })

    const { data: category } = useSelector(state => state.logicalTestCategory)
    const { data: languages } = useSelector(state => state.logicalTestLanguage)
    const { data: question } = useSelector(state => state.logicalTestQuestion)

    useMemo(() => fetchActiveLogicalTestCategory(id, dispatch), [dispatch, id])
    useMemo(() => fetchActiveLogicalTestLanguage(dispatch), [dispatch])

    useEffect(() => {
        if (state && state.question_id) {
            setTestCase([])
            fetchSingleLogicalTestQuestion(state.question_id, dispatch)
        }
    }, [dispatch, state])

    useEffect(() => {
        if (question && state && state.question_id) {
            setValue('category_id', question?.data?.category)
            setValue('language_id', question?.data?.language)
            setValue('question', question?.data?.question)
            setValue('description', question?.data?.description)
            setValue('sampleCode', question?.data?.sampleCode)
            setValue('dynamicFields', [])
            if (question?.data?.dynamicFields && question?.data?.dynamicFields.length > 0) {
                question?.data?.dynamicFields.map((element, index) => {
                    if (index === 0) {
                        setValue(`dynamicFields[0]`, { name: element })
                    } else {
                        append('dynamicFields', { name: element })
                        setValue(`dynamicFields[${index}]`, { name: element })
                    }
                })
            }
        } else {
            if (category && category.data && category.data.length > 0) {
                setValue('category_id', category?.data[0]?._id)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [question, state, category, languages])

    const menuClick = () => {
        onSidedataChange(!newSidedata);
    };

    const backToPreviousPage = () => {
        window.history.back();
    };

    const handleCreateNewQuestion = () => {
        setShowModal(true);
    };

    const handleSave = () => {
        document.getElementById('save_button').click()
    }

    const handleNew = () => {
        setShowModal(false)
        reset();
    }

    const handleClose = () => {
        setEditDetails(null)
        setShowTestCaseModal(false)
    }

    const deleteTestCase = (element, case_id) => {
        // TODO: Add delete logic here
        if (state && state.question_id && case_id) {
            axios.patch(`/api/logical-test/remove-test-case/${state.question_id}/${case_id}`).then((response) => {
                if (response.data.success) {
                    toast.success(response.data.message)
                    fetchSingleLogicalTestQuestion(state.question_id, dispatch)
                } else {
                    toast.error(response.data.message)
                }
            }).catch(error => {
                toast.error(error.response ? error.response.data.message : error.toString())
                console.error(error)
            })
        } else {
            setTestCase((pre) => pre.filter((testCase, index) => index !== element))
        }
    }

    const saveTestCase = (data) => {
        setTestCase((pre) => [...pre, data])
    }

    const updateTestCase = (data, oldDetails) => {
        // TODO: Populate form with question data
        setTestCase((pre) => [...pre.filter((testCase, index) => index !== oldDetails.index), data])
    }

    const fromSubmit = (data) => {
        // TODO: Add submit logic here
        // Reset form state
        setIsLoading(true)

        if (state && state.question_id) {
            data.dynamicFields = data.dynamicFields.map((element) => element.name)
            axios.put(`/api/logical-test/update-question/${id}/${state.question_id}`, data).then((details) => {
                if (details.data.success) {
                    toast.success(details.data.message)
                    reset();
                    fetchSingleLogicalTest(id, dispatch)
                    backToPreviousPage()
                } else {
                    toast.error(details.data.message)
                }
                setIsLoading(false)
            }).catch((error) => {
                setIsLoading(false)
                toast.error(error.response ? error.response.data.message : error.toString())
                console.error(error)
            })
        } else {
            data.dynamicFields = data.dynamicFields.map((element) => element.name)
            axios.post('/api/logical-test/create-question', { ...data, test_case: testCase, test_id: id }).then((details) => {
                if (details.data.success) {
                    toast.success(details.data.message)
                    reset();
                    setTestCase([])
                    fetchSingleLogicalTest(id, dispatch)
                    backToPreviousPage()
                } else {
                    toast.error(details.data.message)
                }
                setIsLoading(false)
            }).catch((error) => {
                setIsLoading(false)
                toast.error(error.response ? error.response.data.message : error.toString())
                console.error(error)
            })
        }

    }

    const [dynamicFieldsValue, setDynamicFieldsValue] = useState([])

    const openTestCase = (element, index) => {
        if (element) {
            setEditDetails({ ...element, index });
        } else {
            setEditDetails(null);
        }
        setDynamicFieldsValue(getValues('dynamicFields'))
        setShowTestCaseModal(true)
    }

    return (
        <>
            <AddTestCase dynamicFields={dynamicFieldsValue} show={showTestCaseModal} updateTestCase={updateTestCase} editDetails={editDetails} handleClose={handleClose} saveTestCase={saveTestCase} />
            <div className="content_app">
                <div className="body_content">
                    <div className="container-fluid px-0">
                        <button className="fa_bars  d-lg-none" onClick={menuClick}>
                            <i className="fa-solid fa-bars fs-4"></i>
                        </button>
                        <div className="row g-0">
                            <div className="col-12 hjklop_right">
                                <div className="container-fluid px-0">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="Main_div">
                                                <div className="addNewQuestions px-3 px-lg-4 px-xl-5 pb-5">
                                                    <span onClick={backToPreviousPage} className="back_to_test">
                                                        <HiChevronLeft className="fs-3" /> Back To Test
                                                    </span>
                                                    <h3 className="addQuestion fw-bold my-3">
                                                        {state && state.question_id ? 'Edit Question' : 'Add New Question'}
                                                    </h3>
                                                    <form onSubmit={handleSubmit(fromSubmit)}>
                                                        <div className="row g-3">
                                                            <div className="col-xl-9 col-lg-8 col-md-7 col-12 ">
                                                                <div className="">
                                                                    <label htmlFor="category_select" className="new_label mb-1">
                                                                        Question Category
                                                                    </label>

                                                                    <select className="selector" id="category_select" {...register('category_id')}>
                                                                        {/* <option value="" default> No Category </option> */}
                                                                        {
                                                                            category && category.data && category.data.map((element) => (
                                                                                <option key={element._id} name={element?._id} value={element?._id}>
                                                                                    {element.name}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>

                                                                    {errors.category_id && <span style={{ color: "red" }}>{errors.category_id.message}</span>}
                                                                </div>

                                                                <div className="mt-3">
                                                                    <label htmlFor="category_select" className="new_label mb-1">
                                                                        Select Language
                                                                    </label>

                                                                    <select className="selector" id="category_select" {...register('language_id')}>
                                                                        <option value="" default> No Language </option>
                                                                        {
                                                                            languages && languages.data && languages.data.map((language) => (
                                                                                <option key={language._id} name={language?._id} value={language?._id}>
                                                                                    {language.name}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>

                                                                    {errors.language_id && <span style={{ color: "red" }}>{errors.language_id.message}</span>}
                                                                </div>

                                                                <div className="mt-3">
                                                                    <label htmlFor="category_select" className="new_label mb-1 justify-content-between d-flex align-items-center">
                                                                        Provide Example Code
                                                                    </label>

                                                                    <Controller control={control} name='sampleCode' render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                        <Editor height="38vh" defaultValue="// some comment" value={value} onChange={(value) => onChange(value)} />
                                                                    )}
                                                                    />
                                                                </div>


                                                                <div className="mt-3">
                                                                    <label htmlFor="category_select" className="new_label mb-1 justify-content-between d-flex align-items-center">
                                                                        Dynamic Variable
                                                                        <Button className='btn btn-success' type='button' onClick={() => append({ name: "" })}>
                                                                            Add Fields
                                                                        </Button>
                                                                    </label>

                                                                    {
                                                                        fields.map((element, i) => (
                                                                            <div className="form-group">
                                                                                <label htmlFor={`field_${i + 1}`}>Field {i + 1}</label>
                                                                                <div class="input-group mb-3">
                                                                                    <input type="text" className="form-control" id={`field_${i + 1}_name`} {...register(`dynamicFields.${i}.name`, { required: true })} />
                                                                                    {(i !== 0) && <span class="input-group-text bg-danger text-white" onClick={() => remove(i)}><BiTrash /></span>}
                                                                                    {errors.fields && errors.fields[i].name && <span style={{ color: "red" }}>{errors.fields[i].name.message}</span>}
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>

                                                            </div>
                                                            <div className="col-xl-3 col-lg-4 col-md-5 col-12 ">
                                                                <div className="top_right_div h-23 p-4">

                                                                    <div className="row parent_btn">
                                                                        {
                                                                            state && state.question_id ? (
                                                                                <button className="update" type='submit' id='save_button'>
                                                                                    {isLoading ? <Spiner1 /> : "Update Question"}
                                                                                </button>
                                                                            ) : (
                                                                                <button className="update" type='submit' id='save_button'>
                                                                                    {isLoading ? <Spiner1 /> : "Save Question"}
                                                                                </button>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="code_question my-4 border-bottom pb-3">

                                                            <h3 id="question_text" className="fw-bold mb-2">
                                                                Questions
                                                            </h3>
                                                            <div className="code_inner_question">
                                                                <label htmlFor="category_select" className="new_label mb-1">
                                                                    Question
                                                                </label>

                                                                <Controller control={control} name='question' render={({ field: { onChange, value, ref } }) => (
                                                                    <ReactQuill theme="snow" placeholder="title" modules={editorConfig} value={value} ref={ref} onChange={(value) => onChange(value)} />
                                                                )} />
                                                            </div>
                                                            {errors.question && <span style={{ color: "red" }}>{errors.question.message}</span>}


                                                            <div className="inner_question mt-3">
                                                                <label htmlFor="category_select" className="new_label mb-1">
                                                                    Question Description
                                                                </label>

                                                                <Controller control={control} name='description' render={({ field: { onChange, value, ref } }) => (
                                                                    <ReactQuill theme="snow" placeholder="description" modules={editorConfig} value={value} ref={ref} onChange={(value) => onChange(value)} />
                                                                )} />
                                                            </div>
                                                            {errors.description && <span style={{ color: "red" }}>{errors.description.message}</span>}


                                                            <div className="rectangle-parent16 mt-4">
                                                                <div className="d-md-flex align-items-center justify-content-end border-bottom mb-4 pb-2">
                                                                    <div className="displayed-in-report-container mt-2 mt-md-0">
                                                                        <button type='button' className="add-category btn btn-outline-secondary px-lg-4" onClick={() => openTestCase()}>
                                                                            <span className="me-1">Add TestCase</span>
                                                                            <BiPlus />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className="group-child37" />

                                                                {
                                                                    state ? state.question_id ? (
                                                                        <>
                                                                            {
                                                                                question && question?.data && question?.data?.test_case && question?.data?.test_case?.length > 0 ? (
                                                                                    <div className="no-questions-created">
                                                                                        <table className="table table-borderless table-sm">
                                                                                            {
                                                                                                question?.data.test_case.map((element, index) => (
                                                                                                    <tbody key={element._id} className='mb-2'>
                                                                                                        <tr className="tbl_row">
                                                                                                            <td>
                                                                                                                <b>
                                                                                                                    {
                                                                                                                        element?.input && Object.keys(element?.input) && Object.keys(element?.input).length > 0 && Object.keys(element?.input).map((items, index) => (
                                                                                                                            <>
                                                                                                                                <span key={items} className="me-1"> {items} = {element?.input[items]}</span>
                                                                                                                                {index !== Object.keys(element?.input).length - 1 && <span>,</span>}
                                                                                                                            </>
                                                                                                                        ))
                                                                                                                    }
                                                                                                                </b>
                                                                                                            </td>
                                                                                                            <td className="data_2">
                                                                                                                <b>
                                                                                                                    {element?.output}
                                                                                                                </b>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <b dangerouslySetInnerHTML={{ __html: element?.explanation }} />
                                                                                                            </td>
                                                                                                            <td className="table_icons justify-content-end">
                                                                                                                <button type='button' className="custom_table_btn edit_btn" onClick={() => openTestCase(element, index)}>
                                                                                                                    <img src="/logos/edit_logo.svg" alt="logo" />
                                                                                                                </button>
                                                                                                                <button type='button' className="custom_table_btn del_btn" onClick={() => deleteTestCase(index, element?._id)}>
                                                                                                                    <img src="/logos/delete_logo.svg" alt="logo" />
                                                                                                                </button>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                ))
                                                                                            }
                                                                                        </table>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="no-questionsCreated">
                                                                                        No test case created yet!
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                testCase && testCase?.length > 0 ? (
                                                                                    <div className="no-questions-created">
                                                                                        <table className="table table-borderless table-sm">
                                                                                            {
                                                                                                testCase.map((element, index) => (
                                                                                                    <tbody key={index} className='mb-2'>
                                                                                                        <tr className="tbl_row">
                                                                                                            <b>
                                                                                                                {
                                                                                                                    element?.input && Object.keys(element?.input) && Object.keys(element?.input).length > 0 && Object.keys(element?.input).map((items, index) => (
                                                                                                                        <>
                                                                                                                            <span key={items} className="me-1"> {items} = {element?.input[items]}</span>
                                                                                                                            {index !== Object.keys(element?.input).length - 1 && <span>,</span>}
                                                                                                                        </>
                                                                                                                    ))
                                                                                                                }
                                                                                                            </b>
                                                                                                            <td className="col-md-4 data_2">
                                                                                                                <b>
                                                                                                                    {element?.output}
                                                                                                                </b>
                                                                                                            </td>
                                                                                                            <td className="col-md-4">
                                                                                                                <b dangerouslySetInnerHTML={{ __html: element?.explanation }} />
                                                                                                            </td>
                                                                                                            <td className="table_icons justify-content-end">
                                                                                                                <button type='button' className="custom_table_btn edit_btn" onClick={() => { setEditDetails({ ...element, index }); setShowTestCaseModal(true) }}>
                                                                                                                    <img src="/logos/edit_logo.svg" alt="logo" />
                                                                                                                </button>
                                                                                                                <button type='button' className="custom_table_btn del_btn" onClick={() => deleteTestCase(index)}>
                                                                                                                    <img src="/logos/delete_logo.svg" alt="logo" />
                                                                                                                </button>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                ))
                                                                                            }
                                                                                        </table>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="no-questionsCreated">
                                                                                        No test case created yet!
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                testCase && testCase?.length > 0 ? (
                                                                                    <div className="no-questions-created">
                                                                                        <table className="table table-borderless table-sm">
                                                                                            {
                                                                                                testCase.map((element, index) => (
                                                                                                    <tbody key={index} className='mb-2'>
                                                                                                        <tr className="tbl_row">
                                                                                                            <td>
                                                                                                                <b>
                                                                                                                    {
                                                                                                                        element?.input && Object.keys(element?.input) && Object.keys(element?.input).length > 0 && Object.keys(element?.input).map((items, index) => (
                                                                                                                            <>
                                                                                                                                <span key={items} className="me-1"> {items} = {element?.input[items]}</span>
                                                                                                                                {index !== Object.keys(element?.input).length - 1 && <span>,</span>}
                                                                                                                            </>
                                                                                                                        ))
                                                                                                                    }
                                                                                                                </b>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <b>
                                                                                                                    {element?.output}
                                                                                                                </b>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <b dangerouslySetInnerHTML={{ __html: element?.explanation }} />
                                                                                                            </td>
                                                                                                            <td className="table_icons justify-content-end">
                                                                                                                <button type='button' className="custom_table_btn edit_btn" onClick={() => { setEditDetails({ ...element, index }); setShowTestCaseModal(true) }}>
                                                                                                                    <img src="/logos/edit_logo.svg" alt="logo" />
                                                                                                                </button>
                                                                                                                <button type='button' className="custom_table_btn del_btn" onClick={() => deleteTestCase(index)}>
                                                                                                                    <img src="/logos/delete_logo.svg" alt="logo" />
                                                                                                                </button>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                ))
                                                                                            }
                                                                                        </table>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="no-questionsCreated">
                                                                                        No test case created yet!
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }

                                                            </div>

                                                        </div>
                                                    </form>

                                                    <button onClick={handleCreateNewQuestion} className="btn btn-outline-secondary px-4" id="create_question">
                                                        Create New Question{" "}
                                                        <img style={{ marginLeft: "5px" }} className="vector-icon18" alt="plus" src="/logos/fa6solidplus.svg" />
                                                    </button>

                                                    <Modal show={showModal} onHide={() => setShowModal(false)}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Create New Question</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            Are you sure, you want to Create New Question?
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button variant="primary" onClick={() => handleSave()}>
                                                                Save and Create New Question
                                                            </Button>
                                                            <Button variant="secondary" onClick={() => handleNew()}>
                                                                Create New Question
                                                            </Button>

                                                        </Modal.Footer>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}