import React, { useEffect, useMemo, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Editor from "@monaco-editor/react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spiner1 from "../../../Components/Spiner1";
import { fetchActiveLogicalTestLanguage, fetchSingleLogicalTest } from "../../../Redux/logicalTest/actions/LogicalTestActions";
import { Modal, Button } from "react-bootstrap";
import { BsCheckCircle } from "react-icons/bs";
import axios from "../../../config/axiosNode";
import { toast } from "react-toastify"

const CodeExamPage = () => {
    const { testId, resultId } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [question_index, set_question_index] = useState(0)
    const [show, setShow] = useState(false)
    const [langCodeData, setLangCodeData] = useState(false);
    const [remaining_time, set_remaining_time] = useState(0)
    const [code, setCode] = useState("");
    const [execute_result, set_execute_result] = useState(null)
    const [shouldPreventNavigation, setShouldPreventNavigation] = useState(true);
    const [restrictOtherFunctions, setRestrictOtherFunctions] = useState(true);

    const { data } = useSelector(state => state.singleLogicalTest)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const langCode = () => {
        setLangCodeData(!langCodeData);
    };
    function handleEditorChange(value, event) {
        setCode(value)
    }

    // useMemo(() => setShouldPreventNavigation(true), [])
    useMemo(() => fetchSingleLogicalTest(testId, dispatch), [dispatch, testId])
    useMemo(() => fetchActiveLogicalTestLanguage(dispatch), [dispatch])

    const createCodeExam = () => {
        setIsLoading(true)
        axios.post(`api/logical-test/execute`, { language: data?.data?.questions[question_index]?.language?.name, code: code, question_id: data?.data?.questions[question_index]._id }).then((response) => {
            if (response.data.success) {
                set_execute_result(response.data.data)
            } else {
                set_execute_result(null)
            }
            setIsLoading(false)
        }).catch((error) => {
            setIsLoading(false)
            console.error(error);
            set_execute_result(null)
        })
    };

    useEffect(() => {
        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function (event) {
            window.history.pushState(null, null, window.location.href);
        };
    }, [])

    useEffect(() => {
        if (data) {
            set_remaining_time(data?.data?.duration * 60)
            setCode(data?.data?.questions[question_index]?.sampleCode)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const saveAnswer = (status) => {
        return new Promise((resolve, reject) => {
            axios.post(`api/logical-test/save-answer/${resultId}`, { question: data?.data?.questions[question_index]._id, language: data?.data?.questions[question_index]?.language?._id, code, answer: execute_result, status, duration: (data?.data?.duration - (remaining_time / 60)) }).then((response) => {
                if (response.data.success) {
                    toast.success('Answer saved successfully')
                    resolve()
                    if (status === 'COMPLETED') {
                        setShouldPreventNavigation(false);
                        setRestrictOtherFunctions(false);
                    }
                } else {
                    toast.error('Failed to save answer')
                    reject()
                }
            }).catch((error) => {
                console.error(error);
                reject(error)
            })
        })
    }

    const next_question = (current_index) => {
        if (!execute_result) {
            return toast.warning('Run your code first')
        }

        if (current_index < data?.data?.total?.question - 1) {
            saveAnswer("IN PROGRESS").then(() => {
                set_question_index(current_index + 1)
                setCode(data?.data?.questions[question_index]?.sampleCode)
                set_execute_result(null)
            }).catch((error) => {
                console.log(error)
            })
        } else {
            saveAnswer("COMPLETED").then(() => {
                setCode("")
                set_question_index(0)
                setShow(true)
                set_execute_result(null)
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    const autoSave = async () => {
        await next_question(question_index)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (remaining_time <= 0) {
                clearInterval(interval)
                setShow(true)
                autoSave()
            } else {
                set_remaining_time((pre) => pre - 1)
            }
        }, 1000);
        return () => {
            clearInterval(interval)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, remaining_time, testId])

    useEffect(() => {
        let visibilityChangeExecuted = false;
        let beforeUnloadExecuted = false;

        const handleVisibilityChange = () => {
            if (!beforeUnloadExecuted && !visibilityChangeExecuted && !show && restrictOtherFunctions) {
                if (document.visibilityState === "hidden") {
                    alert("Your Exam Is Cancelled.");
                    setTimeout(() => {
                        navigate('/');
                        window.close();
                    }, 3000);
                    setTimeout(() => {
                        saveAnswer('ABANDONED')
                    }, 100);
                    visibilityChangeExecuted = true;
                }
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        // window.addEventListener('beforeunload', beforeUnloadHandler);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            // window.removeEventListener('beforeunload', beforeUnloadHandler);
        };
    }, [navigate, restrictOtherFunctions, shouldPreventNavigation, show]);

    function secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h > 9 ? h : '0' + h : "00";
        var mDisplay = m > 0 ? m > 9 ? m : '0' + m : "00";
        var sDisplay = s > 0 ? s > 9 ? s : '0' + s : "00";
        return { hDisplay, mDisplay, sDisplay };
    }


    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="w-100  position-relative pt-3">
                            <img className="" src="/site_logo.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="container mb-5">
                <div className="row mb-3">
                    <div className="col-12 pb-3 pt-0">
                        <div className="code_test_time_box flex-wrap flex-sm-nowrap d-flex align-items-center justify-content-between">
                            <h3 className="mb-0">{data?.data?.title}</h3>
                            <div className=" d-flex align-items-center flex-wrap flex-sm-nowrap">
                                <p className="mb-0 me-3">Remaining Time</p>
                                <p className="mb-0">
                                    <span style={{ color: ((secondsToHms(remaining_time).hDisplay <= 0) && (secondsToHms(remaining_time).mDisplay <= 0)) && 'red', borderColor: ((secondsToHms(remaining_time).hDisplay <= 0) && (secondsToHms(remaining_time).mDisplay <= 0)) && 'red', backgroundColor: ((secondsToHms(remaining_time).hDisplay <= 0) && (secondsToHms(remaining_time).mDisplay <= 0)) && '#ff000038' }}>{secondsToHms(remaining_time).hDisplay}</span>
                                    <strong style={{ color: ((secondsToHms(remaining_time).hDisplay <= 0) && (secondsToHms(remaining_time).mDisplay <= 0)) && 'red', borderColor: ((secondsToHms(remaining_time).hDisplay <= 0) && (secondsToHms(remaining_time).mDisplay <= 0)) && 'red' }}>:</strong>
                                    <span style={{ color: ((secondsToHms(remaining_time).hDisplay <= 0) && (secondsToHms(remaining_time).mDisplay <= 0)) && 'red', borderColor: ((secondsToHms(remaining_time).hDisplay <= 0) && (secondsToHms(remaining_time).mDisplay <= 0)) && 'red', backgroundColor: ((secondsToHms(remaining_time).hDisplay <= 0) && (secondsToHms(remaining_time).mDisplay <= 0)) && '#ff000038' }}>{secondsToHms(remaining_time).mDisplay}</span>
                                    <strong style={{ color: ((secondsToHms(remaining_time).hDisplay <= 0) && (secondsToHms(remaining_time).mDisplay <= 0)) && 'red', borderColor: ((secondsToHms(remaining_time).hDisplay <= 0) && (secondsToHms(remaining_time).mDisplay <= 0)) && 'red' }}>:</strong>
                                    <span style={{ color: ((secondsToHms(remaining_time).hDisplay <= 0) && (secondsToHms(remaining_time).mDisplay <= 0)) && 'red', borderColor: ((secondsToHms(remaining_time).hDisplay <= 0) && (secondsToHms(remaining_time).mDisplay <= 0)) && 'red', backgroundColor: ((secondsToHms(remaining_time).hDisplay <= 0) && (secondsToHms(remaining_time).mDisplay <= 0)) && '#ff000038' }}>{secondsToHms(remaining_time).sDisplay}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row g-3">
                    <div className="col-md-6">
                        <div className="test_coding_headtext_body">
                            <div className="test_coding_headtext border-bottom mb-3">
                                <p className="mb-2 mt-3">Question No.{question_index + 1}</p>
                            </div>
                            <div className="d-flex align-items-center gap-2 justify-content-start" dangerouslySetInnerHTML={{ __html: question_index + 1 + '.' + data?.data?.questions[question_index].question }} />
                            <div className="mb-4 mt-2" dangerouslySetInnerHTML={{ __html: data?.data?.questions[question_index].description }} />
                            <div>
                                <div className="coding_test_scroll">
                                    {
                                        data?.data?.questions[question_index].test_case && data?.data?.questions[question_index].test_case.length > 0 && data?.data?.questions[question_index].test_case.map((element, index) => (
                                            <p className="mb-4" key={element._id}>
                                                <h5 className="mb-1">Example {index + 1}:</h5>
                                                <p className="d-flex flex-column gap-2">
                                                    <span> Input: {
                                                        element?.input && Object.keys(element?.input) && Object.keys(element?.input).length > 0 && Object.keys(element?.input).map((items, index) => (
                                                            <>
                                                                <span key={items} className="me-1"> {items} = {element?.input[items]}</span>
                                                                {index !== Object.keys(element?.input).length - 1 && <span>,</span>}
                                                            </>
                                                        ))
                                                    } </span>
                                                    <span> Output: {element?.output} </span>
                                                    <span className="d-flex gap-2">
                                                        Explanation:
                                                        <div dangerouslySetInnerHTML={{ __html: element?.explanation }} />
                                                    </span>
                                                </p>
                                            </p>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex flex-column g-3" style={{ gap: "15px" }}>
                        <div className="coding_edit_box position-relative">
                            <div className=" position-relative">
                                <button className="codding_btn" onClick={langCode}>
                                    {data?.data?.questions[question_index]?.language?.name}
                                </button>
                            </div>
                            <div className="codeEditor mt-3">
                                <Editor height="38vh" defaultLanguage={data?.data?.questions[question_index]?.language?.name} defaultValue="// some comment" value={code} onChange={handleEditorChange} />
                            </div>
                        </div>
                        <div className="coding_result_box">
                            <div className="result_box mt-0 border-0 flex-column d-flex justify-content-between">
                                <div>
                                    <ul className="list-unstyled d-flex align-items-center justify-content-between">
                                        <li className="d-flex align-items-center result_text me-1">
                                            <p className="border-end mb-0 pe-3">Testcase</p>
                                            <p className="ms-3 mb-0 active">Result</p>
                                        </li>
                                        <li className="d-flex align-items-center result_btn">
                                            <p className="border-end mb-0 pe-3">
                                                Time : <span>{execute_result?.cpuTime ? execute_result?.cpuTime : 0}</span>
                                            </p>
                                            <p className="ms-3 mb-0 active">
                                                Space : <span>{execute_result?.memory ? execute_result?.memory : 0}</span>
                                            </p>
                                        </li>
                                    </ul>
                                    <div className=" resalt_scroll">
                                        <div>
                                            {
                                                isLoading ? (
                                                    <h6 className="text-start">Compiling...</h6>
                                                ) : execute_result && (
                                                    <div className={`${execute_result?.error ? "text-danger" : null}`}>
                                                        <ul class="nav nav-pills mb-3 gap-3" id="pills-tab" role="tablist">
                                                            {
                                                                execute_result && execute_result.map((element, index) => (
                                                                    <li class="nav-item" role="presentation">
                                                                        <button style={{ background: element.passed ? "#1FCC1F" : "#b02a37", borderColor: element.passed ? "#1FCC1F" : "#a52834" }} class={"nav-link btn btn-danger " + ((index === 0) ? 'active' : '')} id={`pills-test-case-tab-${index}`} data-bs-toggle="pill" data-bs-target={`#pills-test-case-${index}`} type="button" role="tab" aria-controls={`pills-test-case-${index}`} aria-selected="true">
                                                                            <span style={{ color: 'white' }}>
                                                                                Test Case {index + 1}
                                                                            </span>
                                                                        </button>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>

                                                        <div class="tab-content" id="pills-tabContent">
                                                            {
                                                                execute_result && execute_result.map((element, index) => (
                                                                    <div class={"tab-pane fade" + ((index === 0) ? 'show active' : '')} id={`pills-test-case-${index}`} role="tabpanel" aria-labelledby={`pills-test-case-tab-${index}`} tabindex="0">
                                                                        <p className="mb-2">Input:  {
                                                                            element?.input && Object.keys(element?.input) && Object.keys(element?.input).length > 0 && Object.keys(element?.input).map((items, index) => (
                                                                                <>
                                                                                    <span key={items} className="me-1"> {items} = {element?.input[items]}</span>
                                                                                    {index !== Object.keys(element?.input).length - 1 && <span>,</span>}
                                                                                </>
                                                                            ))
                                                                        }</p>
                                                                        <p className="mb-2">OutPut: {element?.output}</p>
                                                                        <p className="mb-2">Expected Output: {element?.expectedOutput}</p>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-end code_run_btn">
                                    <button onClick={() => createCodeExam()}>{isLoading ? <Spiner1 /> : "Run"}</button>
                                    <button style={{ background: (question_index < data?.data?.total?.question - 1) && "#3AB2E4" }} onClick={() => next_question(question_index)}>{(question_index < data?.data?.total?.question - 1) ? "Next" : "Submit"}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    ((secondsToHms(remaining_time).hDisplay <= 0) && (secondsToHms(remaining_time).mDisplay <= 0)) ? (
                        <Modal show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header className="border-0"></Modal.Header>
                            <Modal.Body className="text-center pb-5 px-4 px-lg-5">
                                <span className="display-3 text-success">
                                    <BsCheckCircle />
                                </span>
                                <h3 className="text-primary fw-bold fw-montserrat fs-3 mt-3">
                                    Thank You!
                                </h3>
                                <p className="fs-6">
                                    Your answers have been successfully submitted. Our
                                    team will carefully review your test and get back to
                                    you as soon as possible.
                                </p>
                                <Button onClick={() => window.close()} variant="primary">
                                    You can close the tab
                                </Button>
                            </Modal.Body>
                        </Modal>
                    ) : (
                        <Modal show={show} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                            <Modal.Header className="border-0"></Modal.Header>
                            <Modal.Body className="text-center pb-5 px-4 px-lg-5">
                                <span className="display-3 text-success">
                                    <BsCheckCircle />
                                </span>
                                <h3 className="text-primary fw-bold fw-montserrat fs-3 mt-3">
                                    Thank You!
                                </h3>
                                <p className="fs-6">
                                    Your answers have been successfully submitted. Our
                                    team will carefully review your test and get back to
                                    you as soon as possible.
                                </p>
                                <Button onClick={() => window.close()} variant="primary">
                                    You can close the tab
                                </Button>
                            </Modal.Body>
                        </Modal>
                    )
                }
            </div>
        </>
    );
};

export default CodeExamPage;