import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { testById, testByIdApplicant } from '../../redux/Test/slice';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BsCheckCircle } from "react-icons/bs";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UpadateMarks, updateResult } from '../../../Redux/ResultReducer/action';

const MinuteTimer = (props) => {
  const {time, resultId, singleQuestion, appliCantAns, appliCantAnsId, testId, navigate, setRestrictOtherFunctions, setIndex, setShouldPreventNavigation, setShowModeel, setIsSubmit, index} =props
  // const testData = useSelector((state) => state.test.testData);
  // const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(0);
  const [timerFinished, setTimerFinished] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const convertToSecond = time
    setSeconds(convertToSecond)
  }, [time]);


  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(timer);
          setTimerFinished(true);
          setShowModel(true);
          return prevSeconds;
        }
        return prevSeconds - 1;
      });
    }, 1000);


    return () => clearInterval(timer);
  }, []);

useEffect(() => {
  if (timerFinished ){
    setRestrictOtherFunctions(true)
    // dispatch(updateResult(resultId, singleQuestion._id, appliCantAns, appliCantAnsId));
    dispatch(UpadateMarks(testId, resultId, navigate));
    setIndex(index);
    setShouldPreventNavigation(false);
    // setShowModeel(true);
    setIsSubmit(true)
    } 
  },[timerFinished]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };


  return (
    <div>
      {timerFinished ? (
        <>
          {/* <p className='mb-0'>Time's up!</p> */}
          {/* {seconds === 0 ?  */}
          <Modal
            show={showModel}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header className="border-0"></Modal.Header>
            <Modal.Body className="text-center pb-5 px-4 px-lg-5">
              <span className="display-3 text-success">
                <BsCheckCircle />
              </span>
              <h3 className="text-primary fw-bold fw-montserrat fs-3 mt-3">
                Thank You!
              </h3>
              <p className="fs-6 text-danger">Your Time's up!</p>
              <p className="fs-6">
                Your answers have been successfully submitted. Our
                team will carefully review your test and get back to
                you as soon as possible.
              </p>
              <Button
                onClick={() => {
                  window.close()
                }}
                variant="primary"
              >
                You can close the tab
              </Button>
            </Modal.Body>
          </Modal>
          {/* : null} */}
        </>
      ) : (
        (seconds < 60 ?
          <p className='mb-0 text-danger'>Time Remaining: {formatTime(seconds)}</p>
          :
          <p className='mb-0'>Time Remaining: {formatTime(seconds)}</p>
        )
      )}
    </div>
  );
};
export default MinuteTimer;