import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import SettingsNavbar from '../Components/SettingsNavbar';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import Avatar from '../Components/settings/Avatar';
import { toast } from 'react-toastify';
import Spiner1 from '../Components/Spiner1';
import { errorCall } from '../Redux/ErrorHandling/action';
import { useDispatch } from 'react-redux';

const UpdateUserType = (props) => {
    const navigate = useNavigate();
    const dispatch=useDispatch()
    let {id} = useParams();
    const [TypeName, setTypeName] = useState("");
    const [status1, setStatus] = useState("1");
    const [loading, setLoading] = useState();
    const getLocaldata = ()=>{
        const token = localStorage.getItem("token");
        return token
    }
    const changeSkilName = (e) => {
        setTypeName(e.target.value);
    }
    const UpdateUser = async (e) => {
        e.preventDefault();
        if (TypeName?.trim() == "") {
            toast.warning('Please enter a user name');
            return;
        }
        try {
            setLoading(true);
            const token = getLocaldata();
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
    
            const formData = {
                form: {
                    "name": TypeName,
                    "status": status1,
                    "id": id
                }
            };
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/userType/update`, formData, config).then(()=>{
            setLoading(false);
            toast.success('User name updated successfully');
            navigate("/settings/user-management")
            })
            .catch((error)=>{
                console.log(error);
            setLoading(false);
                if (error.message === "Network Error" || error.response.status === 500) {
                    dispatch(errorCall(error))
                    return;
                }
                toast.error(error?.response?.data?.message);
                if (error?.response?.data?.message === "Unauthenticated.") {
                    localStorage.removeItem("token");
                    navigate("/login");
                  }
            });
        } catch (error) {
            if (error.message === "Network Error" || error.response.status === 500) {
                dispatch(errorCall(error))
                return;
            }
            toast.error('Error while updating data');
            if (error?.response?.data?.message === "Unauthenticated.") {
                localStorage.removeItem("token");
                navigate("/login");
              }
        }
    };
    
    const fetchData = async (id) => {
        try {
            setLoading(true);
            const token = getLocaldata();
            const config = {
                headers: { Authorization: `Bearer ${token}` }
            };
    
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/auth/userType/${id}`, config);
            const responseData = response.data;
    
            return responseData;
        } catch (error) {
            if (error.message === "Network Error" || error.response.status === 500 || error.response.status === 404) {
                dispatch(errorCall(error))
                return;
            }
            if (error?.response?.data?.message === "Unauthenticated.") {
                localStorage.removeItem("token");
                navigate("/login");
              }
            throw error; 
        }finally{
            setLoading(false)
        }
    };
    
    const findData = async (id) => {
        try {
            const data = await fetchData(id);
            // setSingleData(data);
            setTypeName(data.data.name);
            setStatus(data.data.status);
        } catch (error) {
        }
    };
    useEffect(() => {
        findData(id); 
    }, [])
  return (
    <div>
       <SettingsNavbar navdata={{ header: "Update User Type", buttonText: "UserType Button", buttonPath: "/settings/skillsuggetion" }} newSidedata={props.Sidedata} newonSidedataChange={props.onSidedataChange}/>
            <div className="user-action-area">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <Link to={"/settings/user-management"}> <BsFillArrowLeftCircleFill /> User List</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="create-company-form">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="type">
                                <span className='font-weight-bold ms-4' >Type</span>
                            </div>
                            
                             <div className="form">
                                <div className="row g-2">
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="text" name="query" placeholder="Type Name"
                                                aria-label="" value={TypeName?TypeName:""} className={`form-control coustom_P h-100 ${ loading ?'placeholder':""}`} onChange={(e) => changeSkilName(e)} />
                                            <Avatar placeholder={ loading ?'placeholder':""} name={TypeName} size={"34px"} position="absolute" top="3px" left="5px" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <select className={`col-sm-4 mb-2 form-control form-control-custom ${ loading ?'placeholder':""}`} value={status1} onChange={(e) => setStatus(e.target.value)} >
                                            <option value="1">Active</option>
                                            <option value="0">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div> 
                            <button className={`btn btn-success button-outline choosed mt-3 ${loading ?'placeholder':""}`} disabled={loading} onClick={(e)=>UpdateUser(e)}>
                            {loading ? <Spiner1/> : "Update"}
                        </button>
                        </div>
                       
                    </div>
                </div>
            </div>
    </div>
  )
}

export default UpdateUserType
