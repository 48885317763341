import React, { useEffect, useRef, useState } from 'react'
import '../candidates/candidates.css'
import Detailview from "../../Components/candidate/Detailview";
import { Link, useNavigate } from 'react-router-dom'
import Select from 'react-select';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { candidate, city, designationList, experienceLevel, skills } from '../../Redux/CandidateReducer/action';
import { BiBorderAll } from 'react-icons/bi';
// import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

const AllCandidates = ({ Sidedata, onSidedataChange, setShowSidebar }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [rangeValues, setRangeValues] = useState([0, 20]);

    const [onSkillCheck, setOnSkillCheck] = useState(false);

    const [designation, setDesignation] = useState([]);
    const [skill, setSkill] = useState([]);
    const [experience, setExperience] = useState([]);
    const [cities, setCities] = useState([]);
    const [rating, setRating] = useState([]);
    const [status, setStatus] = useState([]);
    const [search, setSearch] = useState("");
    const [loader, setLoader] = useState(false);
    const [sorting, setSorting] = useState(null);
    const [expRangeValue, setExpRangeValue] = useState([0, 20]);

    const [defaultCity, setDefaultCity] = useState([]);
    const [defaultSkill, setDefaultSkill] = useState([]);
    const [defaultRating, setDefaultRating] = useState([]);
    const [defaultStatus, setDefaultStatus] = useState([]);
    const designationRef = useRef([]);
    const experienceRef = useRef([]);
    const skillRef = useRef([]);
    const [skillArray, setSkillArray] = useState([]);
    const [clearSortAndSearch, setClearSortAndSearch] = useState(false)

    const candidateLists = useSelector(state => state.Candidate_Reducer);

    const usersPermission = localStorage?.getItem("userPermission")
    const permissionList = usersPermission?.split(',');
    const [filtersChanged, setFiltersChanged] = useState(false);
    const designationOptions = candidateLists?.designationData?.map(elements => ({
        value: elements._id,
        label: elements.name,
    }));
    const experienceOptions = candidateLists?.experienceData?.map(elements => ({
        value: elements.id,
        label: elements.name
    }));
    const skillsOptions = candidateLists?.skillsData?.map(elements => ({
        value: elements.id,
        label: elements.name
    }));
    const cityOptions = candidateLists?.cityData?.map(elements => ({
        value: elements.id,
        label: elements.name
    }));

    const desigValue = designation?.map(elements => ({
        value: elements._id,
        label: elements.name
    }))
    const expertValue = experience?.map(elements => ({
        value: elements.id,
        label: elements.name
    }))
    const skillValue = skill?.map(elements => ({
        value: elements.id,
        label: elements.name
    }))

    const onCheckedSkill = (e, index) => {
        setOnSkillCheck(prevCheck => !prevCheck);
        if (skill?.length > 0) {
            let updatedSkills = skill.map((item, idx) => {
                if (index === idx) {
                    return {
                        ...item,
                        skillRequired: e.target.checked
                    };
                }
                return item;
            });
            setSkill(updatedSkills);

            const inputArray = [...skillArray]
            inputArray[index].skillRequired = e.target.checked
            setSkillArray(inputArray)
        }
    };

    // range
    const handleRangeChangeDesignation = (newValues) => {
        setRangeValues(newValues);
    };

    const handleRangeChange = (e, index) => {
        setRangeValues(prevCheck => !prevCheck);
        if (skill?.length > 0) {
            let updatedSkills = skill.map((item, idx) => {
                if (index === idx) {
                    return {
                        ...item,
                        exp_range: e,
                    };
                }
                return item;
            });

            setSkill(updatedSkills);
            const inputArray = [...skillArray]
            inputArray[index].exp_range = e
            const updatedExpRangeValue = updatedSkills?.map(e => (e?.exp_range))
            setExpRangeValue(...updatedExpRangeValue)
            setSkillArray(inputArray)
        }
    };

    const handleDesignation = (e) => {
        const values = e?.map(el => {
            const filteredArray = candidateLists?.designationData?.filter(val => val?._id === el?.value);
            return filteredArray?.length > 0 ? filteredArray[0] : null;
        });
        setDesignation(values);
    };

    const handleSkill = (e) => {
        var skillData;
        const values = e?.map(el => {
            const filteredArray = candidateLists?.skillsData?.filter((val) => { return (val?.id === el?.value) });

            if (filteredArray?.length > 0) {
                const skill = filteredArray[0];

                skillData = {
                    name: skill.name,
                    exp_range: [0, 20],
                    skillRequired: false
                }

                return {
                    ...skill,
                    exp_range: expRangeValue,
                    skillRequired: false
                };
            }
            return null;

        });
        setSkill(values)
        if (skillArray?.length > e?.length) {
            const matchedArray = skillArray.filter(skill =>
                e.some(eElement => eElement.label === skill.name)
            );
            setSkillArray(matchedArray)
        }
        else {
            setSkillArray(prev => [...prev, skillData])
        }
    }

    const handleDesignationExperience = (e) => {
        const values = e?.map(el => {
            const filteredArray = candidateLists?.experienceData?.filter(val => val?.id === el?.value);
            return filteredArray?.length > 0 ? filteredArray[0] : null;
        });
        setExperience(values)
    }

    const handleCity = (e) => {
        const values = e?.map(el => {
            const filteredArray = candidateLists?.cityData?.filter(val => val?.id === el?.value);
            return filteredArray?.length > 0 ? filteredArray[0] : null;
        });
        setCities(values)
    }

    const getRating = [
        {
            value: "1",
            label: " 1 star"
        },
        {
            value: "2",
            label: " 2 star"
        },
        {
            value: "3",
            label: " 3 star"
        },
        {
            value: "4",
            label: " 4 star"
        },
        {
            value: "5",
            label: " 5 star"
        },
    ];

    const getStatus = [
        {
            value: "New",
            label: "New"
        },
        {
            value: "Reviewed",
            label: "Reviewed"
        },
        {
            value: "Good Fit",
            label: "Good Fit"
        },
        {
            value: "Interview Scheduled",
            label: "Interview scheduled"
        },
        {
            value: "Offer made",
            label: "Offer made"
        },
        {
            value: "Hired",
            label: "Hired"
        },
        {
            value: "Rejected",
            label: "Rejected",
        },
        {
            value: "On Hold",
            label: "On Hold",
        }
    ];

    useEffect(() => {
        let obj = {}

        const data = cities?.length > 0 && cities.map((e) => { return (obj = { value: e?.id, label: e?.name }) })
        setDefaultCity(data);

        const skillData = skill?.length > 0 && skill.map((e) => { return (obj = { value: e?.id, label: e?.name }) })
        setDefaultSkill(skillData);

        const ratingData = rating?.length !== 0 && rating?.map((e) => { return (obj = { value: e, label: e }) })
        setDefaultRating(ratingData);

        const statusData = status?.length !== 0 && status?.map((e) => { return (obj = { value: e, label: e }) })
        setDefaultStatus(statusData)
    }, [cities, skill, rating, status])


    const handleRating = (e) => {
        const values = e?.map(el => (el?.value));
        setRating(values)
    }

    const handleStatus = (e) => {
        const values = e?.map(el => (el?.value));
        setStatus(values)
    }

    const handleClick = () => {
        setLoader(true);
        dispatch(candidate(search, designation.map(e => e.designation_id), skill, experience, cities, rating, status, sorting, 1, rangeValues, navigate));
    }
    const resetPage = () => {
        setClearSortAndSearch(true)
        toggleDropdown(false);
        setDesignation([]);
        setSearch("");
        setSkill([]);
        setExpRangeValue([0, 20]);
        setRangeValues([0, 20])
        setSkillArray([]);
        setExperience([]);
        setCities([]);
        setRating([]);
        setStatus([]);
        setDefaultCity([]);
        setDefaultSkill([]);
        setDefaultRating([]);
        setDefaultStatus([]);
        setLoader(true);
    }

    const resetSkill = () => {
        setSkill([]);
        skillRef.current.clearValue();
        setSkillArray([]);
    }
    const resetDesignation = () => {
        setDesignation([]);
        designationRef.current.clearValue();
        setExperience([]);
        experienceRef.current.clearValue();
    }

    useEffect(() => {
        if (loader == true) {
            dispatch(candidate(search, designation.map(e => e.designation_id), skill, experience, cities, rating, status, sorting, 1, rangeValues, navigate));
            setTimeout(() => {
                setLoader(false)
            }, 1000);
        }
    }, [loader]);

    useEffect(() => {
        setFiltersChanged(true);
    }, [designation, skill, experience, cities, rating, status, search, rangeValues]);


    useEffect(() => {
        if (filtersChanged) {
            handleClick();
            setFiltersChanged(false);
        }
    }, [filtersChanged]);


    const [viewList, updateViewList] = useState("Details");
    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const viewChange = (e) => {
        updateViewList(e);
    };
    const toggleDropdown = (e) => {
        setDropdownVisible(e);
    }

    useEffect(() => {
        dispatch(designationList(navigate));
        dispatch(experienceLevel(navigate));
        dispatch(skills(navigate));
        dispatch(city(navigate));
        let sort = sorting?.length > 0 ? sorting : ""
        dispatch(candidate(search, designation, skill, experience, cities, rating, status, sorting, 1, rangeValues, navigate));
    }, [viewList]);

    const menuClick = () => {
        onSidedataChange(!Sidedata);
    };

    const addCandidatePage = () => {
        navigate("/candidates/add-candidates");
    }

    const removeDesignationField = (id) => {
        setDesignation(designation.filter((interviewer) => interviewer.id !== id));
    };

    const removeSkillField = (id, index) => {
        setSkill(skill.filter((e) => e.id !== id));
        setSkillArray(skillArray.filter((e, i) => i !== index));
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row position-relative ">
                    <div className="col-12 mb-3 "  >
                        <div className="titel_block d-flex align-items-center justify-content-between">
                            <h2 className="mb-0 position-relative">
                                <button className="fa_bars  d-lg-none" onClick={menuClick}>
                                    <i className="fa-solid fa-bars"></i>
                                </button>     Candidates
                            </h2>
                            {
                                permissionList?.includes("60f6aebe1b38a2120c54a724") &&
                                <button className="button_choosed " onClick={addCandidatePage}>
                                    Add Candidates
                                </button>
                            }
                        </div>
                    </div>
                    {/* <div className="row g-3">
                                <div className="col-xxl-2 col-xl-2 col-md-3 col-sm-4 col-12 ">
                                    <ReactMultiSelectCheckboxes
                                    options={designationOptions}
                                    placeholderButtonLabel="Select Designation"
                                    isSelectAll={true}
                                    isSelectAllVisible={true}
                                    selectAllLabel="Select All"
                                    className="form-control w-auto mx-auto"
                                    classNamePrefix="select"
                                    onChange={handleDesignation}
                                    value={designation.map(item => ({ value: item.id, label: item.name }))}
                                    isClearable={true}
                                    />
                                </div>
                                <div className="col-xxl-2 col-xl-2 col-md-3 col-sm-4 col-12">
                                    <ReactMultiSelectCheckboxes
                                    options={experienceOptions}
                                    placeholderButtonLabel="Select Designation Experience"
                                    isSelectAll={true}
                                    isSelectAllVisible={true}
                                    selectAllLabel="Select All"
                                    className="form-control w-auto mx-auto"
                                    classNamePrefix="select"
                                    onChange={handleDesignationExperience}
                                    value={experience.map(item => ({ value: item.id, label: item.name }))}
                                    isClearable={true}
                                    />
                                </div>
                                <div className="col-xxl-2 col-xl-2 col-md-3 col-sm-4 col-12">
                                    <ReactMultiSelectCheckboxes
                                    options={skillsOptions}
                                    placeholderButtonLabel="Select Skill"
                                    isSelectAll={true}
                                    isSelectAllVisible={true}
                                    selectAllLabel="Select All"
                                    className="form-control w-auto mx-auto"
                                    classNamePrefix="select"
                                    onChange={handleSkill}
                                    isClearable={true}
                                    value={skill.map(item => ({ value: item.id, label: item.name }))}
                                    />
                                </div>
                                <div className="col-xxl-2 col-xl-2 col-md-3 col-sm-4 col-12">
                                    <ReactMultiSelectCheckboxes
                                    options={cityOptions}
                                    placeholderButtonLabel="Select City"
                                    className="form-control w-auto mx-auto"
                                    classNamePrefix="select"
                                    onChange={handleCity}
                                    value={cities.map(item => ({ value: item.id, label: item.name }))}
                                    />
                                </div>
                                <div className="col-xxl-2 col-xl-2 col-md-3 col-sm-4 col-12">
                                    <ReactMultiSelectCheckboxes
                                    options={getRating}
                                    placeholderButtonLabel="Select Rating"
                                    className="form-control w-auto  mx-auto"
                                    classNamePrefix="select"
                                    onChange={handleRating}
                                    value={rating.map((value) => ({ value, label: value }))}
                                    />
                                </div>
                                <div className="col-xxl-2 col-xl-2 col-md-3 col-sm-4 col-12">
                                    <ReactMultiSelectCheckboxes
                                    options={getStatus}
                                    placeholderButtonLabel="Select Status"
                                    className="form-control w-auto  mx-auto"
                                    classNamePrefix="select"
                                    onChange={handleStatus}
                                    value={status.map((value) => ({ value, label: value }))}
                                    />
                                </div>
                                </div> */}

                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <div className="filter_sec">
                            <div className=" filter_sec_top  p-2 cursor-pointer d-flex align-items-center justify-content-between"
                                onClick={() => toggleDropdown('designation')} style={{ backgroundColor: "#fff", borderRadius: "4px" }}>
                                <label className=" fw-bold mb-0">Designation</label> <svg className={` ${isDropdownVisible === 'designation' ? 'active' : ''}`} width="7" height="4"
                                    viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.5 4L7 0H0L3.5 4Z"
                                        fill="#032249"></path>
                                </svg>
                            </div>
                            {isDropdownVisible == false ? (designation?.length > 0 &&
                                <div className="filter_sec_bottom py-2 border-top">
                                    {designation?.length > 0 && designation?.map((data, index) => {
                                        return (
                                            <>
                                                <div className="filter_tags"><span className="filter_tag" key={index}>
                                                    {data?.name}
                                                    <span className="filter-tag-close ms-2" onClick={() => removeDesignationField(data?.id)}>X</span></span>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>) : null
                            }
                        </div>


                        {
                            isDropdownVisible === 'designation' ? (<div className="filter_dropdown">
                                <div className="row g-3">
                                    <div className="col-12 col-sm-6 col-xl-4">

                                        <Select
                                            placeholder="Select Designation"
                                            isMulti
                                            name="colors"
                                            options={designationOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={handleDesignation}
                                            defaultValue={desigValue}
                                            ref={designationRef}
                                            isClearable={true}
                                        />
                                    </div>


                                    <div className="col-12 col-sm-6 col-xl-4">
                                        <Select
                                            placeholder="Select Designation Experience"
                                            isMulti
                                            name="colors"
                                            options={experienceOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(e) => handleDesignationExperience(e)}
                                            defaultValue={expertValue}
                                            ref={experienceRef}
                                            isClearable={true}
                                        />
                                    </div>
                                    <div className="col-8 mt-3">
                                        <div className='project_status flex-wrap d-flex align-items-center justify-content-between'>
                                            <p className='mb-0 mt-4 me-3'> {designation?.map(e => e?.name).join(', ')}</p>
                                            {designation && designation?.length > 0 &&
                                                <div className='d-flex align-items-center input_range_box mt-4'>
                                                    <span className='me-3 d-flex prgress_value_years'><span>{rangeValues[0]}</span> Years</span>
                                                    <Slider
                                                        range
                                                        min={0}
                                                        max={20}
                                                        step={1}
                                                        value={rangeValues}
                                                        onChange={handleRangeChangeDesignation}
                                                    />
                                                    <span className='ms-3 d-flex prgress_value_years'><span>{rangeValues[1]}</span> Years</span>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="d-flex justify-content-end mt-3">
                                            <button type="button" className="button_choosed me-2" onClick={resetDesignation}>
                                                Reset
                                            </button>
                                            <button onClick={() => toggleDropdown(false)} type="button" className="button_remove">
                                                Close
                                            </button></div>
                                    </div>
                                </div>
                            </div>) : null
                        }
                    </div>



                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <div className="filter_sec">
                            <div
                                className=" filter_sec_top  p-2 cursor-pointer d-flex align-items-center justify-content-between" onClick={() => toggleDropdown('skill')} style={{ backgroundColor: "#fff", borderRadius: "4px" }}>
                                <label className=" fw-bold mb-0">Skill</label> <svg className={` ${isDropdownVisible === 'skill' ? 'active' : ''}`} width="7" height="4"
                                    viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M3.5 4L7 0H0L3.5 4Z"
                                        fill="#032249"></path>
                                </svg>
                            </div>
                            {isDropdownVisible == false ? (skill?.length > 0 &&
                                <div className="filter_sec_bottom py-2 border-top">
                                    {skill?.length > 0 && skill?.map((data, i) => {
                                        return (
                                            <>
                                                <div className="filter_tags"><span className="filter_tag" key={i}>

                                                    {data?.name}

                                                    <span className="filter-tag-close ms-2" onClick={() => removeSkillField(data?.id, i)}>X</span></span>
                                                </div>
                                            </>
                                        )
                                    })}

                                </div>) : null
                            }
                        </div>


                        {
                            isDropdownVisible === 'skill' ? (<div className="filter_dropdown">
                                <div className="row g-3">
                                    <div className="col-12 col-md-6 col-xl-4">

                                        <Select
                                            placeholder="Skill"
                                            isMulti
                                            name="colors"
                                            options={skillsOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={handleSkill}
                                            defaultValue={skillValue}
                                            ref={skillRef}
                                            isClearable={true}
                                        />
                                    </div>

                                    <div className=" col-12 col-md-6 col-xl-4">

                                    </div>
                                    {skillArray?.map((element, index) => (
                                        <div key={index} className="col-md-8 mt-3">
                                            <div className='project_status flex-wrap d-flex align-items-center justify-content-between'>
                                                <p className='mb-0 mt-4 me-3'>
                                                    <input type="checkbox" className='me-2' name="" onChange={(e) => onCheckedSkill(e, index)} id={`skill1${index}`} checked={element?.skillRequired} />
                                                    <label htmlFor={`skill1${index}`}>{element?.name}</label>
                                                </p>
                                                <div className='d-flex align-items-center input_range_box mt-4'>
                                                    <span className='me-3 d-flex prgress_value_years'><span>{element?.exp_range[0]}</span> Years</span>
                                                    <Slider
                                                        range
                                                        min={0}
                                                        max={20}
                                                        step={1}
                                                        onChange={(e) => handleRangeChange(e, index)}
                                                        value={element?.exp_range}
                                                    />
                                                    <span className='ms-3 d-flex prgress_value_years'><span>{element?.exp_range[1]}</span> Years</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <div className="col-12">
                                        <div className="d-flex justify-content-end mt-3">
                                            <button type="button"
                                                className="button_choosed me-2" onClick={resetSkill}>
                                                Reset
                                            </button>
                                            <button onClick={() => toggleDropdown(false)} type="button" className="button_remove">
                                                Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>) : null
                        }
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4 col-xl-2 mt-2">
                        <div className=" " onClick={() => toggleDropdown(false)}>
                            <Select
                                placeholder="City"
                                isMulti
                                name="colors"
                                options={cityOptions}
                                className="basic-multi-select new_select"
                                classNamePrefix="select"
                                onChange={handleCity}
                                value={defaultCity}
                            />
                        </div>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-4 col-xl-2 mt-2">

                        <div className=" " onClick={() => toggleDropdown(false)}>
                            <Select
                                placeholder="Rating"
                                isMulti
                                name="colors"
                                options={getRating}
                                className="basic-multi-select new_select"
                                classNamePrefix="select"
                                onChange={handleRating}
                                value={defaultRating}
                            />
                        </div>

                    </div>

                    <div className="col-12 col-sm-6 col-lg-4 col-xl-2 mt-2">

                        <div className=" " onClick={() => toggleDropdown(false)}>
                            <Select
                                placeholder="Status"
                                isMulti
                                name="colors"
                                options={getStatus}
                                className="basic-multi-select new_select"
                                classNamePrefix="select"
                                onChange={handleStatus}
                                value={defaultStatus}
                            />
                        </div>

                    </div>

                    <div className="col-12">
                        <div className="d-flex justify-content-end mt-3">
                            {/* <button type="button" className="button_choosed me-2" onClick={handleClick}>
                                Apply
                            </button> */}
                            <button type="button" className="button_remove" onClick={resetPage}>
                                Reset
                            </button>
                        </div>
                    </div>



                    <div className="col-12 mt-4">
                        <div className="d-flex justify-content-between flex-wrap pb-3 mt-3">
                            <ul className="candidate-view-nav ps-0 mb-0 list-unstyled d-flex">
                                <li className='me-3'>
                                    <Link
                                        to=""
                                        data-view="Details"
                                        title="Detail view"
                                        className={`nav-link candidates_nav_link ${viewList === "Details" ? "active" : ""
                                            }`}
                                        onClick={() => viewChange("Details")}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-layout-sidebar"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                d="M0 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3zm5-1v12h9a1 1 0 0 0 1-1V3a1 1
                         0 0 0-1-1H5zM4 2H2a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h2V2z"
                                            />
                                        </svg>
                                    </Link>
                                </li>
                                <li className='me-3'>
                                    <Link
                                        to=""
                                        data-view="list"
                                        title="List view"
                                        className={`nav-link candidates_nav_link ${viewList === "List" ? "active" : ""
                                            }`}
                                        onClick={() => viewChange("List")}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-card-list"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 
                        0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z"
                                            />
                                            <path
                                                d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 
                        0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1
                         0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z"
                                            />
                                        </svg>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to=""
                                        data-view="grid"
                                        title="Grid view"
                                        className={`nav-link candidates_nav_link ${viewList === "Grid" ? "active" : ""
                                            }`}
                                        onClick={() => viewChange("Grid")}
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-list"
                                            viewBox="0 0 16 16"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5
                             0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 
                             1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                                            />
                                        </svg>
                                    </Link>
                                </li>
                            </ul>
                            {
                                <p className="mb-0">
                                    {/* <span>{candidateLists.candidateDataAllResponse.meta?.to}</span>/ */}
                                    <span>{candidateLists?.candidateDataAllResponse?.meta?.total}</span> Candidates
                                </p>
                            }
                        </div>
                    </div>
                </div>

                <Detailview viewList={{ viewList: viewList, designation: designation, skill: skill, experience: experience, cities: cities, rating: rating, status: status, setSorting: setSorting, rangeValues: rangeValues, onSkillCheck: onSkillCheck, loader: loader, setSearch: setSearch, setShowSidebar: setShowSidebar, clearSortAndSearch: clearSortAndSearch, setClearSortAndSearch: setClearSortAndSearch, sorting: sorting, search: search }} />

            </div>

        </>
    )
}

export default AllCandidates