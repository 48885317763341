import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import ReactQuill from 'react-quill'
import { useLocation } from 'react-router-dom'
import * as YUP from 'yup'
import axios from '../../config/axiosNode'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { fetchSingleLogicalTestQuestion } from '../../Redux/logicalTest/actions/LogicalTestActions'

const editorConfig = {
    toolbar: [
        ["bold", "italic", "underline", "strike", "link"], // toggled buttons
        [
            "clean",
            "blockquote",
            "code-block",
            { list: "ordered" },
            { list: "bullet" },
            { align: [] },
        ],
        [{ size: ["small", false, "large", "huge"] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ font: [] }],
        ["image"],
    ],
};

export default function AddTestCase({ show, handleClose, saveTestCase, editDetails, updateTestCase, dynamicFields }) {

    const { state } = useLocation()
    const dispatch = useDispatch()

    const formSchema = YUP.object().shape({
        output: YUP.string().required('Provide test case output'),
        explanation: YUP.string().required('Provide test case explanation'),
    })

    const { register, handleSubmit, formState: { errors }, control, reset, setValue } = useForm({
        resolver: yupResolver(formSchema), mode: 'all', defaultValues: {
            output: editDetails?.output,
            explanation: editDetails?.explanation,
        }
    })

    useEffect(() => {
        if (editDetails) {
            setValue('output', editDetails.output)
            setValue('explanation', editDetails.explanation)
            if (dynamicFields && dynamicFields.length > 0) {
                dynamicFields.map((elements) => {
                    return setValue(`input.${elements.name}`, editDetails?.input[elements?.name])
                })
            }
        } else {
            reset()
        }
    }, [editDetails, reset, setValue, dynamicFields])

    const formSubmit = (data) => {
        if (state && state.question_id) {
            if (editDetails) {
                axios.put(`/api/logical-test/update-test-case/${state.question_id}/${editDetails._id}`, data).then((response) => {
                    if (response.data.success) {
                        toast.success(response.data.message)
                        fetchSingleLogicalTestQuestion(state.question_id, dispatch)
                        reset()
                        handleClose()
                    } else {
                        toast.error(response.data.message)
                    }
                }).catch((error) => {
                    toast.error(error.response ? error.response.data.message : error.toString())
                    console.error(error)
                })
            } else {
                axios.post(`/api/logical-test/create-test-case/${state.question_id}`, data).then((response) => {
                    if (response.data.success) {
                        toast.success(response.data.message)
                        fetchSingleLogicalTestQuestion(state.question_id, dispatch)
                        reset()
                        handleClose()
                    } else {
                        toast.error(response.data.message)
                    }
                }).catch((error) => {
                    toast.error(error.response ? error.response.data.message : error.toString())
                    console.error(error)
                })
            }
        } else {
            if (editDetails) {
                updateTestCase(data, editDetails)
            } else {
                saveTestCase(data)
            }
            reset()
            handleClose() // Close the modal after successful form submission
        }

    }

    return (
        <Modal show={show} onHide={handleClose} size='xl'>
            <Modal.Header closeButton>
                <Modal.Title>{editDetails ? 'Edit Test Case' : 'Add Test Case'}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(formSubmit)}>
                <Modal.Body>
                    <Form.Group className="mb-3">
                        <Form.Label>Test Case Input</Form.Label>
                        {
                            dynamicFields && dynamicFields.length > 0 && dynamicFields.map((elements) => (
                                <>
                                    {
                                        (editDetails && editDetails?.input) ? (
                                            <div className='mb-2 d-flex gap-5'>
                                                <Form.Control type="text" defaultValue={editDetails?.input[elements?.name]} placeholder={`Enter ${elements?.name}`} {...register(`input.[${elements?.name}]`, { required: `Provide test case ${elements?.name} values`, value: editDetails?.input[elements?.name] })} />
                                                {/* {errors[elements?.name] && (<Form.Text className="text-danger">{errors[elements?.name].message}</Form.Text>)} */}
                                            </div>
                                        ) : (
                                            <div className='mb-2 d-flex gap-5'>
                                                <Form.Control type="text" placeholder={`Enter ${elements?.name}`} {...register(`input.[${elements?.name}]`, { required: `Provide test case ${elements?.name} values` })} />
                                            </div>
                                        )
                                    }
                                </>
                            ))
                        }
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Test Case Output</Form.Label>
                        <Form.Control type="text" placeholder="Enter test case input" {...register('output')} />
                        {
                            errors.output && (
                                <Form.Text className="text-danger">
                                    {errors.output.message}
                                </Form.Text>
                            )
                        }
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Test Case Explanation</Form.Label>
                        <Controller control={control} name='explanation' render={({ field: { onChange, value, ref } }) => (
                            <ReactQuill theme="snow" placeholder="Enter test case explanation" modules={editorConfig} value={value} ref={ref} onChange={(value) => onChange(value)} />
                        )} />

                        {
                            errors.explanation && (
                                <Form.Text className="text-danger">
                                    {errors.explanation.message}
                                </Form.Text>
                            )
                        }
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button type='submit' variant="primary">
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}