export const LOGICAL_TEST_RESET = 'LOGICAL_TEST_RESET';

export const FETCH_SINGLE_LOGICAL_TEST_REQUEST = 'FETCH_SINGLE_LOGICAL_TEST_REQUEST';

export const FETCH_SINGLE_LOGICAL_TEST_SUCCESS = 'FETCH_SINGLE_LOGICAL_TEST_SUCCESS';

export const FETCH_SINGLE_LOGICAL_TEST_FAILURE = 'FETCH_SINGLE_LOGICAL_TEST_FAILURE';

export const FETCH_LOGICAL_TESTS_CATEGORY_REQUEST = 'FETCH_LOGICAL_TESTS_CATEGORY_REQUEST';

export const FETCH_LOGICAL_TESTS_CATEGORY_SUCCESS = 'FETCH_LOGICAL_TESTS_CATEGORY_SUCCESS';

export const FETCH_LOGICAL_TESTS_CATEGORY_FAILURE = 'FETCH_LOGICAL_TESTS_CATEGORY_FAILURE';

export const FETCH_LOGICAL_TESTS_LANGUAGE_REQUEST = 'FETCH_LOGICAL_TESTS_LANGUAGE_REQUEST';

export const FETCH_LOGICAL_TESTS_LANGUAGE_SUCCESS = 'FETCH_LOGICAL_TESTS_LANGUAGE_SUCCESS';

export const FETCH_LOGICAL_TESTS_LANGUAGE_FAILURE = 'FETCH_LOGICAL_TESTS_LANGUAGE_FAILURE';

export const FETCH_LOGICAL_TESTS_QUESTION_REQUEST = 'FETCH_LOGICAL_TESTS_QUESTION_REQUEST';

export const FETCH_LOGICAL_TESTS_QUESTION_SUCCESS = 'FETCH_LOGICAL_TESTS_QUESTION_SUCCESS';

export const FETCH_LOGICAL_TESTS_QUESTION_FAILURE = 'FETCH_LOGICAL_TESTS_QUESTION_FAILURE';