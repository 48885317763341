import React, { useState } from "react";
import "./createHrQuestions.css";
import { IoArrowBack, IoTrashOutline } from "react-icons/io5";
import { BsToggle2Off, BsToggle2On, BsToggleOff, BsToggleOn } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

export const CreateHrQuestion = () => {
  const navigate = useNavigate();

  // Initialize with one default multiple-choice question
  const [questions, setQuestions] = useState([
    { id: 1, type: "multiple-choice", text: "", options: ["", ""], isActive: false }
  ]);

  const handleGoBack = () => {
    navigate("/jobs/create-job");
  };

  const handleTypeChange = (index, event) => {
    const newType = event.target.value;
    setQuestions((prevQuestions) => {
      const updatedQuestions = [...prevQuestions];
      updatedQuestions[index].type = newType;
      updatedQuestions[index].options = newType === "multiple-choice" || newType === "checkbox" ? ["", ""] : [];
      return updatedQuestions;
    });
  };

  const handleQuestionChange = (index, event) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].text = event.target.value;
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, event) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = event.target.value;
    setQuestions(updatedQuestions);
  };

  const addOption = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].options.push("");
    setQuestions(updatedQuestions);
  };

  const removeOption = (questionIndex, optionIndex) => {
    if (questions[questionIndex].options.length > 2) {
      const updatedQuestions = [...questions];
      updatedQuestions[questionIndex].options.splice(optionIndex, 1);
      setQuestions(updatedQuestions);
    }
  };

  const removeQuestion = (index) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions.map((question, i) => ({ ...question, id: i + 1 })));
  };

  const addQuestion = () => {
    setQuestions((prevQuestions) => [
      ...prevQuestions,
      { id: prevQuestions.length + 1, type: "multiple-choice", text: "", options: ["", ""], isActive: false }
    ]);
  };
  const [isToggle, setIsToggle] = useState(false);
  const toggleClick = () => {
    setIsToggle(!isToggle);
  };

  return (
    <>
      <div className="d-flex container-fluid align-items-center mt-2 mb-4 justify-content-between">
        <div className="d-flex align-items-center">
          <div className="fs-4 me-2" style={{ cursor: "pointer" }} onClick={handleGoBack}>
            <IoArrowBack />
          </div>
          <div className="d-flex">
            <h5 className="fw-bold">Create Questions</h5>
          </div>
        </div>
        <div>
          <button className="btn btn-info">Save</button>
        </div>
      </div>
      <div className="question-container container-fluid">
        {questions.map((question, index) => (
          <div key={index}>
            <div className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5 className="fw-bold fs-6">Question #{question.id}</h5>
                </div>
                <div className="fs-3 d-flex align-items-center" >
                  <span style={{cursor:"pointer", color:"#D9D9D9"}} onClick={toggleClick}>{isToggle ? <BsToggleOn /> : <BsToggleOff />} </span><span className="fs-6 align-items-center ms-2" style={{color: "rgba(100, 100, 100, 0.70)"}}>Required</span>
                </div>
              </div>
              <div className="d-flex col-12 input_height">
                <input
                  type="text"
                  className="col-4 me-3 custom-border custom_input"
                  placeholder="Enter your question here"
                  value={question.text}
                  style={{ borderBottom: "1.5px solid #E2E2E2", backgroundColor: "#F9F9F9" }}
                  onChange={(e) => handleQuestionChange(index, e)}
                />

                <select
                  name=""
                  id=""
                  className="col-auto ps-2"
                  value={question.type}
                  onChange={(e) => handleTypeChange(index, e)}
                >
                  <option value="multiple-choice">Multiple Choice Question</option>
                  <option value="checkbox">Checkbox</option>
                  <option value="numeric">Numerical</option>
                  <option value="Short Answer">Short Answer</option>
                </select>
              </div>
              {question.type === "multiple-choice" || question.type === "checkbox" ? (
                <div className="col-12">
                  <div className="col-12">
                    {question.options.map((option, optionIndex) => (
                      <div className="col-12 input_height" key={optionIndex} style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                          type={question.type === "checkbox" ? "checkbox" : "radio"}
                          name={`option-${index}`}
                          id={`option-${index}-${optionIndex}`}
                          className="me-2"
                        />
                        <input
                          type="text"
                          name=""
                          id=""
                          placeholder={`Option ${optionIndex + 1}`}
                          className="custom-border col-4 h-100"
                          value={option}
                          onChange={(e) => handleOptionChange(index, optionIndex, e)}
                        />
                        {question.options.length > 2 && (
                          <p
                            className="btn_remove"
                            onClick={() => removeOption(index, optionIndex)}
                          >
                            {/* <IoTrashOutline /> */}
                            - <span>Remove Option {optionIndex + 1}</span>
                          </p>
                        )}
                      </div>
                    ))}

                  </div>
                  <div className="col-12 input_height d-flex align-items-center justify-content-between">
                    <p
                      onClick={() => addOption(index)}
                      className="ms-3"
                      style={{ cursor: 'pointer' }}
                    >
                      + Add Option {question.options.length + 1}
                    </p>
                    <button onClick={() => removeQuestion(index)} className="btn">
                      <IoTrashOutline />
                    </button>
                  </div>
                </div>
              ) : question.type === "numeric" ? (
                <div className="d-flex col-12">
                  <div className="col-4">
                    <input
                      type="text"
                      className="col-3 ps-2 mt-4"
                      disabled
                      placeholder="Enter Number"
                    />
                  </div>
                </div>
              ) : question.type === "Short Answer" ? (
                <div className="d-flex col-12">
                  <div className="col-4 pt-3">
                    <p>Enter Text</p>
                    <hr style={{ marginTop: '2px', borderTop: '1px solid black' }} />
                    <hr style={{ marginTop: '2px', borderTop: '1px solid black', marginTop:"20px" }} />
                  </div>
                </div>
              ) : null}
                { question.type === "numeric" ||  question.type === "Short Answer" ? <div className="col-12 d-flex align-items-center justify-content-end">
                <button onClick={() => removeQuestion(index)} className="btn">
                  <IoTrashOutline />
                </button>
              </div> : null}
            </div>
            <hr className="w-100" />
          </div>
        ))}
        <button className="btn btn-info mt-3" onClick={addQuestion}>
          Add Question
        </button>
      </div>
    </>
  );
};
