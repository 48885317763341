import React, { useEffect, useState } from 'react'
import '../Candiate.scss'
import CandidateHeader from '../component/CandidateHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { postResult } from '../../../Redux/ResultReducer/action'
import { getSpecificApplicant } from "../../../Redux/ApplicantReducer/action";
import Spiner1 from '../../../Components/Spiner1'

const Instruction = (props) => {
    const candidateData = useSelector((state) => state.applicant.data);
    const loading = useSelector((state) => state.resultData.isLoadingPost);
    const dispatch = useDispatch()
    const { testId, id, type } = useParams()
    const [isChecked, setIsChecked] = useState(false)
    const navigate = useNavigate()

    const isAgree = () => {
        if (isChecked === true) {
            dispatch(postResult(navigate, testId, id, type))
        } else {
            toast.warn("Please Accept The T&C")
        }
    }

    const checkTandC = () => {
        if (isChecked === false) {
            setIsChecked(true)
        } else {
            setIsChecked(false)
        }
    }

    useEffect(() => {
        dispatch(getSpecificApplicant(id, navigate))
    }, [dispatch, id, navigate])

    useEffect(() => {
        // Disable right-click context menu
        document.addEventListener('contextmenu', function (e) {
            e.preventDefault();
        }, false);

        // Disable F12 key
        document.addEventListener('keydown', function (e) {
            if (e.key === 'F12') {
                e.preventDefault();
            }
        }, false);

        // Disable Ctrl+Shift+I (or Cmd+Opt+I on Mac)
        document.addEventListener('keydown', function (e) {
            if ((e.ctrlKey || e.metaKey) && e.shiftKey && (e.key === 'I' || e.key === 'i')) {
                e.preventDefault();
            }
        }, false);

        // Disable Ctrl+Shift+J (or Cmd+Opt+J on Mac)
        document.addEventListener('keydown', function (e) {
            if ((e.ctrlKey || e.metaKey) && e.shiftKey && (e.key === 'J' || e.key === 'j')) {
                e.preventDefault();
            }
        }, false);
    }, [])

    return (
        <section className='log_in instruction_area candidate'>
            <div className="container">
                <CandidateHeader />
                <div className="login_area">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="log_in_box p-4 p-lg-5 border-0 box-shadow-sm text-start mb-4">
                                <img src="site_logo.png" alt="" className='img-fluid mobile_logo mx-auto mb-3' />
                                <h3>Instructions :</h3>
                                <h4 className='mb-3'> <span className='text-primary'> Welcome </span>{candidateData?.name}!</h4>
                                <p>Here, you will find all the necessary information and guidelines to ensure a smooth and successful examination experience. Please read the following instructions carefully before proceeding:</p>
                                <div className="instruction_block my-3">
                                    <h6>Technical Requirements:</h6>
                                    <ul>
                                        <li>Ensure that you have a stable internet connection throughout the examination.</li>
                                        <li>It is recommended to use a desktop or laptop computer for the best experience.</li>
                                        <li>Make sure your web browser is up to date. We recommend using Google Chrome or Mozilla Firefox for optimal performance.</li>
                                        <li>Disable any browser extensions or plugins that might interfere with the exam interface.</li>
                                        <li>Clear your browser cache before starting the exam.</li>
                                    </ul>
                                </div>
                                <div className="instruction_block my-3">
                                    <h6>Exam Guidelines:</h6>
                                    <ul>
                                        <li>Strictly adhere to the exam rules and guidelines provided. Failure to comply may result in disqualification.</li>
                                        <li>Do not attempt to access any unauthorized materials or resources during the exam.</li>
                                        <li>Ensure that you are in a quiet and distraction-free environment during the exam.</li>
                                        <li>Avoid any form of communication or collaboration with other individuals during the exam, unless explicitly stated otherwise.</li>
                                    </ul>
                                </div>
                                <div className="instruction_block my-3">
                                    <h6>Time Management:</h6>
                                    <ul>
                                        <li>Keep track of the time remaining for the exam. A countdown timer will be displayed on the exam interface.</li>
                                        <li>Pace yourself accordingly to complete all the required tasks within the allocated time.</li>
                                        <li>Submit your answers before the timer reaches zero. Late submissions may not be accepted.</li>
                                    </ul>
                                </div>
                                <div className="instruction_block my-3">
                                    <h6>Answer Submission:</h6>
                                    <ul>
                                        <li>Save your progress regularly during the exam using the provided "Save" or "Submit" buttons.</li>
                                        <li>Verify that your answers are correctly saved before the exam concludes.</li>
                                        <li>If the exam has multiple sections or pages, make sure to navigate through them correctly and submit all required sections.</li>
                                    </ul>
                                </div>
                                <div className="instruction_block my-3">
                                    <h6>Exam Completion:</h6>
                                    <ul>
                                        <li>Once you have submitted your exam, a confirmation message will be displayed.</li>
                                        <li>You may review your answers, if allowed, before exiting the exam.</li>
                                        <li>Close the exam interface only when you have completed the exam and reviewed your answers.</li>
                                    </ul>
                                </div>
                                <p className='text-dark fs-6 my-4'>We hope these instructions provide you with a clear understanding of how to navigate and complete your exam on the Exam Website. If you have any further questions or concerns, don't hesitate to contact our <a className='text-primary'>support team </a> for assistance. Best of luck with your examination!</p>
                                <div className='d-flex align-items-center'>
                                    <input type="checkbox" id="agree_instruction" className='form-check' onChange={checkTandC} />
                                    <label htmlFor="agree_instruction" className='form-label ms-2 mb-0'>I have read all the instructions and guidelines provided here.</label>
                                </div>
                                <div className="mt-5 text-center">
                                    <button type="button" className='btn btn-primary' disabled={loading} onClick={isAgree}>{loading ? <Spiner1 /> : "Continue"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Instruction;