import axios from "../../../config/axiosNode"
import { FETCH_LOGICAL_TESTS_CATEGORY_FAILURE, FETCH_LOGICAL_TESTS_CATEGORY_REQUEST, FETCH_LOGICAL_TESTS_CATEGORY_SUCCESS, FETCH_LOGICAL_TESTS_LANGUAGE_FAILURE, FETCH_LOGICAL_TESTS_LANGUAGE_REQUEST, FETCH_LOGICAL_TESTS_LANGUAGE_SUCCESS, FETCH_LOGICAL_TESTS_QUESTION_FAILURE, FETCH_LOGICAL_TESTS_QUESTION_REQUEST, FETCH_LOGICAL_TESTS_QUESTION_SUCCESS, FETCH_SINGLE_LOGICAL_TEST_FAILURE, FETCH_SINGLE_LOGICAL_TEST_REQUEST, FETCH_SINGLE_LOGICAL_TEST_SUCCESS, LOGICAL_TEST_RESET } from "../constants/LogicalTestConstants"

export const fetchSingleLogicalTest = async (data, dispatch) => {
    try {
        dispatch({ type: FETCH_SINGLE_LOGICAL_TEST_REQUEST })

        axios.post(`/api/get-single/${data}`).then((response) => {
            if (response.data.success) {
                dispatch({ type: FETCH_SINGLE_LOGICAL_TEST_SUCCESS, payload: response.data })
            } else {
                dispatch({ type: FETCH_SINGLE_LOGICAL_TEST_FAILURE, payload: response.data.message })
            }
        }).catch((error) => {
            if (error.response) {
                dispatch({ type: FETCH_SINGLE_LOGICAL_TEST_FAILURE, payload: error.response.data.message })
            } else {
                console.log(error);
            }
        })

    } catch (error) {
        if (error.response) {
            dispatch({ type: FETCH_SINGLE_LOGICAL_TEST_FAILURE, payload: error.response.data.message })
        } else {
            console.log(error);
        }
    }
}

export const fetchActiveLogicalTestCategory = async (data, dispatch) => {
    try {
        dispatch({ type: FETCH_LOGICAL_TESTS_CATEGORY_REQUEST })

        axios.get(`/api/logical-test/get-active-all-category/${data}`).then((response) => {
            if (response.data.success) {
                dispatch({ type: FETCH_LOGICAL_TESTS_CATEGORY_SUCCESS, payload: response.data })
            } else {
                dispatch({ type: FETCH_LOGICAL_TESTS_CATEGORY_FAILURE, payload: response.data.message })
            }
        }).catch((error) => {
            if (error.response) {
                dispatch({ type: FETCH_LOGICAL_TESTS_CATEGORY_FAILURE, payload: error.response.data.message })
            } else {
                console.log(error);
            }
        })

    } catch (error) {
        if (error.response) {
            dispatch({ type: FETCH_LOGICAL_TESTS_CATEGORY_FAILURE, payload: error.response.data.message })
        } else {
            console.log(error);
        }
    }
}

export const fetchActiveLogicalTestLanguage = async (dispatch) => {
    try {
        dispatch({ type: FETCH_LOGICAL_TESTS_LANGUAGE_REQUEST })

        axios.get(`/api/logical-test/get-active-all-language`).then((response) => {
            if (response.data.success) {
                dispatch({ type: FETCH_LOGICAL_TESTS_LANGUAGE_SUCCESS, payload: response.data })
            } else {
                dispatch({ type: FETCH_LOGICAL_TESTS_LANGUAGE_FAILURE, payload: response.data.message })
            }
        }).catch((error) => {
            if (error.response) {
                dispatch({ type: FETCH_LOGICAL_TESTS_LANGUAGE_FAILURE, payload: error.response.data.message })
            } else {
                console.log(error);
            }
        })

    } catch (error) {
        if (error.response) {
            dispatch({ type: FETCH_LOGICAL_TESTS_LANGUAGE_FAILURE, payload: error.response.data.message })
        } else {
            console.log(error);
        }
    }
}

export const fetchSingleLogicalTestQuestion = (data, dispatch) => {
    try {
        dispatch({ type: FETCH_LOGICAL_TESTS_QUESTION_REQUEST })

        axios.get(`/api/logical-test/get-single-question/${data}`).then((response) => {
            if (response.data.success) {
                dispatch({ type: FETCH_LOGICAL_TESTS_QUESTION_SUCCESS, payload: response.data })
            } else {
                dispatch({ type: FETCH_LOGICAL_TESTS_QUESTION_FAILURE, payload: response.data.message })
            }
        }).catch((error) => {
            if (error.response) {
                dispatch({ type: FETCH_LOGICAL_TESTS_QUESTION_FAILURE, payload: error.response.data.message })
            } else {
                console.log(error);
            }
        })

    } catch (error) {
        if (error.response) {
            dispatch({ type: FETCH_LOGICAL_TESTS_LANGUAGE_FAILURE, payload: error.response.data.message })
        } else {
            console.log(error);
        }
    }
}

export const resetLogicalTest = (dispatch) => {
    dispatch({ type: LOGICAL_TEST_RESET });
}