import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from '../../config/axiosNode'
import moment from 'moment'
import { Editor } from '@monaco-editor/react'

export default function LogicalAnswerSheet(props) {

    const [isLoading, setIsLoading] = useState(false)
    const [testResult, setTestResult] = useState(null)

    const { id } = useParams()

    const getLogicalTestResult = useCallback(() => {
        setIsLoading(true)
        axios.get(`api/logical-test/get-single-result/${id}`).then((response) => {
            if (response.data.success) {
                setIsLoading(false)
                setTestResult(response.data.data)
            } else {
                setIsLoading(false)
                console.error(response.data.message)
            }
        }).catch((error) => {
            setIsLoading(false)
            console.error(error.message)
        })
    }, [id])

    useMemo(() => getLogicalTestResult(), [getLogicalTestResult])

    const menuClick = () => {
        props.onSidedataChange(!props.newSidedata);
    };

    return (
        <>
            {
                isLoading ? (
                    <div className="d-flex align-items-center justify-content-center" style={{ textAlign: "center", height: "100px" }}>
                        <div className="loader"></div>
                    </div>
                ) : (
                    <section className="answer_sheet">
                        <div className="container">
                            <button className="fa_bars  d-lg-none" onClick={menuClick}>
                                <i className="fa-solid fa-bars fs-4"></i>
                            </button>
                            <div className="answer_sheet_header border-bottom py-4">
                                <h3 className="fw-bold">{testResult?.test?.title}</h3>
                                <h5 className="mb-4">
                                    Passed By{" "}
                                    <span className="fw-bold text-primary">
                                        {testResult?.candidate?.name}
                                    </span>
                                </h5>
                                {/* <p>
                                    The green <span className="badge bg-success">background</span>{" "}
                                    indicated the correct answers{" "}
                                </p>
                                <div className="error_check">
                                    <div className="form-check right_answer">
                                        <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckDefault" defaultChecked="true" disabled />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            Selected green checkboxes indicate{" "}
                                            <span className="fw-bold">
                                                the right answers given by the candidate{" "}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="form-check wrong_answer">
                                        <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked" defaultChecked="true" disabled />
                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                            Selected red checkboxes indicate{" "}
                                            <span className="fw-bold">
                                                the wrong answers given by the candidate{" "}
                                            </span>
                                        </label>
                                    </div>
                                </div> */}
                            </div>

                            {
                                testResult?.score.map((Question, quesNum) => (
                                    <div key={quesNum} className="answer_sheet_body py-4 border-bottom">
                                        <h1 className="fs-4">
                                            {" "}
                                            <span className="fw-bold">
                                                Question # {quesNum + 1}
                                            </span>{" "}
                                            <span className="fs-6 text-secondary">
                                                (Attention To Detail)
                                            </span>
                                        </h1>
                                        <div className="bg-light border-radius mt-3 mb-4 p-3 border">
                                            <div className="row g-3 justify-content-between">
                                                <div className="col-auto">
                                                    <h6 className="mb-0">
                                                        Score -{" "}
                                                        <span className="fw-bold">
                                                            {(Question?.totalScore).toFixed()}/ {(100 / testResult?.test?.total?.question).toFixed()}
                                                        </span>
                                                    </h6>
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="mb-0">
                                                        Duration -{" "}
                                                        <span className="fw-bold">
                                                            {Question?.duration?.toFixed(2)}
                                                        </span>
                                                    </h6>
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="mb-0">
                                                        Date -{" "}
                                                        <span className="fw-bold"> {moment(Question?.attendTime).format('DD-MM-YYYY')}</span>
                                                    </h6>
                                                </div>
                                                <div className="col-auto">
                                                    <h6 className="mb-0">
                                                        Time -{" "}
                                                        <span className="fw-bold">{moment(Question?.attendTime).format('hh:mm A')}</span>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="answer_sheet_footer py-4">
                                            <span className="fw-bold">
                                                Question:
                                            </span>{" "}
                                            <h6 className='mb-2' dangerouslySetInnerHTML={{ __html: Question?.question?.question }} />
                                            <h6 className='mb-2' dangerouslySetInnerHTML={{ __html: Question?.question?.description }} />

                                            <span className="fw-bold mb-2">
                                                Candidate Code:
                                            </span>

                                            <Editor height="38vh" defaultLanguage={Question?.language?.name} defaultValue="// some comment" value={Question?.code} />

                                            {
                                                Question?.answer.map((AnsOption, OptionNum) => (
                                                    <div key={AnsOption._id} className="wrong_right_area error_check mt-4">
                                                        {/* <span className="me-2">
                                                            {OptionNum + 1}
                                                            {".   "}
                                                        </span>{" "} */}
                                                        <div className='py-2 px-3 mb-3'>
                                                            <span className="fw-bold">
                                                                Answer:
                                                            </span>{" "}
                                                        </div>

                                                        <div className='py-2 px-3 mb-3'>
                                                            <span className="fw-bold">
                                                                Input:
                                                            </span>
                                                            <b>
                                                                {
                                                                    AnsOption?.input && Object.keys(AnsOption?.input) && Object.keys(AnsOption?.input).length > 0 && Object.keys(AnsOption?.input).map((items, index) => (
                                                                        <>
                                                                            <span key={items} className="me-1"> {items} = {AnsOption?.input[items]}</span>
                                                                            {index !== Object.keys(AnsOption?.input).length - 1 && <span>,</span>}
                                                                        </>
                                                                    ))
                                                                }
                                                            </b>
                                                        </div>

                                                        <div className={AnsOption.passed ? "bg_success_light border-radius border py-2 px-3 mb-3" : 'bg-danger border-radius border py-2 px-3 mb-3'}>
                                                            <div className="form-check mb-0 right_answer d-flex align-items-center gap-2 mb-2">
                                                                <span className="fw-bold">
                                                                    Output:
                                                                </span>{" "}
                                                                <label className="form-check-label form_check_label d-flex align-items-center fw-semi" htmlFor="flexCheckDefault" dangerouslySetInnerHTML={{ __html: AnsOption?.userOutput?.replace(/\r\n/g, '<br />') }} />
                                                            </div>

                                                            <div className="form-check mb-0 right_answer d-flex align-items-center gap-2">
                                                                <span className="fw-bold">
                                                                    Expected Output:
                                                                </span>{" "}
                                                                <label className="form-check-label form_check_label d-flex align-items-center fw-semi" htmlFor="flexCheckDefault" dangerouslySetInnerHTML={{ __html: AnsOption?.expectedOutput?.replace(/\r\n/g, '<br />') }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </section>
                )}
        </>
    )
}