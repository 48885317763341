export const POST_TEST_REQUEST = "POST_TEST_REQUEST"
export const POST_TEST_SUCCESS = "POST_TEST_SUCCESS"
export const POST_TEST_FAILURE = "POST_TEST_FAILURE"

export const GET_TEST_REQUEST = "GET_TEST_REQUEST"
export const GET_TEST_SUCCESS = "GET_TEST_SUCCESS"
export const GET_TEST_FAILURE = "GET_TEST_FAILURE"


export const CREATE_TEST_REQUEST = "CREATE_TEST_REQUEST"
export const CREATE_TEST_SUCCESS = "CREATE_TEST_SUCCESS"
export const CREATE_TEST_FAILURE = "CREATE_TEST_FAILURE"

// export const TEST_FILTER_PROGRAM_REQUEST = "TEST_FILTER_PROGRAM_REQUEST"
// export const TEST_FILTER_PROGRAM_SUCCESS = "TEST_FILTER_PROGRAM_SUCCESS"
// export const TEST_FILTER_PROGRAM_FAILURE = "TEST_FILTER_PROGRAM_FAILURE"

export const TEST_FILTER_CUSTOM_REQUEST = "TEST_FILTER_REQUEST"
export const TEST_FILTER_CUSTOM_SUCCESS = "TEST_FILTER_SUCCESS"
export const TEST_FILTER_CUSTOM_FAILURE = "TEST_FILTER_FAILURE"

export const TEST_BY_ID_REQUEST = "TEST_BY_ID_REQUEST"
export const TEST_BY_ID_SUCCESS = "TEST_BY_ID_SUCCESS"
export const TEST_BY_ID_FAILURE = "TEST_BY_ID_FAILURE"


export const TEST_BY_APPLICANT_ID_REQUEST = "TEST_BY_APPLICANT_ID_REQUEST"
export const TEST_BY_APPLICANT_ID_SUCCESS = "TEST_BY_APPLICANT_ID_SUCCESS"
export const TEST_BY_APPLICANT_ID_FAILURE = "TEST_BY_APPLICANT_ID_FAILURE"

// export const STATE_CHANGE_REQUEST = "STATE_CHANGE_REQUEST"
// export const STATE_CHANGE_SUCCESS = "STATE_CHANGE_SUCCESS"
// export const STATE_CHANGE_FAILURE = "STATE_CHANGE_FAILURE"

// export const ARCHIVE_CHANGE_REQUEST = "ARCHIVE_CHANGE_REQUEST"
// export const ARCHIVE_CHANGE_SUCCESS = "ARCHIVE_CHANGE_SUCCESS"
// export const ARCHIVE_CHANGE_FAILURE = "ARCHIVE_CHANGE_FAILURE"


export const CREATE_TOKEN_REQUEST = "CREATE_TOKEN_REQUEST"
export const CREATE_TOKEN_SUCCESS = "CREATE_TOKEN_SUCCESS"
export const CREATE_TOKEN_FAILURE = "CREATE_TOKEN_FAILURE"

export const SEND_MAIL_REQUEST = "SEND_MAIL_REQUEST"
export const SEND_MAIL_SUCCESS = "SEND_MAIL_SUCCESS"
export const SEND_MAIL_FAILURE = "SEND_MAIL_FAILURE"

// export const CATEGORY_BY_TEST_REQUEST = "CATEGORY_BY_TEST_REQUEST"
// export const CATEGORY_BY_TEST_SUCCESS = "CATEGORY_BY_TEST_SUCCESS"
// export const CATEGORY_BY_TEST_FAILURE = "CATEGORY_BY_TEST_FAILURE"


export const DELETED_TEST_REQUEST = "DELETED_TEST_REQUEST"
export const DELETED_TEST_SUCCESS = "DELETED_TEST_SUCCESS"
export const DELETED_TEST_FAILURE = "DELETED_TEST_FAILURE"

export const DELETE_TEST_PERMANENTLY_REQUEST = "DELETED_TEST_PERMANENTLY_REQUEST"
export const DELETE_TEST_PERMANENTLY_SUCCESS = "DELETED_TEST_PERMANENTLY_SUCCESS"
export const DELETE_TEST_PERMANENTLY_FAILURE = "DELETED_TEST_PERMANENTLY_FAILURE"

export const TEST_SEARCH_REQUEST = "TEST_SEARCH_REQUEST"
export const TEST_SEARCH_SUCCESS = "TEST_SEARCH_SUCCESS"
export const TEST_SEARCH_FAILURE = "TEST_SEARCH_FAILURE"

export const LOGICAL_TEST_REQUEST = "LOGICAL_TEST_REQUEST"
export const LOGICAL_TEST_SUCCESS = "LOGICAL_TEST_SUCCESS"
export const LOGICAL_TEST_FAILURE = "LOGICAL_TEST_FAILURE"

export const TEST_DUPLICATE_REQUEST = "TEST_DUPLICATE_REQUEST"
export const TEST_DUPLICATE_SUCCESS = "TEST_DUPLICATE_SUCCESS"
export const TEST_DUPLICATE_FAILURE = "TEST_DUPLICATE_FAILURE"