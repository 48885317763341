import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { fetchSingleLogicalTest } from '../../Redux/logicalTest/actions/LogicalTestActions'
import { useDispatch } from 'react-redux'
import axios from '../../config/axiosNode'
import { toast } from 'react-toastify'
import { HiChevronLeft } from 'react-icons/hi'
import Spiner1 from '../../Components/Spiner1'

export default function AddLogicalTestCategory({ onSidedataChange, newSidedata }) {

    const { state } = useLocation()

    const menuClick = () => {
        onSidedataChange(!newSidedata);
    };

    const backToPreviousPage = () => {
        window.history.back();
    };

    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState('')

    useEffect(() => {
        if (state?.category) {
            setName(state?.category?.name)
        }
    }, [state])

    const { id } = useParams()
    const dispatch = useDispatch()

    const createCategory = (event) => {
        event.preventDefault()

        if (name === '') {
            return toast.error('Provide category name')
        }
        setIsLoading(true)

        if (state?.category) {
            axios.put(`/api/logical-test/update-category/${id}/${state?.category?._id}`, { name }).then((response) => {
                if (response.data.success) {
                    toast.success(response.data.message)
                    setName('')
                    fetchSingleLogicalTest(id, dispatch)
                    backToPreviousPage()
                } else {
                    toast.error(response.data.message)
                }
                setIsLoading(false)
            }).catch((error) => {
                setIsLoading(false)
                toast.error(error.response ? error.response.data.message : error.toString())
                console.error(error)
            })
        } else {
            axios.post(`/api/logical-test/create-category/${id}`, { name }).then((response) => {
                if (response.data.success) {
                    toast.success(response.data.message)
                    setName('')
                    fetchSingleLogicalTest(id, dispatch)
                    backToPreviousPage()
                } else {
                    toast.error(response.data.message)
                }
                setIsLoading(false)
            }).catch((error) => {
                setIsLoading(false)
                toast.error(error.response ? error.response.data.message : error.toString())
                console.error(error)
            })
        }

    }

    const onEnterEdit = (event) => {
        if (event.key === "Enter") {
            if (!isLoading) {
                createCategory(event);
            }
        }
    };

    return (
        <div className="body_content">
            <div className="container-fluid">
                <button className="fa_bars  d-lg-none" onClick={menuClick}>
                    <i className="fa-solid fa-bars fs-4"></i>
                </button>
                <div className="row">
                    <div className="COL-12 hjklop_right">
                        <div className="container-fluid px-0 px-lg-4 my-4 my-lg-5">
                            <div className="top_header border-bottom pb-2">
                                <div className="d-flex align-items-center">
                                    <img id="top_icon" className="gray_round " src="/logos/update_test.png" alt="img" />
                                    <Link onClick={backToPreviousPage} className="back_to_text fw-bold text-dark d-flex align-items-center mt-0">
                                        <HiChevronLeft className="fs-3" />
                                        Back To Test
                                    </Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-6 col-lg-8">
                                    <div className="inner_div my-4">
                                        <div className="">
                                            <b className="add_new_text">{state?.category ? 'Edit Category' : 'Add New Category'}</b>
                                        </div>
                                        <div className="my-3">
                                            <input type="text" className="single_input form-control" placeholder="Please Enter Category Here" value={name} onChange={(e) => setName(e.target.value)} onKeyDown={onEnterEdit} />
                                        </div>
                                        <div className="">
                                            {
                                                state?.category ? (
                                                    <button id="save_btn" disabled={isLoading} onClick={createCategory}>
                                                        {isLoading ? <Spiner1 /> : "Edit"}
                                                    </button>
                                                ) : (
                                                    <button id="save_btn" disabled={isLoading} onClick={createCategory}>
                                                        {isLoading ? <Spiner1 /> : "Save"}
                                                    </button>
                                                )
                                            }
                                            <button onClick={(e) => backToPreviousPage()} id="cancel_btn">
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}