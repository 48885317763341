import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './App.css';
import './TestPortal/App.scss'
import Sidebar from "./Components/Sidebar";
import Settings from './Pages/Settings';
import Skill from './Pages/Skill';
import Login from "./Components/Authentication/Login";
import AllCandidates from './Pages/candidates/AllCandidates';

import Notfound from './Pages/Notfound/Notfound';
import Designation from './Pages/Designation';
import Experience from './Pages/Experience';
import SourceManagement from './Pages/SourceManagement';
import ProfileSettings from './Pages/ProfileSettings';
import Userlist from './Pages/user/Userlist';
import UserPermition from './Pages/UserPermition';
import UserTypeManagement from "./Pages/UserTypeManagement";
import MenuManagement from './Pages/MenuManagement';
import AddSkill from './Pages/AddSkill';
import UserCreate from './Pages/user/UserCreate';
import CreateCandidate from './Pages/candidates/CreateCandidate';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateJobs from './Pages/jobs/CreateJobs';
import JobsList from './Pages/jobs/JobsList';
import AddDesignation from './Pages/AddDesignation';
import UpdateSkill from './Pages/UpdateSkill';
import UpdateDesignation from './Pages/UpdateDesignation';
import UpdateExperience from './Pages/UpdateExperience';
import AddSource from './Pages/AddSource';
import UpdateSource from './Pages/UpdateSource';
import UpdateUserPermission from './Pages/UpdateUserPermission';
import AddUserType from './Pages/AddUserType';
import UpdateUserType from './Pages/UpdateUserType';
import AddMenu from './Pages/AddMenu';
import Candidateprofile from './Pages/candidates/Candidateprofile';
import CandidateProfiles from './Pages/candidates//CandidateProfiles';
import Dashboard from '../src/Pages/Home/Dashbord';
import AddExperience from './Pages/AddExperience';
import UpdateMenu from './Pages/UpdateMenu';
import EditCandidate from './Pages/candidates/EditCandidate';
import AnswerSheet from '../src/TestPortal/components/AnswerSheet'
import 'react-toastify/dist/ReactToastify.css';
import TestScreen from './TestPortal/components/TestScreen';
import TestResult from './TestPortal/components/TestResult';
import InnerTest from './TestPortal/components/InnerTest';
import LoginTest from './TestPortal/candidate/auth/Login';
import CreateTest from './TestPortal/components/CreateTest';
import AddCategory from './TestPortal/components/AddCategory';
import AddQuestions from './TestPortal/components/AddQuestions';
import EditQuestion from './TestPortal/components/EditQuestion';
import AddTest from './TestPortal/components/AddTest';
import CreateTemplate from './TestPortal/components/CreateTemplate';
import TemplateList from './TestPortal/components/TemplateList';
import Instruction from "./TestPortal/candidate/instruction/Instruction";
import ExamPage from './TestPortal/candidate/exam/ExamPage';
import NetworkErrorPage from './Pages/error/error';
import PythonTest from './Pages/PythonTest';
import CodeExamPage from './TestPortal/candidate/exam/CodeExamPage';
import { useSelector } from 'react-redux';
import Permission from './middleware/Permission';
import CallRecording from './Components/candidate/CallRecording';
import TrashBin from './TestPortal/components/TrashBin';
import JobsViwer from './Pages/jobs/JobsViwer';
import JobListNew from './Pages/jobs/JobListNew';
import ApplyJobs from "./Pages/jobs/ApplyJobs"
import LogicalTestCreate from './Pages/logical-test/LogicalTestCreate';
import AddLogicalTestCategory from './Pages/logical-test/AddLogicalTestCategory';
import AddLogicalTestQuestion from './Pages/logical-test/AddLogicalTestQuestion';
import { CreateHrQuestion } from './Pages/jobs/CreateHrQuestion';
import LogicalTestDetails from './Pages/logical-test/LogicalTestDetails';
import LogicalAnswerSheet from './Pages/logical-test/LogicalAnswerSheet';

function App() {
  const pathname = window.location.pathname;
  const parts = pathname ? pathname?.split('/') : [];
  const childIndex = parts[1];
  const [activeComponet, setActiveComponet] = useState('');
  const [notFoundSidebar, setNotFoundSidebar] = useState(false);
  const [Sidedata, setSidedata] = useState(false);
  const [showSide, setShowSide] = useState(false);
  const [path, setPath] = useState();
  const [showSidebar, setShowSidebar] = useState(false)
  const { errorData } = useSelector(state => state.errorReducer)
  const handleChildDataChange = (newSidedata) => {
    setSidedata(newSidedata);
  };
  const [show, setShow] = useState(true);
  const isNetworkError = () => {
    if (errorData?.message === "Network Error" || errorData?.response?.status === 500 || errorData?.response?.status === 404) {
      return true
    } else {
      return false
    }
  }



  useEffect(() => {
    if (childIndex != "login" && childIndex != "candidate" && childIndex != "instruction" && childIndex != "candidate-test" && childIndex != "time-out" && childIndex != "not-implemented" && childIndex != "error" && childIndex != "view-job-data" && childIndex != "apply-job") {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [window.location.pathname]);


  // this two useEffect are use to control the sidebar if any inpput clicked
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutsideSidebar = (event) => {
      if (windowWidth <= 982 && !show && event.target.tagName === 'INPUT' || windowWidth <= 982 && !show && event.target.tagName === 'SELECT') {
        handleChildDataChange(false); // Close the sidebar if it's open and a input field is clicked
      }
    };
    document.addEventListener('click', handleClickOutsideSidebar);

    return () => {
      document.removeEventListener('click', handleClickOutsideSidebar);
    };
  }, [show, windowWidth]);


  return (
    <div className="App">
      <BrowserRouter>
        <div className={!show || showSide === true ? "main_body" : null}>
          {!show && <Sidebar notFoundSidebar={notFoundSidebar} Sidedata={Sidedata} onSidedataChange={handleChildDataChange} showSide={showSide} setNotFoundSidebar={setNotFoundSidebar} />}
          <section className={`position-relative ${!show ? "content" : " w-100"} ${notFoundSidebar ? 'notFoundcontent' : ''}`}>
            <Routes >
              <Route path='/' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <Dashboard setPath={setPath} Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              <Route path='/login' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <Login setPath={setPath} setShow={setShow} />} />
              {/* <Route element={<Permission />}> */}

              <Route element={<Permission id="60f6aebe1b38a2120c54a723" />}>
                <Route path='/candidates/all-candidates' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <AllCandidates Sidedata={Sidedata} onSidedataChange={handleChildDataChange} setShowSidebar={setShowSidebar} />} />
              </Route>
              +
              {/* <Route element={<Permission id="65bb905d53e762c89b9af167" />}> */}
              <Route path='/candidates/all-candidates/profile/:id' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <Candidateprofile Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              {/* </Route> */}

              <Route path='/candidates/profile/:id' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <CandidateProfiles setShowSide={setShowSide} />} />

              <Route element={<Permission id="60f6aebe1b38a2120c54a724" />}>
                <Route path='/candidates/add-candidates' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <CreateCandidate type={'Add'} Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb924853e762c89b9af169" />}>
                <Route path='/candidates/edit-candidates/:id' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <CreateCandidate type={'Edit'} Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
                {/* <EditCandidate Sidedata={Sidedata} setActiveComponet={setActiveComponet} onSidedataChange={handleChildDataChange} />} /> */}
              </Route>

              <Route path='/settings' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <Settings Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />

              <Route element={<Permission id="60f6aebe1b38a2120c54a728" />}>
                <Route path='/settings/skills' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <Skill Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb92c853e762c89b9af16a" />}>
                <Route path='/settings/add-skill' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <AddSkill Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb930e53e762c89b9af16b" />}>
                <Route path='/settings/update-skill/:id' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <UpdateSkill Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="60f6aebe1b38a2120c54a729" />}>
                <Route path='/settings/designation' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <Designation Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb935d53e762c89b9af16c" />}>
                <Route path='/settings/add-designation' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <AddDesignation Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb93b653e762c89b9af16d" />}>
                <Route path='/settings/update-designation/:id' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <UpdateDesignation Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="60f6aebe1b38a2120c54a72a" />}>
                <Route path='/settings/experience' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <Experience Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb93fb53e762c89b9af16e" />}>
                <Route path='/settings/add-experience' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <AddExperience Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb946053e762c89b9af16f" />}>
                <Route path='/settings/update-experience/:id' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <UpdateExperience Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="60f6aebe1b38a2120c54a72b" />}>
                <Route path='/settings/source' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <SourceManagement Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb94d653e762c89b9af170" />}>
                <Route path='/settings/add-source' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <AddSource Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb951c53e762c89b9af171" />}>
                <Route path='/settings/edit-source/:id' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <UpdateSource Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="60f6aebe1b38a2120c54a72c" />}>
                <Route path='/settings/user-profile' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <ProfileSettings Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="6113f2034b847d2e0e11d622" />}>
                <Route path='/settings/user-permission' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <UserPermition Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb956153e762c89b9af172" />}>
                <Route path='/settings/update-user-permission/:id' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <UpdateUserPermission Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="6113f2034b847d2e0e11d623" />}>
                <Route path='/settings/user-management' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <UserTypeManagement Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb95c253e762c89b9af174" />}>
                <Route path='/settings/add-user-type' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <AddUserType Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb95ec53e762c89b9af175" />}>
                <Route path='/settings/update-user-type/:id' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <UpdateUserType Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="6113f2034b847d2e0e11d624" />}>
                <Route path='/settings/menu-management' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <MenuManagement Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb961953e762c89b9af176" />}>
                <Route path='/settings/add-management' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <AddMenu Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb964e53e762c89b9af177" />}>
                <Route path='/settings/update-menu-management/:id' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <UpdateMenu Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="610118960b660a49d05022a2" />}>
                <Route path='/user/user-list' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <Userlist Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb968153e762c89b9af178" />}>
                <Route path='/user/user-create' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <UserCreate title={"create"} Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb96b053e762c89b9af179" />}>
                <Route path='/user/user-edit/:id' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <UserCreate title={"edit"} Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="61ae06f5edb406550227b942" />}>
                <Route path='/jobs/create-job' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <CreateJobs title={"create"} Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>
              {/* <Route element={<Permission id="61ae06f5edb406550227b942" />}> */}
              <Route path='/jobs/create-job/questions' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <CreateHrQuestion title={"create"} Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              {/* </Route> */}

              <Route element={<Permission id="65bb96ff53e762c89b9af17a" />}>
                <Route path='/jobs/edit-job/:id' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <CreateJobs title={"edit"} Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>
              <Route >
                <Route path='/jobs/view-job/:id' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <JobsViwer title={"admin"} Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>
              <Route >
                <Route path='/view-job-data/:id' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <JobsViwer title={"candidate"} Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="61ae06f5edb406550227b943" />}>
                <Route path='/jobs/create-job-list' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <JobsList Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>


              <Route path='/call-recordings' element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <CallRecording Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              {/* job ection */}
              <Route>
                <Route path="/jobs/joblist-applicants/:id" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <JobListNew Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>
              <Route>
                <Route path="/apply-job/:id" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <ApplyJobs />} />
              </Route>



              {/* job section */}

              <Route path='*' element={<Notfound notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} />} />

              {/* TEST PORTAL COMPONENTS */}

              {/* <Route element={<Permission isAuthenticated={array1} />}> */}
              <Route element={<Permission id="65968372f78bc5ceb87bc1d1" />}>
                <Route path="/test/all-test" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <TestScreen Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb973f53e762c89b9af17b" />}>
                <Route path="/test/inner-test/:id" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <InnerTest Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb976653e762c89b9af17c" />}>
                <Route path="/test/test-edit/:id" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <CreateTest Sidedata={Sidedata} onSidedataChange={handleChildDataChange} title={"Edit Test"} />} />
              </Route>

              {/* <Route element={<Permission id="65bb979453e762c89b9af17d" />}> */}
              <Route path="/test/category-add/:id" element={errorData?.message === "Network Error" || errorData?.response?.status === 500 ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <AddCategory Sidedata={Sidedata} onSidedataChange={handleChildDataChange} title={"Add New Category"} />} />
              {/* </Route> */}

              {/* <Route element={<Permission id="65bb97bd53e762c89b9af17e" />}> */}
              <Route path="/test/create-test-screen/:id" element={errorData?.message === "Network Error" || errorData?.response?.status === 500 ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <CreateTest Sidedata={Sidedata} onSidedataChange={handleChildDataChange} title={"Create New Test"} />} />
              {/* </Route> */}

              <Route path="/test/create-logical-test-screen/:id" element={errorData?.message === "Network Error" || errorData?.response?.status === 500 ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <LogicalTestCreate />} />

              <Route path="/test/edit-logical-test-screen/:id" element={errorData?.message === "Network Error" || errorData?.response?.status === 500 ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <LogicalTestCreate />} />

              <Route path="/test/create-logical-test-category/:id" element={errorData?.message === "Network Error" || errorData?.response?.status === 500 ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <AddLogicalTestCategory Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />

              <Route path="/test/create-logical-test-question/:id" element={errorData?.message === "Network Error" || errorData?.response?.status === 500 ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <AddLogicalTestQuestion Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />

              <Route path="/test/logical-test-details/:id" element={errorData?.message === "Network Error" || errorData?.response?.status === 500 ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <LogicalTestDetails Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />

              {/* <Route element={<Permission id="65bb97f053e762c89b9af17f" />}> */}
              <Route path="/test/question-add/:id" element={errorData?.message === "Network Error" || errorData?.response?.status === 500 ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <AddQuestions Sidedata={Sidedata} onSidedataChange={handleChildDataChange} title={"Add Question"} />} />
              {/* </Route> */}

              {/* <Route element={<Permission id="65bb984253e762c89b9af181" />}> */}
              <Route path="/test/question-edit/:quesid/:id" element={errorData?.message === "Network Error" || errorData?.response?.status === 500 || errorData?.response?.status === 404 ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : < EditQuestion Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              {/* </Route> */}

              {/* <Route element={<Permission id="65bb986a53e762c89b9af182" />}> */}
              <Route path="/test/category-edit/:id/:cateid" element={errorData?.message === "Network Error" || errorData?.response?.status === 500 || errorData?.response?.status === 404 ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <AddCategory Sidedata={Sidedata} onSidedataChange={handleChildDataChange} title={"Edit Category"} />} />
              {/* </Route> */}

              {/* <Route element={<Permission id="659683c1f78bc5ceb87bc1d2" />}>
                <Route path="/test/custom-test" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : < CustomTest Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route> */}

              <Route element={<Permission id="65bb98b553e762c89b9af183" />}>
                <Route path="/test/test-add" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <AddTest Sidedata={Sidedata} onSidedataChange={handleChildDataChange} title={"Edit Test"} />} />
              </Route>

              <Route path="/test/python-test" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <PythonTest Sidedata={Sidedata} onSidedataChange={handleChildDataChange} title={"Edit Test"} />} />

              <Route element={<Permission id="659684edf78bc5ceb87bc1d6" />}>
                <Route path="/email/create-template" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <CreateTemplate Sidedata={Sidedata} onSidedataChange={handleChildDataChange} title={"Create_Temp"} />} />
              </Route>

              <Route element={<Permission id="65bb9ae453e762c89b9af184" />}>
                <Route path="/email/edit-template/:id" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <CreateTemplate Sidedata={Sidedata} onSidedataChange={handleChildDataChange} title={"Edit_Temp"} />} />
              </Route>

              <Route element={<Permission id="6596851af78bc5ceb87bc1d7" />}>
                <Route path="/email/template-list" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <TemplateList Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route path="/candidate/:testType/:testId/:token" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <LoginTest setShowSide={setShowSide} />} />
              <Route path="/instruction/:type/:testId/:id" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <Instruction />} />
              <Route path="/candidate-test/mcq/:testId/:resultId" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <ExamPage setShowSide={setShowSide} />} />
              <Route path="/candidate-test/logical/:testId/:resultId" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <CodeExamPage />} />

              <Route element={<Permission id="65968433f78bc5ceb87bc1d4" />}>
                <Route path="/result/test-result" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <TestResult Sidedata={Sidedata} onSidedataChange={handleChildDataChange} title={"Test_Results"} />} />
              </Route>

              <Route element={<Permission id="659684abf78bc5ceb87bc1d5" />}>
                <Route path="/result/archive-result" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <TestResult Sidedata={Sidedata} onSidedataChange={handleChildDataChange} title={"Archive_Test_Results"} />} />
              </Route>

              <Route element={<Permission id="65bb9b2853e762c89b9af185" />}>
                <Route path="/answer-sheet/:id" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <AnswerSheet Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route element={<Permission id="65bb9b2853e762c89b9af185" />}>
                <Route path="/logical-answer-sheet/:id" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <LogicalAnswerSheet Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />
              </Route>

              <Route path="/test/trash-bin" element={isNetworkError() ? <NetworkErrorPage notFoundSidebar={notFoundSidebar} setNotFoundSidebar={setNotFoundSidebar} /> : <TrashBin Sidedata={Sidedata} onSidedataChange={handleChildDataChange} />} />

              {/* </Route> */}


            </Routes>
          </section>
          <ToastContainer autoClose={3000} />
        </div>
      </BrowserRouter >
    </div >
  );
}

export default App;