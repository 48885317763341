import { applyMiddleware, combineReducers, compose, legacy_createStore, } from "redux";
import thunk from "redux-thunk";
import { Login_Reducer, Logout_Reducer, } from "./AuthReducer/reducer";
import { User_Reducer } from "./User/reducer";
import { getSkillSet } from "./settings/skill/reducer";
import { Candidate_Reducer } from "./CandidateReducer/reducer";
import { getdesidnationSet } from "./settings/designation/reducer";
import { getExperienceSet } from "./settings/experience/reducer";
import { getSourceSet } from "./settings/source_management/reducer";
import { getAuthUser } from "./settings/profile_setting/reducer";
import { updateProfile } from "./settings/updateProfile/reducer";
import { getUserType } from "./settings/user_type_List/reducer";
import { getUserManageList } from "./settings/user_type_manage_get/reducer";
import { getMenu } from "./settings/getMenuManage/reducer";
import { Job_Reducer } from "./JobReducer/reducer";
import { addDesignationset } from "./settings/add_designation/reducer";

import { findSkillS } from "./settings/findSkill/reducer";
import { findDesignationS } from "./settings/findDesignation/reducer";
import { updateDesignationS } from "./settings/updateDesignation/reducer";
import { addExperienceSet } from "./settings/addExperience/reducer";
import { findExperiences } from "./settings/findExperience/reducer";
import { addSource } from "./settings/AddSource/reducer";
import { findSource } from "./settings/findSource/reducer";
import { updateSource } from "./settings/updateSource/reducer";
import { getPermissionSet } from "./settings/userPermission/reducer";
import { findRollset } from "./settings/findRoll/reducer";
import { updatePermission } from "./settings/update_permission/reducer";
import { findCandidate } from "./Candidate/findCandidate/reducer";
// import {tempCandidate} from "./candidate/reducer";
import { tempCandidate, tempDropdown } from "./Tempcandidate/reducer";
import { updateSkill } from "./settings/updateskill/reducer";
import { LogicalTestReducer, Test_Reducer } from "./TestReducer/reducer";
import { Category_Reducer } from "./CategoryReducer/reducer";
import { Applicant_Reducer } from "./ApplicantReducer/reducer";
import { Question_Reducer } from "./QuestionReducer/reducer";
import { result_Reducer } from "./ResultReducer/reducer";
import { email_Reducer } from "./EmailReducer/reducer";
import { addSkillSet } from "./settings/add_skill/reducer";
import { updateImage } from "./updateProfileImage/reducer";
import { editCandidateData } from "./Candidate/editcandidate/reducer";
import { error_Reducer } from "./ErrorHandling/reducer";
import { addCodeExamReducer } from "./CodeExam/reducer";
import { changeStep, createCandidate, createLanguage, createQualification, updateCertifications, updateHardware, updateJobHistory, updateProjectDetails, updateTotalExperience, updateworkDetails, createSkills, addCandidateResponse, addValidation, updateCandidateResponse, addPeriod } from "./Candidate/createCandidate/reducer";
import { searchGlobalReducer } from "./SearchReducer/reducer";
import { addToArchive, getCallRecordings } from "./GetCallRecordings/reducer";
import { postApplyJobs } from "./AppyJobs/reducer";
import { viewApplicants } from "./ViewJobApplicants/reducer";
import { viewJobDetails } from "./JobDetails.jsx/reducer";
import { activeStatus } from "./ActiveInactiveStatus/reducer";
import { FetchLogicalTestCategory, FetchLogicalTestLanguage, FetchLogicalTestQuestion, FetchSingleLogicalTest } from "./logicalTest/reducer/LogicalTestReducer";

const Reducer = combineReducers({
  Login_Reducer: Login_Reducer,
  Candidate_Reducer: Candidate_Reducer,
  User_Reducer: User_Reducer,
  getSkillSet: getSkillSet,
  addSkillSet: addSkillSet,
  getdesidnationSet: getdesidnationSet,
  getExperienceSet: getExperienceSet,
  getSourceSet: getSourceSet,
  getAuthUser: getAuthUser,
  updateProfile: updateProfile,
  getUserType: getUserType,
  getUserManageList: getUserManageList,
  getMenu: getMenu,
  Job_Reducer: Job_Reducer,
  addDesignationset: addDesignationset,
  findSkillS: findSkillS,
  updateSkill: updateSkill,
  findDesignationS: findDesignationS,
  updateDesignationS: updateDesignationS,
  addExperienceSet: addExperienceSet,
  findExperiences: findExperiences,
  addSource: addSource,
  findSource: findSource,
  updateSource: updateSource,
  getPermissionSet: getPermissionSet,
  findRollset: findRollset,
  updatePermission: updatePermission,
  findCandidate: findCandidate,
  tempCandidate: tempCandidate,
  tempDropdown: tempDropdown,
  Logout_Reducer: Logout_Reducer,
  test: Test_Reducer,
  categories: Category_Reducer,
  question: Question_Reducer,
  applicant: Applicant_Reducer,
  resultData: result_Reducer,
  emailData: email_Reducer,
  updateImage: updateImage,
  editCandidateData: editCandidateData,
  errorReducer: error_Reducer,
  codeExam: addCodeExamReducer,
  searchGlobal: searchGlobalReducer,
  createCandidate: createCandidate,
  createSkills: createSkills,
  createLanguage: createLanguage,
  changeStep: changeStep,
  createQualification: createQualification,
  updateHardware: updateHardware,
  updateworkDetails: updateworkDetails,
  updateProjectDetails: updateProjectDetails,
  updateCertifications: updateCertifications,
  updateTotalExperience: updateTotalExperience,
  updateJobHistory: updateJobHistory,
  addResponseReducer: addCandidateResponse,
  addValidation: addValidation,
  updateCandidateResponse: updateCandidateResponse,
  addPeriod: addPeriod,
  getCallRecordings: getCallRecordings,
  addToArchive: addToArchive,
  postApplyJobs: postApplyJobs,
  viewApplicants: viewApplicants,
  viewJobDetails: viewJobDetails,
  singleLogicalTest: FetchSingleLogicalTest,

  logicalTestCategory: FetchLogicalTestCategory,
  logicalTestLanguage: FetchLogicalTestLanguage,
  logicalTestQuestion: FetchLogicalTestQuestion,
  logicalTest: LogicalTestReducer
});

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : compose;

export const store = legacy_createStore(
  Reducer,
  composeEnhancers(applyMiddleware(thunk))
);