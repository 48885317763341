import Axios from "axios";

const axios = Axios.create({ baseURL: process.env.REACT_APP_BACKEND_BASE_URL })

axios.interceptors.request.use(config => {
    if (localStorage.getItem('token')) {
        config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    }

    return config
}, (error) => {
    return Promise.reject(error)
})

export default axios