import { FETCH_LOGICAL_TESTS_CATEGORY_FAILURE, FETCH_LOGICAL_TESTS_CATEGORY_REQUEST, FETCH_LOGICAL_TESTS_CATEGORY_SUCCESS, FETCH_LOGICAL_TESTS_LANGUAGE_FAILURE, FETCH_LOGICAL_TESTS_LANGUAGE_REQUEST, FETCH_LOGICAL_TESTS_LANGUAGE_SUCCESS, FETCH_LOGICAL_TESTS_QUESTION_FAILURE, FETCH_LOGICAL_TESTS_QUESTION_REQUEST, FETCH_LOGICAL_TESTS_QUESTION_SUCCESS, FETCH_SINGLE_LOGICAL_TEST_FAILURE, FETCH_SINGLE_LOGICAL_TEST_REQUEST, FETCH_SINGLE_LOGICAL_TEST_SUCCESS, LOGICAL_TEST_RESET } from "../constants/LogicalTestConstants";

export const FetchSingleLogicalTest = (state = {}, action) => {
    switch (action.type) {
        case FETCH_SINGLE_LOGICAL_TEST_REQUEST:
            return { isLoading: true };
        case FETCH_SINGLE_LOGICAL_TEST_SUCCESS:
            return { isLoading: false, data: action.payload, };
        case FETCH_SINGLE_LOGICAL_TEST_FAILURE:
            return { isLoading: false, error: action.payload, };
        case LOGICAL_TEST_RESET:
            return {};
        default:
            return state;
    }
}

export const FetchLogicalTestCategory = (state = {}, action) => {
    switch (action.type) {
        case FETCH_LOGICAL_TESTS_CATEGORY_REQUEST:
            return { isLoading: true };
        case FETCH_LOGICAL_TESTS_CATEGORY_SUCCESS:
            return { isLoading: false, data: action.payload, };
        case FETCH_LOGICAL_TESTS_CATEGORY_FAILURE:
            return { isLoading: false, error: action.payload, };
        case LOGICAL_TEST_RESET:
            return {};
        default:
            return state;
    }
}

export const FetchLogicalTestLanguage = (state = {}, action) => {
    switch (action.type) {
        case FETCH_LOGICAL_TESTS_LANGUAGE_REQUEST:
            return { isLoading: true };
        case FETCH_LOGICAL_TESTS_LANGUAGE_SUCCESS:
            return { isLoading: false, data: action.payload, };
        case FETCH_LOGICAL_TESTS_LANGUAGE_FAILURE:
            return { isLoading: false, error: action.payload, };
        case LOGICAL_TEST_RESET:
            return {};
        default:
            return state;
    }
}

export const FetchLogicalTestQuestion = (state = {}, action) => {
    switch (action.type) {
        case FETCH_LOGICAL_TESTS_QUESTION_REQUEST:
            return { isLoading: true };
        case FETCH_LOGICAL_TESTS_QUESTION_SUCCESS:
            return { isLoading: false, data: action.payload, };
        case FETCH_LOGICAL_TESTS_QUESTION_FAILURE:
            return { isLoading: false, error: action.payload, };
        case LOGICAL_TEST_RESET:
            return {};
        default:
            return state;
    }
}