import React, { useEffect, useState } from "react";
import "./css/TestResult.css";
import classNames from "classnames";
import { FiArrowUpRight } from "react-icons/fi";
import { BsArrowUpRight, BsDownload } from "react-icons/bs";
import { BiArchiveIn, BiArchiveOut } from "react-icons/bi";
import { HiChevronLeft } from "react-icons/hi";
import { TbFileExport } from "react-icons/tb";
import { TiDocumentText } from "react-icons/ti";
import Paginate from "../components/Pagination/Pagination";
import { allResult, archiveResultSearch, resultSearch, singleResult, unarchiveAllResults, updateStatusArchive, } from "../../Redux/ResultReducer/action";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import axios from "../../config/axiosNode";
import { errorCall } from "../../Redux/ErrorHandling/action";
import moment from "moment-timezone"

const TestResult = (props) => {
  const location = useLocation()?.pathname
  const sessionTokenValue = localStorage.getItem("token");
  const webToken = sessionTokenValue;
  const dispatch = useDispatch();
  const [include, setInclude] = useState(false);
  const [page, setPage] = useState(1);
  const [selectAll, setSelectAll] = useState();
  const [spentTime, setSpentTime] = useState("");
  const [loader, setLoader] = useState(false);
  const [testStatus, setTestStatus] = useState("");
  const [test, setTest] = useState("");

  const { isLoadingAllRes: resultLoader, allResult: allResults, resultData: { result, totalPages }, singleResult: singleResultData } = useSelector((state) => state.resultData)
  const { resultData: LogicalTestResultData } = useSelector((state) => state.resultData)


  const [resultData, setResultData] = useState([]);
  const [AllTestData, setAllTestData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [resultshow, setResultShow] = useState(false);
  const [searchData, setSearchData] = useState(" ");
  const [testType, setTestType] = useState("MCQ")

  const [testMarks, setTestMarks] = useState(0);
  const handleResultClose = () => setResultShow(false);
  const handleResultShow = (e) => {
    dispatch(singleResult(webToken, e.target.value, navigate));
    setResultShow(true);
  };

  const usersPermission = localStorage?.getItem("userPermission")
  const permissionList = usersPermission?.split(',');

  const navigate = useNavigate();

  const handleInclude = async (e) => {
    setPage(1);
    setInclude(e.target.checked);
    dispatch(resultSearch(testType, webToken, navigate, 1, props.title, searchData, testMarks, e.target.checked, testStatus, test));
    setResultData(result);
    setResultData(resultData);
  };

  const handleChange = (event) => {
    setLoading(true);
    const { name, checked } = event.target;
    if (name === "allSelect") {
      if (checked) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
      let tempData = resultData.map((element) => ({ ...element, isChecked: checked }));

      setResultData(tempData);
    } else {
      let tempData = resultData.map((element) => element._id === name ? { ...element, isChecked: checked } : element);
      const checkedCount = tempData.filter((element) => element.isChecked === checked);
      setResultData(tempData);

      if (checkedCount.length == tempData.length && checked) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
      }
    }
    setLoading(false);
  };

  const handleSearch = (e) => {
    setPage(1);
    e.preventDefault();
    dispatch(resultSearch(testType, webToken, navigate, 1, props.title, searchData, testMarks, include, testStatus, test));
    setResultData(result);
  };

  function removeSpecialCharactersFromStart(inputString) {
    const regex = /^[!@#$%^&*()_+{}\[\]:;<>,.?~\\/\-|=]+/;
    return inputString.replace(regex, "");
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (searchData !== 0) {
        handleSearch(event);
      }
    }
  };

  const handleArchive = (e) => {
    const ids = e.target.getAttribute("value");
    dispatch(updateStatusArchive(webToken, ids, true, navigate)).then(() => {
      toast.success("Test Archived Successfully");
      dispatch(resultSearch(testType, webToken, navigate, page, props.title, searchData, testMarks, include, testStatus, test));
      setResultData(result);
    });
  };

  const handleUnArchive = (e) => {
    if (props.title === "Test_Results") {
      const ids = e.target.getAttribute("value");
      dispatch(updateStatusArchive(webToken, ids, false, navigate)).then(() => {
        toast.success("Test Unarchived Successfully");
        dispatch(resultSearch(testType, webToken, navigate, page, props.title, searchData, testMarks, include, testStatus, test));
        setResultData(result);
      }).catch((error) => { });
    } else {
      const ids = e.target.getAttribute("value");
      dispatch(updateStatusArchive(webToken, ids, false, navigate)).then(() => {
        toast.success("Test Unarchived Successfully");
        dispatch(resultSearch(testType, webToken, navigate, page, props.title, searchData, testMarks, true, testStatus, test));
        setResultData(result);
      }).catch((error) => { });
    }
  };

  const handleAllUnArchive = (e) => {
    if (selectAll === true) {
      dispatch(unarchiveAllResults(webToken, false, navigate)).then(() => {
        dispatch(resultSearch(testType, webToken, navigate, page, props.title, searchData, testMarks, true, testStatus, test));
        setResultData(result);
      });
    } else if (selectAll === false) {
      const filterOut = resultData.filter((e) => e.isChecked);
      filterOut.map((value, i) => {
        dispatch(updateStatusArchive(webToken, value._id, false, navigate)).then(() => {
          if (filterOut.length - 1 == i) {
            dispatch(resultSearch(testType, webToken, navigate, page, props.title, searchData, testMarks, true, testStatus, test));
            setResultData(result);
          }
        });
      });
    }
  };

  const pageChange = (e) => {
    dispatch(resultSearch(testType, webToken, navigate, e, props.title, searchData, testMarks, include, testStatus, test));
    setPage(e);
    if (e === "previous") {
      setPage(page - 1);
      dispatch(resultSearch(testType, webToken, navigate, page - 1, props.title, searchData, testMarks, include, testStatus, test));
      setResultData(result);
    } else if (e === "next") {
      setPage(page + 1);
      dispatch(resultSearch(testType, webToken, navigate, page + 1, props.title, searchData, testMarks, include, testStatus, test));
      setResultData(result);
    }
  };

  const handleMarks = (e) => {
    e.preventDefault();
    var splitValue = e.target.value?.split("*");
    setTestMarks(splitValue[0]);
    setTestStatus(splitValue[1]);
    setPage(1);
    dispatch(resultSearch(testType, webToken, navigate, 1, props.title, searchData, splitValue[0], include, splitValue[1], test));
    setResultData(result);
  };

  const headers = ["applicantName", "applicantEmail", "marks", "testName", "testStatus", "createdAt",];

  const convertToCSV = (data, headers) => {
    const csvRows = [];
    csvRows.push(headers.join(","));
    data.forEach((row) => {
      const csvRow = headers.map((header) => {
        const field = row[header];
        const needsQuotes = field;
        return needsQuotes ? `"${field}"` : field;
      });
      csvRows.push(csvRow.join(","));
    });
    return csvRows.join("\n");
  };

  // const generatePDF = async (data) => {
  //   setLoader(true);
  //   handleResultClose()

  //   axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/gettest/${data.testID}`, { headers: { Authorization: `Bearer ${webToken}`, }, }).then((testResult) => {
  //     if (testResult?.data?.result) {
  //       return axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/category/${data.testID}`, { headers: { Authorization: `Bearer ${webToken}`, }, }).then((categoryResponse) => {
  //         if (categoryResponse?.data) {
  //           return axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/averagescore/${data.testID}`, { headers: { Authorization: `Bearer ${webToken}`, }, }).then((averageScoreResponse) => {
  //             if (averageScoreResponse?.data) {
  //               return axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/categorywisemarks/${data.testID}/${data._id}`, { headers: { Authorization: `Bearer ${webToken}`, }, }).then((categoryWiseMarksResponse) => {
  //                 if (categoryWiseMarksResponse?.data?.categoryWiseData) {
  //                   return axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/singleresult/${data._id}`, { headers: { Authorization: `Bearer ${webToken}`, }, }).then((singleresultResponse) => {
  //                     if (singleresultResponse?.data?.result?.answers) {
  //                       savePdf(data, testResult?.data?.result, categoryResponse?.data, averageScoreResponse?.data, categoryWiseMarksResponse?.data?.categoryWiseData);
  //                       const datetime1 = new Date(singleresultResponse?.data?.result?.answers[singleresultResponse?.data?.result?.answers?.length - 1]?.time);
  //                       const datetime2 = new Date(data?.createdAt);
  //                       const timeDifference = Math.abs(datetime1 - datetime2);
  //                       const secondsDifference = timeDifference / 1000;

  //                       if (secondsDifference) {
  //                         setSpentTime(secondsDifference);
  //                       }
  //                     } else {
  //                       toast.error("Failed To Generate Pdf")
  //                     }
  //                   }).catch((error) => {
  //                     if (error.message === "Network Error" || error.response.status === 500) {
  //                       return dispatch(errorCall(error))
  //                     }
  //                     toast.error("Failed To Generate Pdf");
  //                     setLoader(false);
  //                   });
  //                 }
  //               }).catch((error) => {
  //                 if (error.message === "Network Error" || error.response.status === 500) {
  //                   return dispatch(errorCall(error))
  //                 }
  //                 toast.error("Failed To Generate Pdf");
  //                 setLoader(false);
  //               });
  //             }
  //           }).catch((error) => {
  //             if (error.message === "Network Error" || error.response.status === 500) {
  //               return dispatch(errorCall(error))
  //             }
  //             toast.error("Failed To Generate Pdf");
  //             setLoader(false);
  //           });
  //         }
  //       }).catch((error) => {
  //         toast.error("Failed To Generate Pdf");
  //         setLoader(false);
  //       });
  //     }
  //   }).catch((error) => {
  //     if (error?.message === "Network Error" || error?.response?.status === 500) {
  //       return dispatch(errorCall(error))
  //     }
  //     if (error?.response?.data?.error === "Unauthenticated.") {
  //       localStorage.removeItem("token");
  //       navigate("/login");
  //     }
  //     toast.error("Failed To Generate Pdf");
  //     setLoader(false);
  //   });
  // };



  const generatePDF = async (data) => {
    setLoader(true);
    handleResultClose()
    if (testType === 'logical-test') {
      axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/logical-test/generate-report/${data._id}`).then((details) => {
        const pdfContent = details.data.pdf;

        const pdf = new jsPDF('p', 'pt', 'a4');
        pdf.html(pdfContent, {
          callback: function (doc) {
            const blob = doc.output('blob');
            const url = URL.createObjectURL(blob);
            const newWindow = window.open(url, '_blank');
            if (!newWindow) {
              alert('Please allow popups for this website to view the PDF.');
            } else {
              setLoader(false)
              newWindow.addEventListener('unload', () => {
                URL.revokeObjectURL(url);
              });
            }
          },
          x: 10, y: 10, width: 595.28, // a4 width
        });
      }).catch((error) => {
        if (error.message === "Network Error" || error.response.status === 500) {
          dispatch(errorCall(error))
        }
        toast.error("Failed To Generate Pdf");
        setLoader(false);
      })

    } else {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/generate-result/${data.testID}/${data._id}`, { headers: { Authorization: `Bearer ${webToken}`, }, })
        const pdfContent = response.data.pdf;

        const pdf = new jsPDF('p', 'pt', 'a4');
        pdf.html(pdfContent, {
          callback: function (doc) {
            const blob = doc.output('blob');
            const url = URL.createObjectURL(blob);
            const newWindow = window.open(url, '_blank');
            if (!newWindow) {
              alert('Please allow popups for this website to view the PDF.');
            } else {
              setLoader(false)
              newWindow.addEventListener('unload', () => {
                URL.revokeObjectURL(url);
              });
            }
          },
          x: 10,
          y: 10,
          width: 595.28, // a4 width
        });
      } catch (error) {
        if (error?.message === "Network Error" || error?.response?.status === 500) {
          dispatch(errorCall(error))
          return;
        }
        toast.error("Failed To Generate Pdf");
        setLoader(false);
        console.error('Error generating PDF:', error);
      }
    }
  };


  const menuClick = () => {
    props.onSidedataChange(!props.newSidedata);
  };

  const savePdf = (resultData, testData, categoryScoreData, scoreData, cateMarks) => {
    const doc = new jsPDF({
      orientation: "potrait",
      unit: "pt",
      // height: width
      format: [841.89, 595.28],
    });

    const htmlContent = `<!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Document</title>
      <link rel="preconnect" href="https://fonts.googleapis.com">
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap" rel="stylesheet">
    </head>
    <body>
      <div style="font-family: 'Poppins', sans-serif; width: 100%; max-width: 595.28px;  margin: 0 auto; background-color: #e1e0e000;  margin-top: 100px;">
        <div
            style="
            display: flex;
            align-items: center;
            background-color: #43b2e4;
            padding: 10px;
            width: 490px;
            height: 40px;
            border-top: 1px solid white;
            border-right: 1px solid white;
            border-bottom: 1px solid white;
            border-radius: 0px 100px 100px 0px;
            "
            >
            <div style=" width: 100%; max-width: 595.28px;  margin: 0 auto ; display: flex ;align-items: center; justify-content: space-between;">
              <h1 style="color: white; font-size: 20px; margin-bottom: 0;">
                  <span>
                  <img style="" src="https://dev.interviewscreener.com/logos/Group.png" alt="image" className="img-fluid" /></span>
                  ${resultData?.testName}
              </h1>
              <div
                  className="report_logo"
                  style="margin-left: auto; margin-right: 40px"
                  >
                  <img style="width: 100px; height:100px; border-radius: 200px;" src="https://dev.interviewscreener.com/logos/Frame.png" alt="image" className="img-fluid" />
              </div>
            </div>
        </div>
        <div style="margin-top: 20px; display: flex; align-items: center; justify-content: space-between;"
            >
            <div style=" width:595.28px;  display: flex;align-items: center;  padding: 20px 0px 0px; ">
              <div style=" width:300px; margin-right:45.28px">
                  <table
                    style="
                    font-family: 'Poppins', sans-serif;
                    width: 100%;
                    border-collapse: collapse;
                    border-left: none;
                    border-right: none;
                    border-top: none;
                    margin-left: 25px;
                    "
                    >
                    <tr style="border-bottom: 1px solid lightGrey">
                        <td style="border: none; padding: 8px; text-align: left; font-size: 10px">
                          Passed by
                        </td>
                        <td
                          style="
                          border: none;
                          padding: 8px;
                          text-align: left;
                          font-weight: bolder;
                          font-size: 10px;
                          "
                          >
                          <span style="margin-right: 5px;">:</span>${resultData?.applicantName}
                        </td>
                    </tr>
                    <tr style="border-bottom: 1px solid lightGrey">
                        <td style="border: none; padding: 8px; text-align: left; font-size: 10px">
                          Email
                        </td>
                        <td
                          style="
                          border: none;
                          padding: 8px;
                          text-align: left;
                          font-weight: bolder;
                          font-size: 10px;
                          "
                          >
                          <span style="margin-right: 5px;">:</span><font>${resultData?.applicantEmail}</font>
                        </td>
                    </tr>
                    <tr style="border-bottom: 1px solid lightGrey">
                        <td style="border: none; padding: 8px; text-align: left; font-size: 10px">
                          Total Time
                        </td>
                        <td
                          style="
                          border: none;
                          padding: 8px;
                          text-align: left;
                          font-weight: bolder;
                          font-size: 10px;
                          "
                          >
                          <span style="margin-right: 5px;">:</span>${spentTime ? (spentTime / 60).toFixed(0) + "Min" + " " + (spentTime % 60).toFixed(0) + "Sec" : "0 Min"},<span style="color: green; margin-right: 5px; font-size: 10px; font-weight: bolder;">
                           Max Time: ${resultData?.testMaxTime
        ? resultData?.testMaxTime + "min"
        : "No Limit"
      }
                           </span></span>
                     </tr>
                     <tr style="border-bottom: 1px solid lightGrey">
                        <td style="border: none; padding: 8px; text-align: left; font-size: 10px">
                           No. of Questions
                        </td>
                        <td
                           style="
                           border: none;
                           padding: 8px;
                           text-align: left;
                           font-weight: bolder;
                           font-size: 10px;
                           "
                           >
                           <span style="margin-right: 5px;">:</span>  ${resultData?.candidateQuestion?.length
      }
                        </td>
                     </tr>
                     <tr style="border-bottom: 1px solid lightGrey">
                        <td style="border: none; padding: 8px; text-align: left; font-size: 10px">
                           IP Address
                        </td>
                        <td
                           style="
                           border: none;
                           padding: 8px;
                           text-align: left;
                           font-weight: bolder;
                           font-size: 10px;
                           "
                           >
                           <span style="margin-right: 5px;">:</span>${resultData?.ipAddress.replace(
        "Not Found",
        ""
      )}
                        </td>
                     </tr>
                  </table>
               </div>
               <div
                  style="
                  margin-top:20px;
                  display: flex;
                  width: 250px;
                  height: 120px;
                  background: ${resultData?.marks < 29
        ? "#FFB2B2"
        : resultData?.marks > 29 &&
          resultData?.marks <= 51
          ? "#FFFFED"
          : resultData?.marks > 51 &&
            resultData?.marks <= 75
            ? "#D1FFBD"
            : resultData?.marks > 75
              ? "lightgreen"
              : null
      };
                  border-radius: 20px 0px 0px 20px;
                  align-items: center;
                  justify-content: center;
                  "
                  >
                  <div style="
                     height: 100%;
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     ">
                     <div style="margin-right:20px;">
                        <div style="display:flex;
                           align-items: center;
                           justify-content: end;">
                           <div style="
                              padding:13px 8px; 
                              height: 31px;
                              display:flex;
                              align-items: center;
                              justify-content: center;
                              border-radius: 5px;
                              background-color:${resultData?.marks < 29
        ? "#F43E1E"
        : resultData?.marks > 29 &&
          resultData?.marks <= 51
          ? "#FED232"
          : resultData?.marks > 51 &&
            resultData?.marks <= 75
            ? "#1aaa1a"
            : resultData?.marks > 75
              ? "#008631"
              : null
      };
                              ">
                              <p style="color: #FFF;
                                 margin-bottom:0px;
                                 margin-left:7px;
                                 font-family: 'Poppins', sans-serif;
                                 font-size: 14px; 
                                 text-align:center;
                                 font-weight: 600;">
                                 ${resultData?.marks < 29
        ? "Poor"
        : resultData?.marks > 29 &&
          resultData?.marks <= 51
          ? "Average"
          : resultData?.marks > 51 &&
            resultData?.marks <= 75
            ? "Good"
            : resultData?.marks > 75
              ? "Excellent"
              : null
      }
                              </p>
                           </div>
                        </div>
                        <p style="color: #6C6C6C;
                           font-family: 'Poppins', sans-serif;
                           font-size: 13px;
                           text-align: end;
                           margin-bottom:0px;
                           font-weight: 700;">Applicant’s Score</p>
                        <h2 style="color:${resultData?.marks < 29
        ? "#de1616"
        : resultData?.marks > 29 && resultData?.marks <= 51
          ? "#f5e907"
          : resultData?.marks > 51 && resultData?.marks <= 75
            ? "#23b023"
            : resultData?.marks > 75
              ? "#23b023"
              : null
      };
                           font-family: 'Poppins', sans-serif;
                           font-size: 20px;
                           text-align: end;
                           font-weight: 700;">${resultData?.marks?.toFixed()}${" "}<b>/</b>100</h2>
                     </div>
                     <img  style="width: 50px; height:72px;" src=${resultData?.marks < 29
        ? "https://dev.interviewscreener.com/logos/Thumbs-Down.png"
        : resultData?.marks > 29 &&
          resultData?.marks <= 51
          ? "https://dev.interviewscreener.com/logos/winner_gold_cup.png"
          : resultData?.marks > 51 &&
            resultData?.marks <= 75
            ? "https://dev.interviewscreener.com/logos/winner_gold_cup.png"
            : resultData?.marks > 75
              ? "https://dev.interviewscreener.com/logos/winner_gold_cup.png"
              : null
      } alt="">
                  </div>
               </div>
            </div>
         </div>
         <div style="width: 595.28px; margin:75px auto 0; ">
            <div style="width: 100%; display: flex; justify-content: space-between;">
               <div style=" width: 40%; background-color: rgba(128, 128, 128, 0); height: 100%; padding-left:25px;">
                  <div>
                     <div style="display: flex; align-items: center; justify-content: space-between; width: 100%;">
                        <p style="color: #383838;
                           margin:0px;
                           font-family: 'Poppins', sans-serif;
                           font-size: 11px;
                           font-weight: 600;
                           ">${resultData?.testName}</p>
                        <p style="color: #383838;
                           margin:0px;
                           font-family: 'Poppins', sans-serif;
                           font-size: 11px; 
                           font-weight: 700;
                           ">${resultData?.marks?.toFixed()}${" "}<b>/</b>100</p>
                     </div>
                     <div style="margin-top: 10px; width: 100%; height: 2px; background-color: #000;"></div>
                  </div>
                  ${cateMarks
        ? cateMarks
          ?.map(
            (element) => `
                  <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
                     <div style="display: flex; align-items: center; margin-top: 20px; color: #929292;
                        font-family: 'Poppins', sans-serif;
                        font-size: 11px;
                        font-weight: 500;
                        ">
                        <p style=" width: 10px; height: 10px; border-radius: 100px;background-color: #43B2E4; margin: 0px 20px 0px 0px;"></p>
                        ${element?.category}
                     </div>
                     <p style="margin: 20px 0px 0px;">
                        ${element?.marks}
                     </p>
                  </div>
                  `
          )
          ?.join("")
        : ""
      }
               </div>
               <div style="width: 50%; height: 100%; display: flex; align-items: center; justify-content: end;">
                  <div style="width: 100%;  background-color: #e3e3e366; max-width: 400px; padding:  20px 20px 0px; border-radius: 10px; margin:0px 20px; border:1px solid #D7D7D7">
                     <table style="width: 100%;">
                        <thead>
                           <tr>
                              <th style=" width: 275px; text-align: left; border-right: 1px solid #CECDCD; padding: 0px 0px 0px;">
                                 <p style="color: #333;
                                    margin-bottom:7px;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 11px;
                                    font-weight: 700;
                                    ">Average Test Score</p>
                              </th>
                              <th style="padding: 0px 0px 0px 5px ;">
                                 <p style="color: #333;
                                    margin-bottom:7px;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 11px;
                                    text-align:center;
                                    font-weight: 700;
                                    white-space: nowrap;">${scoreData?.allAverages
      }${" "}<b>/</b>100</p>
                              </th>
                           </tr>
                        </thead>
                        <tbody>
                          
                           ${scoreData?.top5PercentApplicants === null
        ? ""
        : ` <tr>
                            <td style="border-top: 1px solid #D7D7D7;border-right: 1px solid #D7D7D7; padding: 10px 0px 10px; vertical-align: middle;">
                               <p style="color: #818181;
                                  font-family: 'Poppins', sans-serif;
                                  font-size: 10px;
                                  font-weight: 400;">Top 5 % Score</p>
                            </td>
                            <td style="border-top: 1px solid #D7D7D7; padding: 10px 0px 10px; text-align: center; vertical-align: middle;">
                               <p style="color: #818181;
                                  font-family: 'Poppins', sans-serif;
                                  font-size: 10px;
                                  font-weight: 400;
                                  white-space: nowrap;">${scoreData?.top5PercentApplicants
        }${" "}<b>/</b>100
                               </p>
                            </td>
                         </tr>`
      }

                          ${scoreData?.top10PercentApplicants === null
        ? ""
        : `<tr>
                            <td style="border-top: 1px solid #D7D7D7;border-right: 1px solid #D7D7D7; padding: 10px 0px 10px; vertical-align: middle;">
                               <p style="color: #818181;
                                  font-family: 'Poppins', sans-serif;
                                  font-size: 10px;
                                  font-weight: 400;">Top 10 % Score</p>
                            </td>
                            <td style="border-top: 1px solid #D7D7D7; padding: 10px 0px 10px; text-align: center; vertical-align: middle;">
                               <p style="color: #818181;
                                  font-family: 'Poppins', sans-serif;
                                  font-size: 10px;
                                  font-weight: 400;
                                  white-space: nowrap;">${scoreData?.top10PercentApplicants
        }${" "}<b>/</b>100
                               </p>
                            </td>
                         </tr>`
      }

                           
                           ${scoreData?.top20PercentApplicants === null
        ? ""
        : `<tr>
                            <td style="border-top: 1px solid #D7D7D7;border-right: 1px solid #D7D7D7; padding: 10px 0px 10px; vertical-align: middle;">
                               <p style="color: #818181;
                                  font-family: 'Poppins', sans-serif;
                                  font-size: 10px;
                                  font-weight: 400;">Top 20 % Score</p>
                            </td>
                            <td style="border-top: 1px solid #D7D7D7; padding: 10px 0px 10px; text-align: center; vertical-align: middle;">
                               <p style="color: #818181;
                                  font-family: 'Poppins', sans-serif;
                                  font-size: 10px;
                                  font-weight: 400;
                                  white-space: nowrap;">${scoreData?.top20PercentApplicants
        }${" "}<b>/</b>100
                               </p>
                            </td>
                         </tr>`
      }

                          ${scoreData?.top30PercentApplicants === null
        ? ""
        : `<tr>
                            <td style="border-top: 1px solid #D7D7D7;border-right: 1px solid #D7D7D7; padding: 10px 0px 10px; vertical-align: middle;">
                               <p style="color: #818181;
                                  font-family: 'Poppins', sans-serif;
                                  font-size: 10px;
                                  font-weight: 400;">Top 30 % Score</p>
                            </td>
                            <td style="border-top: 1px solid #D7D7D7; padding: 10px 0px 10px; text-align: center; vertical-align: middle;">
                               <p style="color: #818181;
                                  font-family: 'Poppins', sans-serif;
                                  font-size: 10px;
                                  font-weight: 400;
                                  white-space: nowrap;"
                                  >${scoreData?.top30PercentApplicants
        }${" "}<b>/</b>100
                               </p>
                            </td>
                         </tr>`
      }
                           
                        </tbody>
                     </table>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </body>
</html>
`;
    if (scoreData != undefined) {
      doc.html(htmlContent, {
        callback: function (doc) {
          // Convert the PDF to a Blob
          const blob = doc.output("blob");
          setLoader(false);
          // Create a URL for the Blob
          const blobUrl = URL.createObjectURL(blob);
          // Open the Blob URL in a new tab
          window.open(blobUrl, "_blank");
          // setpdfData("successful");
        },
      });
    }
  };

  const csvResult = (e) => {
    const filterOut = resultData.filter((e) => e.isChecked);
    if (filterOut.length === 0) {
      return toast.warn("Please Choose Any Result To Export");
    } else {
      if (selectAll === false) {
        const csvData = convertToCSV(filterOut, headers);
        const encodedUri = encodeURI(csvData);
        const link = document.createElement("a");
        link.setAttribute("href", `data:text/csv;charset=utf-8,${encodedUri}`);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else if (selectAll === true) {
        const csvData = convertToCSV(resultData, headers);
        const encodedUri = encodeURI(csvData);
        const link = document.createElement("a");
        link.setAttribute("href", `data:text/csv;charset=utf-8,${encodedUri}`);
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const handleTestSearch = (e) => {
    setTest(e.target.value);
    if (!e.target.value) {
      dispatch(resultSearch(testType, webToken, navigate, page, props.title, searchData, testMarks, include, testStatus));
    } else {
      dispatch(resultSearch(testType, webToken, navigate, 1, props.title, searchData, testMarks, include, testStatus, e.target.value));
    }
    setResultData(result);
  };
  const searchValue = (e) => {
    const newValue = removeSpecialCharactersFromStart(e.target.value);
    setSearchData(newValue);
    if (e.target.value === "") {
      dispatch(resultSearch(testType, webToken, navigate, 1, props.title, "", testMarks, include, testStatus, test));
      setResultData(result);
      setResultData(resultData);
    }
  };

  useEffect(() => {
    setPage(1);
    if (props?.title === 'Archive_Test_Results' && testType === 'logical-test') {
      dispatch(archiveResultSearch(webToken, navigate, 1));
    } else {
      dispatch(resultSearch(testType, webToken, navigate, 1, props.title, "", 0, false));
    }
    dispatch(allResult(webToken, navigate));
    setInclude(false);
    setSearchData("");
  }, [dispatch, navigate, props.title, testType, webToken]);

  useEffect(() => {
    setResultData(result);
    setInclude(include);
  }, [include, result]);

  useEffect(() => {
    if (AllTestData?.length == 0 && allResults) {
      setAllTestData(allResults);
    }
  }, [AllTestData?.length, allResults]);

  useEffect(() => {
    setSelectAll(false);
  }, [allResults, result]);


  const handleLogicalStatus = (test_id) => {
    axios.patch(`${process.env.REACT_APP_BACKEND_BASE_URL}api/logical-test/archive-unarchive-result/${test_id}`).then((response) => {
      if (props?.title === 'Archive_Test_Results' && testType === 'logical-test') {
        dispatch(archiveResultSearch(webToken, navigate, 1));
      } else {
        dispatch(resultSearch(testType, webToken, navigate, page, props.title, searchData, testMarks, include, testStatus, test));
      }
      setResultData(resultData);
      toast.success(response.data.message);
    }).catch((error) => {
      toast.error(error.response ? error.response.data.message : error.toString());
    })
  }


  return (
    <>
      <div className="content_app ">
        <div className="body_content">
          <div className="container-fluid">
            <button className="fa_bars  d-lg-none ms-2" onClick={menuClick}>
              <i className="fa-solid fa-bars fs-4"></i>
            </button>
            <div className="row">
              <div className="col-12 hjklop_right px-0">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div className="my-3">
                        <div className="container-fluid px-0">
                          <div className="top_header border-bottom pb-3">
                            <div className="row g-3 align-items-center">
                              <div className="col-md-5">
                                <div className="top_left">
                                  <div>
                                    <img src="/logos/Test-result-icon.png" alt="logo" id="test_icon" />
                                    <b id="test_text">
                                      {props.title.replace("_", " ").replace("_", " ")}
                                    </b>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-7">
                                <div className="top_right justify-content-md-end">
                                  <div className="d-flex right_inner gap-2">
                                    <div className="input-group">
                                      <select class="form-select" defaultValue={testType} onChange={(e) => setTestType(e.target.value)} aria-label="Default select example">
                                        <option value="MCQ">MCQ</option>
                                        <option value="logical-test">Logical Test</option>
                                      </select>
                                    </div>
                                    <div className="input-group">
                                      <div className="form-control input_div d-flex">
                                        <input value={searchData ? searchData : ""} onChange={(e) => searchValue(e)} className="input_search w-100" type="search" placeholder="Search" name="search" autoComplete="off" onKeyDown={handleKeyPress} />
                                        <div className="setting_search">
                                        </div>
                                      </div>
                                      <div className="input-group-btn">
                                        {
                                          searchData === 0 ? (
                                            <button className="btn btn-default" type="submit" disabled>
                                              Search
                                            </button>
                                          ) : (
                                            <button onClick={handleSearch} className="btn btn-default" type="submit">
                                              Search
                                            </button>
                                          )
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {loader == true ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                height: "80vh",
                                alignItems: "center",
                              }}
                            >
                              <b>Report Is Generating ...</b>
                            </div>
                          ) : (
                            <div>
                              <div className="table_features">
                                {/* for Test result  part */}
                                {props.title === "Test_Results" ? (
                                  <div className="btn_div d-block">
                                    <div className="row g-3">
                                      <div className="col-lg-7">
                                        <div className="btn_right d-block d-md-flex align-items-center">
                                          <div>
                                            <select
                                              onChange={(e) =>
                                                handleTestSearch(e)
                                              }
                                              className="bg-light w-250 form-select text-black  accordian_btn"
                                            >
                                              <option value={""}>
                                                All Test
                                              </option>
                                              {!AllTestData
                                                ? "loading..."
                                                : AllTestData?.filter(
                                                  (e, index, self) => {
                                                    return (
                                                      self.findIndex(
                                                        (item) =>
                                                          item.testName ===
                                                          e.testName
                                                      ) === index
                                                    );
                                                  }
                                                ).map((e, index) => (
                                                  <option
                                                    value={e?.testName}
                                                    key={index}
                                                  >
                                                    {e?.testName}
                                                  </option>
                                                ))}
                                            </select>
                                          </div>
                                          <div>
                                            <select
                                              id="second_select"
                                              className="bg-light w-250 form-select text-black accordian_btn ms-0 ms-md-2 my-2 my-md-0"
                                              onChange={handleMarks}
                                            >
                                              <option value={""}>
                                                All Results
                                              </option>
                                              <option
                                                value={75 + "*" + "completed"}
                                              >
                                                75/100 and more
                                              </option>
                                              <option
                                                value={65 + "*" + "completed"}
                                              >
                                                65/100 and more
                                              </option>
                                              <option
                                                value={55 + "*" + "completed"}
                                              >
                                                55/100 and more
                                              </option>
                                              <option
                                                value={45 + "*" + "completed"}
                                              >
                                                45/100 and more
                                              </option>
                                              {/* value={element?.categoryName + "*" + element?._id} */}
                                              <option
                                                value={0 + "*" + "progress"}
                                              >
                                                In Progress
                                              </option>
                                              <option
                                                value={0 + "*" + "abandoned"}
                                              >
                                                Abanoned
                                              </option>
                                            </select>
                                          </div>
                                          <div className="d-flex align-items-center">
                                            <input
                                              onChange={(e) => handleInclude(e)}
                                              // checked={include}
                                              type="checkbox"
                                              name="includes"
                                              className="cursor-pointer ms-md-4 ms-0"
                                              id="include_archive"
                                            />
                                            <label
                                              htmlFor="include_archive"
                                              className="cursor-pointer ms-1"
                                            >
                                              Include Archive
                                            </label>
                                            {/* <span id="includes">Include Archive</span> */}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-lg-5">
                                        <div className="row g-0 justify-content-end justify-content-md-start justify-content-lg-end">
                                          <div className="col-auto">
                                            <button
                                              onClick={() => {
                                                navigate("/test/all-test");
                                              }}
                                              className="test_some px-4"
                                            >
                                              <span className="d-none d-sm-inline me-1">
                                                Test Someone
                                              </span>
                                              <BsArrowUpRight />
                                            </button>
                                          </div>
                                          <div className="col-auto">
                                            {/* {selectAll === true ? ( */}
                                            <button
                                              className="export px-4 ms-2"
                                              onClick={csvResult}
                                            >
                                              <TbFileExport />
                                              <span className="d-none d-sm-inline ms-1">
                                                Exports
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : // for archive result part
                                  props.title === "Archive_Test_Results" ? (
                                    <div className="archive_btn">
                                      <div className="main_btn_div">
                                        {permissionList?.includes("65968433f78bc5ceb87bc1d4") &&
                                          <div
                                            className="Test_btn"
                                            onClick={() =>
                                              navigate(`/result/test-result`)
                                            }
                                          >
                                            {" "}
                                            <HiChevronLeft className="fs-3" />
                                            Test results
                                          </div>
                                        }
                                        <div>
                                          <button
                                            className=" text-white Unarchived_btn ms-2"
                                            onClick={handleAllUnArchive}
                                          >
                                            {/* <img src="" alt="" /> */}
                                            <span className="me-1">
                                              <BiArchiveOut />
                                            </span>
                                            <span className="d-none d-sm-inline">
                                              Unarchived
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                              </div>
                              <div className="my-5 table_box p-3">
                                {
                                  testType === 'logical-test' ? (
                                    <div className="table-responsive scroll_table">
                                      <table className="table table-striped  table-md test_table">
                                        <thead id="table-header" className="thead-dark sticky-top">
                                          <tr className="table_row">
                                            <th scope="col" width="123px">
                                              <p className="mb-0 ps-2 d-flex align-items-center">
                                                <span className="outer_table_check" style={{ whiteSpace: 'nowrap' }}>
                                                  <input name="allSelect" onChange={(e) => handleChange(e)} className="table_check m-0" type="checkbox" checked={!selectAll ? false : true} id="1" disabled={resultData?.length === 0} />
                                                  <label className="table_check ms-0" htmlFor="1">
                                                    Select All
                                                  </label>
                                                </span>
                                              </p>
                                            </th>
                                            <th scope="col">Created</th>
                                            <th scope="col">Applicant</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Test</th>
                                            <th scope="col">Result</th>
                                            <th scope="col">Action</th>
                                          </tr>
                                          <tr></tr>
                                        </thead>
                                        <tbody>
                                          {
                                            resultLoader ? (
                                              <tr>
                                                <td colSpan="7">
                                                  <div className="d-flex align-items-center justify-content-center" style={{ textAlign: "center", height: "100px", }}>
                                                    <div className="loader"></div>
                                                  </div>
                                                </td>
                                              </tr>
                                            ) : LogicalTestResultData && LogicalTestResultData.data && LogicalTestResultData.data?.length > 0 ? LogicalTestResultData.data?.map((e) => (
                                              <tr className="tbl_row" key={e._id}>
                                                <td>
                                                  <div className="text-start ps-2">
                                                    <input name={e._id} onChange={(e) => handleChange(e)} checked={e.isChecked ? true : false} style={{ marginRight: "5px", }} type="checkbox" />
                                                  </div>
                                                </td>
                                                <td style={{ whiteSpace: 'nowrap' }}>
                                                  {moment(e.createdAt).format('YYYY-MM-DD')}
                                                </td>
                                                <td>
                                                  <button className="btn p-0 fw-bold border-0" value={e?._id} onClick={handleResultShow}>
                                                    {e?.candidate?.name}
                                                  </button>
                                                </td>
                                                <td>
                                                  <div className="td">
                                                    {e?.candidate?.email}
                                                  </div>
                                                </td>

                                                <td style={{ color: "#3ab2e4", cursor: "pointer", }} onClick={() => { permissionList?.includes("65bb973f53e762c89b9af17b") && navigate(`/test/logical-test-details/${e?.test?._id}`, { state: { from: location } }); }}>
                                                  {" "}
                                                  <div className="td">
                                                    {e?.test?.title}
                                                    <FiArrowUpRight />
                                                  </div>
                                                </td>

                                                <td>
                                                  <div className="td">
                                                    {/* <span id="table_btn" style={{ whiteSpace: 'nowrap' }} className={classNames("result text-uppercase", { "bg-warning": e?.status === "pending", "bg-danger": e?.status === "started", "bg-success": e?.status === "finished" })}> */}
                                                    <span id="table_btn" style={{ whiteSpace: 'nowrap' }} className={classNames("result text-uppercase", { "bg-warning": e?.status === "IN PROGRESS", "bg-danger": e?.status === "ABANDONED", "bg-success": e?.status === "COMPLETED" })}>
                                                      {e?.status}
                                                    </span>
                                                  </div>
                                                  {
                                                    (e?.status === "COMPLETED") && (
                                                      <p className="text-success m-2">
                                                        {e?.totalScore.toFixed()}/100
                                                      </p>
                                                    )
                                                  }
                                                </td>

                                                <td className="table_ico">
                                                  {
                                                    (!e.isArchived && (e?.status === "COMPLETED")) && (
                                                      <div className="table_icons_div">
                                                        <button onClick={() => { generatePDF(e); }}>
                                                          <span className="d_icon">
                                                            <BsDownload />
                                                          </span>
                                                          <div>Report</div>
                                                        </button>
                                                        {
                                                          permissionList?.includes("65bb9b2853e762c89b9af185") && (
                                                            <button onClick={() => { navigate(`/logical-answer-sheet/${e?._id}`); }}>
                                                              <span className="c_icon">
                                                                <TiDocumentText />
                                                              </span>
                                                              <div>Answer</div>
                                                            </button>
                                                          )
                                                        }
                                                      </div>
                                                    )
                                                  }

                                                  <div className="table_icons_div">
                                                    <button onClick={() => handleLogicalStatus(e?._id)}>
                                                      {
                                                        e?.isArchived ? (
                                                          <>
                                                            <span>
                                                              <img className="table_image" src="/logos/unarchived.svg" alt="logos" value={e?._id} />
                                                            </span>
                                                            <div className="ms-4" value={e?._id}>
                                                              Unarchive
                                                            </div>
                                                          </>
                                                        ) : (
                                                          <>
                                                            <span className="b_icon text-white">
                                                              <BiArchiveIn value={e?._id} />
                                                            </span>
                                                            <div value={e?._id}>
                                                              Archive
                                                            </div>
                                                          </>
                                                        )
                                                      }
                                                    </button>
                                                  </div>


                                                </td>

                                              </tr>


                                            )) : (
                                              <tr>
                                                <td colSpan="7">
                                                  <b style={{ color: "grey" }}>
                                                    No Data
                                                  </b>
                                                </td>
                                              </tr>
                                            )
                                          }
                                        </tbody>
                                      </table>
                                    </div>
                                  ) : (
                                    <div className="table-responsive  scroll_table" >
                                      <table className="table table-striped  table-md test_table">
                                        <thead
                                          id="table-header"
                                          className="thead-dark sticky-top"
                                        >
                                          <tr className="table_row">
                                            <th scope="col" width="123px">
                                              <p className="mb-0 ps-2 d-flex align-items-center">
                                                <span className="outer_table_check" style={{ whiteSpace: 'nowrap' }}>
                                                  <input
                                                    name="allSelect"
                                                    onChange={(e) =>
                                                      handleChange(e)
                                                    }
                                                    className="table_check m-0"
                                                    type="checkbox"
                                                    checked={
                                                      !selectAll ? false : true
                                                    }
                                                    id="1"
                                                    disabled={resultData?.length === 0}
                                                  />{" "}
                                                  <label
                                                    className="table_check ms-0"
                                                    htmlFor="1"
                                                  >
                                                    Select All
                                                  </label>
                                                </span>
                                              </p>
                                            </th>
                                            <th scope="col">Created</th>
                                            <th scope="col">Applicant</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Test</th>
                                            <th scope="col">Result</th>
                                            <th scope="col">Action</th>
                                          </tr>
                                          <tr></tr>
                                        </thead>
                                        <tbody>
                                          {
                                            resultLoader ? (
                                              <tr>
                                                <td colSpan="7">
                                                  <div className="d-flex align-items-center justify-content-center" style={{ textAlign: "center", height: "100px", }}>
                                                    <div className="loader"></div>
                                                  </div>
                                                </td>
                                              </tr>
                                            ) : resultData?.length > 0 ? resultData?.map((e, i) => (
                                              <tr className="tbl_row" key={i}>
                                                <td>
                                                  <div className="text-start ps-2">
                                                    <input name={e._id} onChange={(e) => handleChange(e)} checked={e.isChecked ? true : false} style={{ marginRight: "5px", }} type="checkbox" />
                                                  </div>
                                                </td>
                                                <td style={{ whiteSpace: 'nowrap' }}>
                                                  {e.createdAt?.slice(0, 10)}
                                                </td>
                                                <td>
                                                  <button className="btn p-0 fw-bold border-0" value={e?._id} onClick={handleResultShow}>
                                                    {e?.applicantName}{" "}
                                                  </button>
                                                </td>
                                                <td>
                                                  <div className="td">
                                                    {e?.applicantEmail}
                                                  </div>
                                                </td>
                                                {
                                                  props.title == "Test_Results" ? (
                                                    <td style={{ color: "#3ab2e4", cursor: "pointer", }} onClick={() => { permissionList?.includes("65bb973f53e762c89b9af17b") && navigate(`/test/inner-test/${e.testID}`, { state: { from: location } }); }}>
                                                      {" "}
                                                      <div className="td">
                                                        {e?.testName}
                                                        <FiArrowUpRight />
                                                      </div>
                                                    </td>
                                                  ) : props.title == "Archive_Test_Results" ? (
                                                    <td style={{ color: "#3ab2e4", cursor: "pointer", }} onClick={() => { permissionList?.includes("65bb973f53e762c89b9af17b") && navigate(`/test/inner-test/${e?.testID}`, { state: { from: location } }); }}>
                                                      {" "}
                                                      <div className="td">
                                                        {e?.testName}
                                                        <FiArrowUpRight />
                                                      </div>
                                                    </td>
                                                  ) : null
                                                }
                                                <td>
                                                  <div className="td">
                                                    <span id="table_btn" style={{ whiteSpace: 'nowrap' }} className={classNames("result", { "bg-warning": e?.testStatus === "IN PROGRESS", "bg-danger": e?.testStatus === "ABANDONED", "bg-success": e?.testStatus === "COMPLETED", })}>
                                                      {e?.testStatus}
                                                    </span>
                                                  </div>
                                                  {
                                                    e?.testStatus === "COMPLETED" ? (
                                                      <p className="text-success m-2 ">
                                                        {e?.marks.toFixed()}/100
                                                      </p>
                                                    ) : null
                                                  }
                                                </td>
                                                {props.title === "Test_Results" ? (
                                                  <td className="table_ico">
                                                    {
                                                      e?.testStatus === "COMPLETED" ? (
                                                        <div className="table_icons_div">
                                                          <button onClick={() => { generatePDF(e); }}>
                                                            <span className="d_icon">
                                                              <BsDownload />
                                                            </span>
                                                            <div>Report</div>
                                                          </button>
                                                          {
                                                            permissionList?.includes("65bb9b2853e762c89b9af185") && (
                                                              <button onClick={() => { navigate(`/answer-sheet/${e?._id}`); }}>
                                                                <span className="c_icon">
                                                                  <TiDocumentText />
                                                                </span>
                                                                <div>Answer</div>
                                                              </button>
                                                            )
                                                          }
                                                          {
                                                            e?.isArchived === false ? (
                                                              <button className="btnclick" value={e?._id} onClick={handleArchive}>
                                                                <span className="b_icon text-white">
                                                                  <BiArchiveIn value={e?._id} />
                                                                </span>
                                                                <div value={e?._id}>
                                                                  Archive
                                                                </div>
                                                              </button>
                                                            ) : (
                                                              <button value={e?._id} onClick={handleUnArchive}>
                                                                <span>
                                                                  <img className="table_image" src="/logos/unarchived.svg" alt="logos" value={e?._id} />
                                                                </span>
                                                                <div className="ms-4" value={e?._id}>
                                                                  Unarchive
                                                                </div>
                                                              </button>
                                                            )
                                                          }
                                                        </div>
                                                      ) : (
                                                        <div className="table_icons_div">
                                                          {
                                                            e?.isArchived === true ? (
                                                              <button value={e?._id} onClick={handleUnArchive}>
                                                                <span>
                                                                  <img className="table_image" src="/logos/unarchived.svg" alt="logos" value={e?._id} />
                                                                </span>
                                                                <div className="ms-4" value={e?._id}>
                                                                  Unarchive{" "}
                                                                </div>
                                                              </button>
                                                            ) : (
                                                              <button className="btnclick" value={e?._id} onClick={handleArchive}>
                                                                <span className="b_icon text-white">
                                                                  <BiArchiveIn value={e?._id} />
                                                                </span>
                                                                <div value={e?._id}>
                                                                  Archive
                                                                </div>
                                                              </button>
                                                            )
                                                          }
                                                        </div>
                                                      )
                                                    }
                                                  </td>
                                                ) : props.title === "Archive_Test_Results" ? (
                                                  <td className="">
                                                    <div className="table_icons_div">
                                                      <button value={e?._id} onClick={handleUnArchive}>
                                                        <span>
                                                          <img className="table_image" src="/logos/unarchived.svg" alt="logos" value={e?._id} />
                                                        </span>
                                                        <div className="text-muted ps-5" value={e?._id}>
                                                          {" "}
                                                          Unarchive
                                                        </div>
                                                      </button>
                                                    </div>
                                                  </td>
                                                ) : null}
                                              </tr>
                                            )) : (
                                              <tr>
                                                <td colSpan="7">
                                                  <b style={{ color: "grey" }}>
                                                    No Data
                                                  </b>
                                                </td>
                                              </tr>
                                            )
                                          }
                                        </tbody>
                                      </table>
                                    </div>
                                  )
                                }

                              </div>
                              {
                                totalPages == undefined ? null : (
                                  <>
                                    <div className="parentPage">
                                      <Paginate currentPage={page} totalPages={totalPages} onPageChange={(e) => pageChange(e)} />
                                    </div>
                                  </>
                                )
                              }
                              <div className="pagination-container"></div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={resultshow}
        onHide={handleResultClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header d-flex flex-wrap align-items-start border-0 pb-4">
          <div className="w-75">
            <h6 className="modal-title">
              Candidate Name -{" "}
              <span className="fw-bold">{singleResultData?.applicantName}</span>
            </h6>
            <p>
              Email Address -{" "}
              <span className="fw-bold">
                {singleResultData?.applicantEmail}
              </span>
            </p>
          </div>
          <button
            className="btn btn-danger bg-danger border-0"
            onClick={handleResultClose}
          >
            {" "}
            <IoMdClose />
          </button>
          <div className="w-100">
            <p className="text-primary mb-1">
              Date - <span> {singleResultData?.createdAt?.slice(0, 10)} </span>{" "}
            </p>
            <p className="text-primary mb-1">
              Time - <span> {singleResultData?.createdAt?.slice(11, 19)}</span>{" "}
            </p>
          </div>
        </div>
        <Modal.Body className="px-2 py-0">
          <div className="result_content px-2">
            <div className="row g-3 py-2 border-bottom border-top">
              <div className="col-auto">
                <div className="result_header">
                  <span className="result_circle"></span>
                  <span className="ms-2">IP Address</span>
                </div>
                <p className="fw-bold ms-3">122.182.136.2</p>
              </div>
              <div className="col-auto">
                <div className="result_header">
                  <span className="result_circle"></span>
                  <span className="ms-2">ID</span>
                </div>
                <p className="fw-bold ms-3">mxlghy1</p>
              </div>
            </div>
            {singleResultData?.applicantExtraDetails &&
              <div className="row border-bottom py-3">
                <div className="col-auto">
                  <div className="result_header">
                    <span className="result_circle"></span>
                    <span className="ms-2">Links</span>
                  </div>
                  <p className="fw-bold my-1 ms-3">
                    {singleResultData?.applicantExtraDetails}
                  </p>
                </div>
              </div>
            }
            <div className="row py-3 g-3 justify-content-between">
              <div className="col-auto">
                <div className="result_header">
                  <span className="result_circle"></span>
                  <span className="ms-2">Test Given</span>
                </div>
                <p className="fw-bold ms-3">{singleResultData?.testName}</p>
              </div>
              <div className="col-auto">
                <div className="result_header">
                  <span className="result_circle"></span>
                  <span className="ms-2">Result</span>
                </div>
                {singleResultData?.testStatus === "COMPLETED" ? (
                  <p className="fw-bold text-uppercase text-success ms-3">
                    {singleResultData?.testStatus}
                  </p>
                ) : singleResultData?.testStatus === "IN PROGRESS" ? (
                  <p className="fw-bold text-uppercase text-warning ms-3">
                    {singleResultData?.testStatus}
                  </p>
                ) : (
                  <p className="fw-bold text-uppercase text-danger ms-3">
                    {singleResultData?.testStatus}
                  </p>
                )}
              </div>
              {singleResultData?.testStatus === "COMPLETED" &&
                <div
                  className="col-auto table_icons_div"
                  onClick={() => {
                    generatePDF(singleResultData);
                  }}
                >

                  <button>
                    <span className="d_icon">
                      <BsDownload />
                    </span>
                    <div> Report</div>
                  </button>
                </div>
              }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TestResult;