import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import "./jobs.css";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  designationList,
  locationList,
  experienceLevel,
  skills,
} from "../../Redux/CandidateReducer/action";
import {
  currencyList,
  jobAdd,
  jobUpdate,
  specificeJob,
  typeList,
} from "../../Redux/JobReducer/action";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BlurLoader } from "../../Components/BlurLoader/BlurLoader";

import CreatableSelect from 'react-select/creatable';

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: state.isFocused ? "0px solid #86b7fe" : "#000",
    backgroundColor: state.isDisabled ? "#ccc" : "#fff",
    borderRadius: "0.375rem",
    minHeight: "45px",
    boxShadow: state.isFocused && "0 0 0 0.25rem rgba(13,110,253,.25)",
    color: state.isSelected ? "#86b7fe" : "0 0 0 0.25rem rgba(13,110,253,.25)",
    fontSize: "14px",
    "&:hover": {
      borderColor: state.isFocused ? "#86b7fe" : "#86b7fe",
      boxShadow: "0 0 0 0.25rem rgba(13,110,253,.25)",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#333",
  }),
  input: (provided) => ({
    ...provided,
    color: "#333",
    fontSize: "14px",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused || state.isSelected ? "#86b7fe" : "#fff",
    color: state.isSelected ? "#333" : "#333",
    fontSize: "14px",
    "&:hover": {
      color: state.isFocused ? "#333" : "#333",
      boxShadow: "0 0 0 0.25rem rgba(13,110,253,.25)",
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "#eee",
    borderRadius: "0.375rem",
  }),
  menuList: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    color: "#eee",
    borderRadius: "0.375rem",
  }),
};

const numberOptions = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15+" },
];

const CreateJobs = (props) => {
  const jobRef = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null),
  useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
  const { id } = useParams();
  const skillList = useSelector((state) => state.Candidate_Reducer.skillsData);
  const designationData = useSelector(
    (state) => state.Candidate_Reducer.designationData
  );
  const locationData = useSelector(
    (state) => state.Candidate_Reducer.locationData
  );
  const experienceData = useSelector(
    (state) => state.Candidate_Reducer.experienceData
  );
  const TypeLists = useSelector((state) => state.Job_Reducer?.typeData);
  const currencyTypeLists = useSelector(
    (state) => state.Job_Reducer.currencyData
  );
  const specficJobData = useSelector(
    (state) => state.Job_Reducer.specficJobData
  );



  const [currancyType, setCurrancyType] = useState("");
  const [description, setDescription] = useState("");
  const [designation_experience_id, setDesignation_experience_id] = useState({});
  const [designation_id, setDesignation_id] = useState("");
  const [note, setNote] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [location, setLocation] = useState("");
  const [oldLocation, setOldLocation] = useState("");
  const [locationId, setLocationId] = useState("");
  const [preference, setPreference] = useState("");
  const [preferenceSecond, setPreferenceSecond] = useState(" ");
  const [showPreference, setShowPreference] = useState("");
  const [typeData, setTypedata] = useState([]);
  const [submitLoader, setsubmitLoader] = useState(false);
  const [jobError, setJobError] = useState({});

  const [salaryMax, setSalaryMax] = useState("");
  const [salaryMin, setSalaryMin] = useState("");
  const [vaccancy, setVaccancy] = useState("");
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [skill, setskill] = useState([]);
  const [experienceMax, setExperienceMax] = useState("");
  const [experienceMin, setExperienceMin] = useState("");
  const [selectedValue, setSelectedValue] = useState();

  const usersPermission = localStorage?.getItem("userPermission")
  const permissionList = usersPermission?.split(',');
  const errorGenerate = (field, message) => {
    !field && !message ? setJobError() :
      setJobError((prev) => {
        return {
          ...prev,
          [field]: message,
        };
      })
  }
  const handleChange = (selectedOption) => {
    delete jobError.skill
    setSelectedValue((prev) => [...selectedOption]);
    const option = selectedOption.map((e) => {
      return skillList.find((element) => {
        if (element.skill_id == e.value) {
          return { value: element.skill_id, label: element.name, element };
        }
      });
    })
    setskill(option)
  };

  const programmingLanguages = () => {
    if (skillList) {
      return skillList?.map((e) => {
        return { value: e.skill_id, label: e.name };
      });
    }
  };

  const roleOptions = () => {
    if (experienceData) {
      return experienceData.filter((item) => item.status != 0).map((e) => ({ value: e.id, label: e.name }));
    }
    return [];
  };
  const handleDesiExp = (roleOptions) => {
    delete jobError.designation_experience_id
    const option = experienceData.find(element => element?.id === roleOptions.value);
    setDesignation_experience_id(option);

    if (option.name == "Fresher") {
      setExperienceMin(0)
      setExperienceMax(0)
    } else {
      setExperienceMin("")
      setExperienceMax("")
    }
  };
  const handleDesignation = (event) => {
    delete jobError.designation_id
    const option = designationData?.find(
      (element) => { return (element?.id === event.value) }
    );
    setDesignation_id(option);
  }


  const deisgnationOptions = () => {
    if (designationData?.length > 0) {
      return designationData?.filter((item) => item.status != 0)?.map((e) => ({ value: e.id, label: e.name }));
    }
    return [];
  };

  const locationOptions = () => {
    const options = locationData?.length > 0
      ? locationData.map((location) => ({
        value: location.value,
        label: location.label,
      })) : locationData.map(() => ({
        value: "Others",
        label: "Others",
      }));
    options.push({ value: 'others', label: 'Others' });
    return options;
  };

  // const handleLocation = (event) => {
  //   delete jobError.locationId
  //   const option = locationData?.find(
  //     (element) => { return (element?.id === event.value) }
  //   );
  //   setLocationId(option);
  // }

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setTitle(title.trim());
  //   setDescription(description.trim());

  //   if (!title.trim()) {
  //     errorGenerate("title", "Please Provide Job Title")
  //   }
  //   if (props.title !== "edit" && !designation_experience_id) {
  //     errorGenerate("designation_experience_id", "Please Select Job Role")
  //   }
  //   if (props.title !== "edit" && !designation_id) {
  //     errorGenerate("designation_id", "Please Select Designation")
  //   }
  //   if (props.title !== "edit" && !locationId) {
  //     errorGenerate("locationId", "Please Select Location")
  //   }
  //   if (preference.length == 0) {
  //     errorGenerate("preference", "Please select preference")
  //   }
  //   if (!description.trim()) {
  //     errorGenerate("description", "Please Provide Description")
  //   }
  //   if (skill?.length == 0) {
  //     errorGenerate("skill", "Please Select SKill")
  //   }
  //   if (experienceMin === "") {
  //     errorGenerate("experienceMin", "Please Provide minimum experience")
  //   }
  //   if (experienceMax === "") {
  //     errorGenerate("experienceMax", "Please Provide maximum experience")
  //   }
  //   if (parseInt(experienceMax) < parseInt(experienceMin)) {
  //     errorGenerate("experienceMax", "Please Select Valid Maximum Experience Year")
  //   }
  //   if (!vaccancy) {
  //     errorGenerate("vaccancy", "Please Provide number of Vacancy")
  //   }
  //   if (!location) {
  //     errorGenerate("location", "Please Provide Job Location")
  //   }

  //   if (!currancyType) {
  //     errorGenerate("currancyType", "Please Select Currancy Type")
  //   }
  //   if (!salaryMin) {
  //     errorGenerate("salaryMin", "Please Provide Minimum Salary")
  //   }
  //   if (!salaryMax) {
  //     errorGenerate("salaryMax", "Please Provide Maximum Salary")
  //   } if (parseInt(salaryMin) == 0) {
  //     errorGenerate("salaryMin", "Minimum Salary Should be a Valid Number")
  //   } if (parseInt(salaryMax) == 0) {
  //     errorGenerate("salaryMax", "Maximum Salary Should be a Valid Number")
  //   }
  //   if (parseInt(salaryMax) < parseInt(salaryMin)) {
  //     errorGenerate("salaryMax", "Please Provide Maximum Salary More Than Minimum Salary")
  //   }


  //   if (!title.trim()) {
  //     jobRef[2].current.focus()
  //   }
  //   else if (!designation_experience_id) {
  //     jobRef[3].current.focus()
  //   }
  //   else if (!designation_id) {
  //     jobRef[4].current.focus()
  //   }
  //   else if (preference.length == 0) {
  //     jobRef[5].current.focus()
  //   }
  //   else if (!description.trim()) {
  //     jobRef[6].current.focus()
  //   }
  //   else if (skill?.length == 0) {
  //     jobRef[7].current.focus()
  //   }
  //   else if (experienceMin === "") {
  //     jobRef[8].current.focus()
  //   }
  //   else if (experienceMax === "") {
  //     jobRef[9].current.focus()
  //   }
  //   else if (parseInt(experienceMax) < parseInt(experienceMin)) {
  //     jobRef[9].current.focus()
  //   }
  //   else if (!vaccancy) {
  //     jobRef[11].current.focus()
  //   }
  //   else if (!location) {
  //     jobRef[12].current.focus()
  //   }

  //   else if (!currancyType) {
  //     jobRef[13].current.focus()
  //   }
  //   else if (!salaryMin) {
  //     jobRef[14].current.focus()
  //   }
  //   else if (!salaryMax) {
  //     jobRef[15].current.focus()

  //   } else if (parseInt(salaryMin) == 0) {
  //     jobRef[14].current.focus()
  //   } else if (parseInt(salaryMax) == 0) {
  //     jobRef[14].current.focus()
  //   }
  //   else if (parseInt(salaryMax) < parseInt(salaryMin)) {
  //     jobRef[15].current.focus()
  //   }
  //   else {
  //     setsubmitLoader(true)
  //     if (id) {
  //       e.preventDefault();
  //       const time = preferenceSecond;
  //       dispatch(
  //         jobUpdate(
  //           navigate,
  //           token,
  //           id,
  //           currancyType,
  //           description,
  //           designation_experience_id,
  //           designation_id,
  //           experienceMax,
  //           experienceMin,
  //           image,
  //           location,
  //           locationId,
  //           note,
  //           oldLocation,
  //           time,
  //           salaryMax,
  //           salaryMin,
  //           skill,
  //           title,
  //           vaccancy
  //         )
  //       ).then((res) => {
  //         setsubmitLoader(false)
  //       }).catch((error) => {
  //         setsubmitLoader(false)
  //       })
  //         ;
  //     } else {
  //       dispatch(
  //         jobAdd(
  //           navigate,
  //           token,
  //           currancyType,
  //           description,
  //           designation_experience_id,
  //           designation_id,
  //           experienceMax,
  //           experienceMin,
  //           image,
  //           location,
  //           locationId,
  //           note,
  //           preference,
  //           salaryMax,
  //           salaryMin,
  //           skill,
  //           title,
  //           vaccancy
  //         )
  //       ).then(() => {
  //         setsubmitLoader(false)
  //       }).catch(() => {
  //         setsubmitLoader(false)
  //       });
  //     }
  //   }

  // };
    const handleSubmit = (e) => {
      navigate("/jobs/create-job/questions")
    }


  function swipeZeros(str) {
    let result = "";
    let hasNonZero = false;

    for (let char of str) {
      if (!isNaN(char)) {
        if (char !== "0") {
          result += char;
          hasNonZero = true;
        } else if (hasNonZero) {
          result += char;
        }
      }
    }

    return result || "0";
  }

  const currencyOptions = () => {
    if (currencyTypeLists) {
      return currencyTypeLists?.map((e) => ({ value: e.id, label: e.name }));
    }
    return [];
  };

  const handleCurrency = (event) => {
    delete jobError.currancyType
    const option = currencyTypeLists?.find(
      (element) => element?.id === event?.value
    );
    setCurrancyType(option);
  };


  const handleImage = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (
        file.type === "image/jpeg" ||
        file.type === "image/gif" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml" // SVG MIME type
      ) {
        if (file.size < 5000000) { // 5 MB limit
          const reader = new FileReader();
          reader.onload = function (event) {
            const img = new Image();
            img.onload = () => {
              const height = parseInt(img.height)
              const widht = parseInt(img.width)
              const validDimention = (widht / height) > 6 || (widht / height) < 3
              // Validate image dimensions
              if (validDimention) {
                toast.warn("Image Dimensions must be 3:1 or 6:1.");
                setImage(null);
              } else {
                // Dispatch the action to update profile image
                setImage(event.target.result);
              }
            };
            img.src = event.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          toast.warn("Please upload an image less than 5MB.");
        }
      } else {
        toast.warn("Please upload an image file (JPEG, GIF, PNG, SVG).");
      }
    }
  };
  const editSelectValue = () => {
    const transformedSkills = specficJobData?.skills?.map(skill => ({
      value: skill.id,
      label: skill.name
    }));
    setSelectedValue(transformedSkills)
  }
  const handleTime = (element) => {
    delete jobError.preference
    {
      setPreference([element.id]);
      setPreferenceSecond(element.id);
      setShowPreference(element?.name)
    }
  }
  useEffect(() => {
    dispatch(skills(navigate));
    dispatch(designationList(navigate));
    dispatch(locationList(navigate));
    dispatch(experienceLevel(navigate));
    dispatch(typeList(navigate, token));
    dispatch(currencyList(navigate, token));
    if (props.title == "edit") {
      dispatch(specificeJob(navigate, token, id));
    }

  }, []);


  useEffect(() => {
    if (TypeLists) {
      setTypedata(TypeLists)
    }
  }, [TypeLists])


  const [editDesignationId, setEditDesignationId] = useState('')


  useEffect(() => {
    if (props.title == "edit") {
      const obj = {
        value: specficJobData?.currency_type_two?.id,
        label: specficJobData?.currency_type_two?.name,
      };
      setskill([]);
      let options = specficJobData?.skills?.map((skills) => {
        return { value: skills.id, label: skills.name };
      });
      setSelectedValue(options ? options : "");
      setCurrancyType(
        specficJobData?.currency_type_two
          ? specficJobData?.currency_type_two
          : ""
      );
      setDescription(
        specficJobData?.description ? specficJobData?.description : ""
      );
      setExperienceMin(
        specficJobData.experience_min ? specficJobData?.experience_min / 12 : 0
      );
      setExperienceMax(
        specficJobData.experience_max ? specficJobData?.experience_max / 12 : 0
      );
      setEditDesignationId(specficJobData?.designation_id);
      const des_id = designationData?.find((element) => {
        return element?.id === specficJobData?.designation_id;
      });
      setDesignation_id(des_id ? des_id : "");
      setNote(specficJobData.note ? specficJobData.note : "");
      setTitle(specficJobData.title ? specficJobData.title : "");
      setImage("");
      setLocation(specficJobData.location ? specficJobData.location : "");
      setOldLocation(specficJobData.location ? specficJobData.location : "");
      setLocationId(
        specficJobData.location_id ? specficJobData.location_id : ""
      );
      setSalaryMax(specficJobData.salary_max ? specficJobData.salary_max : "");
      setSalaryMin(specficJobData.salary_min ? specficJobData.salary_min : "");
      setVaccancy(
        specficJobData.total_vacancy ? specficJobData.total_vacancy : ""
      );

      setShowPreference(specficJobData.preference);
      editSelectValue();
    }
    setskill(specficJobData?.skills);
  }, [specficJobData]);

  useEffect(() => {
    const exp = experienceData.find(
      (element) =>
        element?.experience_level_id == specficJobData?.designation_exp_id
    );
    setDesignation_experience_id(exp ? exp : "");
  }, [specficJobData?.designation_exp_id])
  useEffect(() => {
    const option = TypeLists?.find((element) => {
      return element?.name == specficJobData.preference;
    });
    props.title == "edit" && setPreference([option?.id]);
  }, [TypeLists, specficJobData.preference]);


  useEffect(() => {
    setImage(specficJobData?.image);
  }, [specficJobData?.image]);

  useEffect(() => {
    if (props.title == "edit") {
      const option = TypeLists?.find(
        (element) => { return (element?.name == specficJobData.preference) }
      );

      setPreferenceSecond(option?.id);
    }
  }, [specficJobData.preference])

  useEffect(() => {
    if (props.title == "edit") {
      const option = designationData?.find(
        (element) => { return (element?.designation_id === editDesignationId) }
      );
      setDesignation_id(option);
    }
  }, [editDesignationId]);


  useEffect(() => {
    setImage(image);
  }, [image]);

  const menuClick = () => {
    props.onSidedataChange(!props.Sidedata);
  };
  useEffect(() => {
    if (props.title == "create") {
      setskill([]);
      setSelectedValue("");
      setImage("");
      setCurrancyType("");
      setDescription("");
      setDesignation_experience_id("");
      setExperienceMin("")
      setExperienceMax("")
      setEditDesignationId("")
      setDesignation_id("");
      setNote("");
      setTitle("");
      setImage("");
      setLocation("");
      setLocationId("");
      setSalaryMax("");
      setSalaryMin("");
      setVaccancy("");
      setPreference([]);
      setPreferenceSecond("");
      setShowPreference("")
      editSelectValue("")
    }
  }, [props.title, id])

  const removeFile = (e) => {
    setImage('')
  }
  return (
    <div className="creatJobsPage ">
      <div className="container-fluid">
        <div className="paheHeader">
          <div className="row g-3 align-items-cneter justify-content-between">
            <div className="col-auto">
              <h5 className="pageTitle">
                <button className="fa_bars  d-lg-none" onClick={menuClick}>
                  <i className="fa-solid fa-bars"></i>
                </button>
                {props.title == "edit" ? "Job Post Update" : "Post Job"}
              </h5>
            </div>
            {permissionList?.includes("61ae06f5edb406550227b943") && (
              <div className="col-auto">
                <button className="btn btn-job">
                  <Link className="text-light" to={"/jobs/create-job-list"}>
                    Jobs List
                  </Link>
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="pageBody py-4">
          {props.title == "edit" && !specficJobData?.skills_id && !image ? (
            <BlurLoader />
          ) : null}
          <div className="row">
            <div className="col-xxl-10 col-xl-11">
              <div className="jobBanner text-end">
                <label
                  htmlFor="bannerImageUplaod"
                  className="btn btn-job-upload mb-1 border-0 text-secondary"
                >
                  <i className="fa-regular fa-image"></i>
                  <span className="ms-1">Upload Banner Image</span>
                </label>
                <input
                  ref={jobRef[1]}
                  type="file"
                  id="bannerImageUplaod"
                  accept="image/jpeg,image/gif,image/png"
                  onChange={(e) => {
                    handleImage(e);
                  }}
                  className="d-none"
                />
                <div className="bannerImg mb-2 ">
                  {image ? (
                    <img
                      src={image}
                      alt="Preview"
                      className="img-fluid w-100 "
                      accept="image/jpeg,image/gif,image/png"
                      style={{ maxHeight: "170px" }}
                    />
                  ) : (
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/images/default_job_banner.jpg`}
                      alt="Default"
                      accept="image/jpeg,image/gif,image/png"
                      className="img-fluid w-100"
                    />
                  )}
                </div>
                <div>
                  <p
                    className=" text-center  d-flex align-items-center justify-content-center mt-2"
                    style={{ height: "20px" }}
                  >
                    <span className={image ? "remove_image_text" : ""}>
                      {image
                        ? image
                        : "Dimension of the image should be width x height 3:1 or 6:1 or 1024 x 204"}
                    </span>
                    <span
                      className=" d-inline"
                      style={{ color: "red", cursor: "pointer" }}
                    >
                      <IoMdClose
                        className={!image && "d-none "}
                        onClick={(e) => removeFile(e)}
                      />
                    </span>
                  </p>
                </div>
              </div>
              <div className="formArea mt-4">
                <div className="row g-3 align-items-end">
                  <div className="col-12">
                    <label htmlFor="" className="form-label">
                      Job Title <span className="text-danger">*</span>
                    </label>
                    <input
                      ref={jobRef[2]}
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        delete jobError?.title;
                        setTitle(e.target.value);
                      }}
                      placeholder="Enter job title"
                      value={title ? title : ""}
                    />
                  </div>
                  <p className="text-danger ms-3">{jobError?.title}</p>
                  <div className="col-lg-6">
                    <label htmlFor="" className="form-label">
                      Job Role <span className="text-danger">*</span>
                    </label>
                    <Select
                      name=""
                      id=""
                      ref={jobRef[3]}
                      className=""
                      options={roleOptions()}
                      styles={customStyles}
                      value={
                        roleOptions() && designation_experience_id?.name
                          ? roleOptions()?.find(
                            (e) => e.label == designation_experience_id?.name
                          )
                          : ""
                      }
                      isDisabled={props.title == "edit" ? true : false}
                      onChange={(e) => handleDesiExp(e)}
                      placeholder="Designation Experience"
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className="col-lg-6">
                    <Select
                      ref={jobRef[4]}
                      name=""
                      id=""
                      options={deisgnationOptions()}
                      isDisabled={props.title == "edit" ? true : false}
                      styles={customStyles}
                      placeholder="Designation"
                      value={
                        deisgnationOptions() && designation_id?.name
                          ? deisgnationOptions()?.find(
                            (e) => e.label == designation_id?.name
                          )
                          : ""
                      }
                      onChange={(e) => handleDesignation(e)}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="text-danger ms-3">
                      {jobError?.designation_experience_id}
                    </p>
                    <p className="text-danger me-3 align-item-end">
                      {jobError?.designation_id}
                    </p>
                  </div>

                  <div className="col-12">
                    <label htmlFor="" className="form-label">
                      Job Preference <span className="text-danger">*</span>
                    </label>
                    <div className="form-control border-0 pt-3">
                      <div className="row g-3">
                        {typeData?.length > 0 &&
                          typeData?.map((data, index) => (
                            <div key={data.id} className="col-auto">
                              <div className="form-check">
                                <input
                                  ref={jobRef[5]}
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  checked={
                                    showPreference === data?.name ? true : false
                                  }
                                  onChange={() => handleTime(data)}
                                  id={data.id}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={data.id}
                                >
                                  {data?.name}
                                </label>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                  <p className="text-danger ms-3">{jobError?.preference}</p>
                  <div className="col-12">
                    <label htmlFor="" className="form-label">
                      Job Description <span className="text-danger">*</span>
                    </label>
                    <textarea
                      ref={jobRef[6]}
                      name=""
                      id=""
                      rows="5"
                      onChange={(e) => {
                        delete jobError.description;
                        setDescription(e.target.value);
                      }}
                      style={{ resize: "none" }}
                      className="form-control"
                      value={description ? description : ""}
                    ></textarea>
                  </div>
                  <p className="text-danger ms-3">{jobError?.description}</p>
                  <div className="col-lg-6">
                    <label htmlFor="" className="form-label">
                      Required Skills <span className="text-danger">*</span>
                    </label>
                    {props?.title == "edit" ? (
                      <Select
                        // ref={jobRef[7]}
                        name=""
                        id=""
                        className=""
                        options={programmingLanguages()}
                        isMulti
                        styles={customStyles}
                        placeholder="Skills"
                        value={
                          selectedValue
                            ? selectedValue?.map((value) => ({
                              value: value.value,
                              label: value.label,
                            }))
                            : "loading..."
                        }
                        onChange={(e) => handleChange(e)}
                      />
                    ) : (
                      <Select
                        ref={jobRef[7]}
                        name=""
                        id=""
                        className=""
                        options={programmingLanguages()}
                        isMulti
                        styles={customStyles}
                        placeholder="Skills"
                        // defaultValue={selectedValue?.map((value) => ({value: value.value, label: value.label}))}
                        onChange={(e) => handleChange(e)}
                      />
                    )}
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="" className="form-label">
                      Experience in years <span className="text-danger">*</span>
                    </label>
                    <div className="row g-3 align-items-center justify-content-between">
                      <div className="col-sm-5">
                        {
                          <Select
                            ref={jobRef[8]}
                            name=""
                            id=""
                            className=""
                            options={numberOptions}
                            styles={customStyles}
                            value={
                              experienceMin === ""
                                ? [{ value: "", label: "Minimum" }]
                                : numberOptions?.map(
                                  (value) =>
                                    experienceMin == value.value && value
                                )
                            }
                            onChange={(e) => {
                              delete jobError?.experienceMin;
                              delete jobError?.experienceMax;
                              setExperienceMin(e.value);
                            }}
                            components={{
                              IndicatorSeparator: () => null,
                            }}
                          />
                        }
                      </div>
                      <div className="col-auto mx-auto">
                        <span>to</span>
                      </div>
                      <div className="col-sm-5">
                        <Select
                          ref={jobRef[9]}
                          name=""
                          id=""
                          className=""
                          options={numberOptions}
                          styles={customStyles}
                          value={
                            experienceMax === ""
                              ? [{ value: "", label: "Maximum" }]
                              : numberOptions?.map(
                                (value) =>
                                  experienceMax == value.value && value
                              )
                          }
                          onChange={(e) => {
                            delete jobError?.experienceMin;
                            delete jobError?.experienceMax;
                            setExperienceMax(e.value);
                          }}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="text-danger ms-3">{jobError?.skill}</p>
                    <p className="text-danger me-3">
                      {jobError?.experienceMax}
                    </p>
                  </div>
                  <div className="col-12">
                    <label htmlFor="" className="form-label">
                      Others
                    </label>
                    <textarea
                      ref={jobRef[10]}
                      name=""
                      id=""
                      rows="5"
                      className="form-control"
                      placeholder="Notes"
                      value={note}
                      style={{ resize: "none" }}
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                    ></textarea>
                    {/* <p className="text-danger ms-3">{jobError.note}</p> */}
                  </div>
                  <div className="col-lg-6">
                    <label htmlFor="" className="form-label">
                      Total Number Of Vacancy{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      ref={jobRef[11]}
                      type="number"
                      className="form-control"
                      placeholder="Total Vacancy"
                      value={vaccancy} // Should this be `vacancy` instead of `vaccancy`?
                      onWheel={(e) => {
                        e.preventDefault();
                      }}
                      onChange={(e) => {
                        delete jobError.vacancy; // Corrected 'vaccancy' to 'vacancy'
                        setVaccancy(swipeZeros(e.target.value)); // Corrected 'vaccancy' to 'vacancy'
                      }}
                    />
                  </div>
                  {/* <input
                      ref={jobRef[12]}
                      type="text"
                      className="form-control"
                      placeholder="Job Location"
                      value={location ? location : ""}
                      onChange={(e) => {
                        delete jobError.location;
                        setLocation(e.target.value);
                      }}
                    /> */}

                  <div className="col-lg-6">
                    <label htmlFor="" className="form-label">
                      Job Location  <span className="text-danger">*</span>
                    </label>
                    <CreatableSelect
                      ref={jobRef[12]}
                      options={locationOptions()}
                      styles={customStyles}
                      placeholder="Select Job Location"
                      // value={locationOptions().find((option) => option.value === location)}
                      value={locationOptions().find((option) => option.value === location) || { label: location, value: location }}
                      defaultValue={location}
                      onChange={(option) => {
                        delete jobError.location;
                        setLocation(option ? option.value : '');
                      }}
                      onCreateOption={(newLocation) => {
                        const option = { label: newLocation, value: newLocation };
                        setLocation(option.value);
                      }}
                    />
                  </div>

                  <div className="d-flex justify-content-between">
                    <p className="text-danger ms-3">{jobError?.vaccancy}</p>
                    <p className="text-danger me-3">{jobError?.location}</p>
                  </div>
                  <div className="col-md-3">
                    <label htmlFor="" className="form-label">
                      Expected Salary Per Month{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <Select
                      ref={jobRef[13]}
                      name=""
                      id=""
                      className=""
                      value={currencyOptions().find(
                        (month) => month?.value == currancyType?.id
                      )}
                      options={currencyOptions()}
                      styles={customStyles}
                      placeholder="Currency Types"
                      onChange={(e) => {
                        handleCurrency(e);
                      }}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </div>
                  <div className="col-md-9">
                    <div className="row g-3 align-items-center">
                      <div className="col-sm-5">
                        <input
                          ref={jobRef[14]}
                          type="number"
                          className="form-control"
                          value={salaryMin}
                          onChange={(e) => {
                            delete jobError.salaryMax;
                            delete jobError.salaryMin;
                            setSalaryMin(swipeZeros(e.target.value));
                          }}
                          placeholder="Minimun"
                        />
                      </div>
                      <div className="col-sm-auto mx-auto">
                        <span>to</span>
                      </div>
                      <div className="col-sm-5">
                        <input
                          ref={jobRef[15]}
                          type="number"
                          className="form-control"
                          value={salaryMax}
                          onChange={(e) => {
                            delete jobError.salaryMax;
                            delete jobError.salaryMin;
                            setSalaryMax(swipeZeros(e.target.value));
                          }}
                          placeholder="Maximun"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="text-danger ms-3">{jobError?.currancyType}</p>
                    <p className="text-danger me-3">
                      {jobError?.salaryMax || jobError?.salaryMin}
                    </p>
                  </div>
                  <div className="col-12">
                    <button
                      disabled={submitLoader}
                      onClick={(e) => handleSubmit(e)}
                      className="btn btn-submit fs-5 px-5 mt-3"
                    >
                      {props.title == "edit" ? "Update and Next" : "Next"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateJobs;