import axios from "axios";
import { FIND_CANDIDATE_FAILURE, FIND_CANDIDATE_REQUEST, FIND_CANDIDATE_SUCCESS } from "./actionType";
import { errorCall } from "../../ErrorHandling/action";

export const find_candidate_req = ()=>({
    type: FIND_CANDIDATE_REQUEST
});

export const find_candidate_succ = (data)=>({
    type: FIND_CANDIDATE_SUCCESS,
    payload : data
})

export const find_candidate_failure = (err)=>({
    type: FIND_CANDIDATE_FAILURE,
    payload : err
})

const getLocaldata = ()=>{
    const token = localStorage.getItem("token");
    return token
}

export const getCandidate = (id, navigate)=>async (dispatch)=>{
    dispatch( find_candidate_req());
    // const mt = getLocaldata();
    // const config = {
    //     headers: { Authorization: `Bearer ${mt}` }
    //   };

   try {
        const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/candidate/show/${id}`);
        dispatch(find_candidate_succ(res.data));
        return res.data;
    } catch (err) {
        dispatch(find_candidate_failure(err));
        if (err.response.status === 404) {
            localStorage.removeItem("id");
        }
        if (err.message === "Network Error" || err.response.status === 500) {
            dispatch(errorCall(err));
            return err;
        }
    }
}





