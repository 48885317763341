import React, { useEffect, useMemo, useState } from 'react'
import "../../TestPortal/components/css/CreateTest.css";
import { BlurLoader } from '../../Components/BlurLoader/BlurLoader'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { BiInfoCircle, BiPlus } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSingleLogicalTest } from '../../Redux/logicalTest/actions/LogicalTestActions'
import axios from '../../config/axiosNode'
import { toast } from 'react-toastify'
import * as YUP from 'yup'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import Swal from 'sweetalert2';

export default function LogicalTestCreate({ onSidedataChange, newSidedata }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isPublished, setIsPublished] = useState(false)

    const { state } = useLocation()
    const { id } = useParams()

    const { isLoading, data } = useSelector(state => state.singleLogicalTest)

    const formSchema = YUP.object().shape({
        title: YUP.string().required('Provide valid test title for update this test'),
        testVersion: YUP.number().typeError('Provide valid test version for update this test').min(1, 'Provide valid test duration for update this test').max(999, 'Provide valid test duration for update this test'),
        duration: YUP.number().typeError('Provide valid test duration for add this test').min(1, 'Provide valid test duration for add this test').max(999, 'Provide valid test duration for add this test')
    })

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        resolver: yupResolver(formSchema), mode: 'all', defaultValues: {
            title: data?.data?.title,
            testVersion: data?.data?.testVersion,
            duration: data?.data?.duration
        }
    })

    useEffect(() => {
        if (data && data.data) {
            setValue('title', data?.data?.title)
            setValue('testVersion', data?.data?.testVersion)
            setValue('duration', data?.data?.duration)
        }
    }, [data, setValue])

    useMemo(() => fetchSingleLogicalTest(id, dispatch), [dispatch, id])

    const publishTest = () => {
        axios.get(`/api/logical-test/publish-test/${id}`).then((response) => {
            if (response.data.success) {
                toast.success(response.data.message)
                reset()
                fetchSingleLogicalTest(id, dispatch)
                navigate('/test/all-test')
            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response ? error.response.data.message : error.toString())
            console.error(error)
        })
    }

    const draftTest = () => {
        axios.get(`/api/logical-test/draft-test/${id}`).then((response) => {
            if (response.data.success) {
                toast.success(response.data.message)
                // navigate('/test/all-test')
                reset()
                fetchSingleLogicalTest(id, dispatch)
            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response ? error.response.data.message : error.toString())
            console.error(error)
        })
    }

    const deleteCategory = (category) => {
        axios.delete(`/api/logical-test/delete-category/${category}`).then((response) => {
            if (response.data.success) {
                toast.success(response.data.message)
                fetchSingleLogicalTest(id, dispatch)
            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response ? error.response.data.message : error.toString())
            console.error(error)
        })
    }

    const deleteQuestion = (question) => {
        axios.delete(`/api/logical-test/delete-question/${question}`).then((response) => {
            if (response.data.success) {
                toast.success(response.data.message)
                fetchSingleLogicalTest(id, dispatch)
            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response ? error.response.data.message : error.toString())
            console.error(error)
        })
    }

    const formSubmit = (data) => {
        axios.put(`/api/logical-test/update-test/${id}`, data).then((response) => {
            if (response.data.success) {
                toast.success(response.data.message)
                reset()
                fetchSingleLogicalTest(id, dispatch)
                if (isPublished) {
                    publishTest()
                } else {
                    draftTest()
                }
            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response ? error.response.data.message : error.toString())
            console.error(error)
        })
    }

    const deleteTest = () => {
        Swal.fire({
            title: 'Do you want to delete the test?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3ab2e4',
            cancelButtonColor: 'grey',
            cancelButtonText: "No",
            confirmButtonText: 'Yes',

            customClass: {
                confirmButton: 'Swal_confirm',
                cancelButton: 'Swal_cancel',
                icon: 'Swal_icon',
                title: 'Swal_title'
            },
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`api/logical-test/move-to-trash/${id}`).then((result) => {
                    if (result.data.success) {
                        Swal.fire({
                            title: "Test Deleted!",
                            text: result.data.message,
                            icon: "success"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate('/test/all-test')
                            }
                        });
                    } else {
                        Swal.fire({
                            title: "Error",
                            text: "Failed to delete test. Please try again later.",
                            icon: "error"
                        });
                    }
                })
            }
        })
    }

    const duplicateTest = () => {
        axios.get(`/api/logical-test/duplicate-test/${id}`).then((response) => {
            if (response.data.success) {
                toast.success(response.data.message)
                navigate(`/test/edit-logical-test-screen/${response.data.data._id}`, { state: { editable: true } })
                reset()
            } else {
                toast.error(response.data.message)
            }
        }).catch((error) => {
            toast.error(error.response ? error.response.data.message : error.toString())
            console.error(error)
        })
    }

    return (
        <div className="content_app">
            <div className={`body_content`}>
                <div className="container-fluid px-0">
                    <button className="fa_bars  d-lg-none ms-3">
                        <i className="fa-solid fa-bars fs-4"></i>
                    </button>
                    <div className="row g-0">
                        <div className="col-12">
                            <div className="container-fluid px-0 ">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="">
                                            {isLoading && <BlurLoader />}
                                            <div className="create-test-screen px-3 px-lg-4 px-xl-5 pb-5">
                                                <h3 className="create-new-test fw-bold mb-3">
                                                    {state ? state.editable ? 'Edit Logical Test' : 'Create New Logical Test' : 'Create New Logical Test'}
                                                </h3>
                                                <form className="row g-3" onSubmit={handleSubmit(formSubmit)} id='test-form'>

                                                    <div className="col-xl-9 col-lg-8 col-md-7 col-12 first-input-parent">
                                                        {
                                                            state ? state.editable ? (
                                                                <>
                                                                    <div className="title-parent">
                                                                        <label htmlFor="test_title" className="title mb-1">
                                                                            Title
                                                                        </label>
                                                                        <input type="text" className="form-control" placeholder="Enter your test’s name..." name="title" id="test_title" {...register('title')} />
                                                                        {errors.title && <span className='text-danger'>{errors.title.message}</span>}
                                                                    </div>
                                                                    <div className="version-parent my-3">
                                                                        <label htmlFor="test_version mb-1" className="title">
                                                                            Version
                                                                        </label>
                                                                        <div>
                                                                            <input type="number" placeholder="Enter number of version" className="form-control" name="testVersion" {...register('testVersion')} />
                                                                            {errors.testVersion && <span className='text-danger'>{errors.testVersion.message}</span>}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="title-parent">
                                                                        <label htmlFor="test_title" className="title mb-1">
                                                                            Title
                                                                        </label>
                                                                        <input type="text" className="form-control" placeholder="Enter your test’s name..." name="title" id="test_title" value={data?.data?.title} disabled />
                                                                    </div>
                                                                    <div className="version-parent my-3">
                                                                        <label htmlFor="test_version mb-1" className="title">
                                                                            Version
                                                                        </label>
                                                                        <div>
                                                                            <input type="number" placeholder="Enter number of version" className="form-control" name="testVersion" value={data?.data?.testVersion} disabled />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="title-parent">
                                                                        <label htmlFor="test_title" className="title mb-1">
                                                                            Title
                                                                        </label>
                                                                        <input type="text" className="form-control" placeholder="Enter your test’s name..." name="title" id="test_title" value={data?.data?.title} disabled />
                                                                    </div>
                                                                    <div className="version-parent my-3">
                                                                        <label htmlFor="test_version mb-1" className="title">
                                                                            Version
                                                                        </label>
                                                                        <div>
                                                                            <input type="number" placeholder="Enter number of version" className="form-control" name="testVersion" value={data?.data?.testVersion} disabled />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                        <div className="frame-parent">
                                                            <div className="maximum-time-in-mins-parent">
                                                                <label htmlFor="test_time" className="title mb-1">
                                                                    <span> Maximum Time in Mins </span>
                                                                    <span className="we-dont-block">
                                                                        (We don’t block the test after the limit - any excess is just highlighted in the report)
                                                                        <p className="text-primary"> Maximum three digit time is allowed </p>
                                                                    </span>
                                                                </label>
                                                                <div>
                                                                    <input type="text" placeholder="Enter time in minutes" className="form-control number_input" name="maxTime" id="test_time" {...register('duration')} maxLength={3} />
                                                                    {errors.duration && <span className='text-danger'>{errors.duration.message}</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-xl-3 col-lg-4 col-md-5 col-12  rectangle-parent15">
                                                        <div className="group-child33 h-100 p-4">
                                                            {
                                                                state && state.editable && (
                                                                    <>
                                                                        <div class="live-up border-bottom pb-2">
                                                                            <div class="status-live">
                                                                                <span>Status: </span>
                                                                                <span class="live1">{data?.data?.isArchive ? 'Draft' : 'Live'}</span>
                                                                            </div>
                                                                            <div class="status-live">
                                                                                <span>Updated: </span>
                                                                                <span class="live1">{moment(data?.data?.updatedAt).format('YYYY-MM-DD')}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="live_body border-bottom py-2">
                                                                            <button type='button' onClick={() => duplicateTest()} class="btn p-0 duplicate_btn d-block">
                                                                                <span class="me-1">
                                                                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z"></path>
                                                                                    </svg>
                                                                                </span>
                                                                                <span class="dup_test">Duplicate Test</span>
                                                                            </button>
                                                                            <button type='button' onClick={() => deleteTest()} class="btn p-0 delete-test-parent d-block">
                                                                                <span class="me-1">
                                                                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path d="M15 2H9c-1.103 0-2 .897-2 2v2H3v2h2v12c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V8h2V6h-4V4c0-1.103-.897-2-2-2zM9 4h6v2H9V4zm8 16H7V8h10v12z"></path>
                                                                                    </svg>
                                                                                </span>
                                                                                <span class="del_test">Delete Test</span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            <div>
                                                                <div className="pre_button mt-2">
                                                                    <button id="draft-btn" type='submit' className="btn left_top_button m-1" value={"draft"} onClick={() => setIsPublished(false)}>
                                                                        Move To Draft
                                                                    </button>
                                                                    <button id="publish_btn" type='submit' className="btn left_top_button fs-6 m-1" value={"publish"} onClick={() => setIsPublished(true)}>
                                                                        {state ? state.editable ? 'Update & Publish' : 'Publish' : 'Publish'}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </form>


                                                {/* Test Category Section Start */}
                                                <div className="rectangle-parent16 mt-4">
                                                    <div className="d-md-flex align-items-center justify-content-between border-bottom mb-4 pb-2">
                                                        <div>
                                                            <b className="questions-categories">
                                                                Question's Categories{" "}
                                                            </b>
                                                            <span></span>
                                                            <span className="report">
                                                                <Link className="report"> (Displayed in report){" "} </Link>
                                                            </span>
                                                            <BiInfoCircle />
                                                        </div>
                                                        <div className="displayed-in-report-container mt-2 mt-md-0">
                                                            <button className="add-category btn btn-outline-secondary px-lg-4" onClick={() => navigate(`/test/create-logical-test-category/${id}`)}>
                                                                <span className="me-1">Add Category</span>
                                                                <BiPlus />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="group-child37" />


                                                    {
                                                        data && data.data && data.data.categories && data?.data?.categories?.length > 0 ? (
                                                            <div className="no-questions-created">
                                                                <table className="table table-borderless table-sm">
                                                                    {
                                                                        data?.data?.categories.map((element) => (
                                                                            <tbody key={element._id} className='mb-2'>
                                                                                <tr className="tbl_row">
                                                                                    <td className="col-md-4">
                                                                                        {element?.name}
                                                                                    </td>
                                                                                    <td className="col-md-4 data_2">
                                                                                        <b>
                                                                                            {element?.totalQuestion}
                                                                                            <span> Questions</span>
                                                                                        </b>
                                                                                    </td>
                                                                                    <td className="table_icons justify-content-end">
                                                                                        <button className="custom_table_btn edit_btn" onClick={() => navigate(`/test/create-logical-test-category/${id}`, { state: { category: element } })}>
                                                                                            <img src="/logos/edit_logo.svg" alt="logo" />
                                                                                        </button>
                                                                                        <button className="custom_table_btn del_btn" onClick={() => deleteCategory(element._id)}>
                                                                                            <img src="/logos/delete_logo.svg" alt="logo" />
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        ))
                                                                    }
                                                                </table>
                                                            </div>
                                                        ) : (
                                                            <div className="no-questionsCreated">
                                                                No categories created yet!
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                                {/* Test Category Section End */}

                                                {/* Test Question Section Start */}
                                                <div className="rectangle-parent17 mt-4">
                                                    <div className="d-md-flex align-items-center justify-content-between border-bottom mb-4 pb-2">
                                                        <div>
                                                            <b className="questions">Questions</b>
                                                        </div>
                                                        <div className=" mt-2 mt-md-0">
                                                            <button className="add-question-parent btn btn-outline-secondary px-lg-4" onClick={() => navigate(`/test/create-logical-test-question/${id}`)}>
                                                                <span className="me-1">Add Question</span>
                                                                <BiPlus />
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="group-child37" />

                                                    {
                                                        data && data.data && data.data.questions && data?.data?.questions?.length > 0 ? (
                                                            <div className="no-categories-created">
                                                                <table className="table table-borderless table-sm">
                                                                    {
                                                                        data?.data?.questions.map((question) => (
                                                                            <tbody key={question._id}>
                                                                                <tr className="tbl_row">
                                                                                    <td className="col-md-4" dangerouslySetInnerHTML={{ __html: question?.question }} />
                                                                                    <td className="col-md-4 data_2">
                                                                                        <b>
                                                                                            {question?.category?.name}
                                                                                        </b>
                                                                                    </td>
                                                                                    <td className="col-md-4 data_2">
                                                                                        <b>
                                                                                            {question?.test_case?.length}
                                                                                            <span> Test Cases</span>
                                                                                        </b>
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="table_icons justify-content-end align-items-center h-100">
                                                                                            <button className="custom_table_btn edit_btn" onClick={() => navigate(`/test/create-logical-test-question/${id}`, { state: { question_id: question._id } })}>
                                                                                                <img src="/logos/edit_logo.svg" alt="logo" />
                                                                                            </button>
                                                                                            <button className="custom_table_btn del_btn" onClick={() => deleteQuestion(question._id)}>
                                                                                                <img src="/logos/delete_logo.svg" alt="logo" />
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        ))
                                                                    }
                                                                </table>
                                                            </div>
                                                        ) : (
                                                            <div className="no-questionsCreated">
                                                                No Questions created yet!
                                                            </div>
                                                        )
                                                    }

                                                </div>
                                                {/* Test Question Section End */}

                                                <div className="frame-group"></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}