import React, { useEffect, useState, useRef } from "react";
// import { PhoneInput } from "react-contact-number-input";
import CandidateHeader from "../component/CandidateHeader";
import { useNavigate, useParams } from "react-router-dom";
import "../../candidate/Candiate.scss";
import {
  postApplicant,
  validToken,
} from "../../../Redux/ApplicantReducer/action";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import testFailed from "../../assets/failed.png";
import { Modal } from "react-bootstrap";
import { PiUploadSimple } from "react-icons/pi";
import { ImCross } from "react-icons/im";
import PhoneInput, { parsePhoneNumber, isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import Spiner1 from '../../../Components/Spiner1';

const Login = (props) => {
  const phoneInputRef = useRef(null);
  const { testId, token, testType } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isValidFullName, setIsValidFullName] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidLink, setIsValidLink] = useState(true);
  const [isValidPdf, setIsValidPdf] = useState(true);

  const testToken = useSelector((state) => state.applicant.message);
  const testTokenError = useSelector((state) => state.applicant);
  const loading = useSelector((state) => state.applicant.addApplicantLoading);;
  const errorData = useSelector((state) => state.applicant.errorDataAdd);
  // const loading = useSelector((state) => state);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [uploadCv, setUploadCv] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [phone, setPhone] = useState(undefined);
  const [countryCode, setCountryCode] = useState();
  const [allNumber, setAllNumber] = useState(undefined);
  const [extraDetails, setExtraDetails] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [invalidError, setInvalidError] = useState("");
  const [show, setShowing] = useState(false);
  const [labelHandeler, setLabelHandeller] = useState("");
  const labelStyle = { top: '-13px', fontWeight: '600' };
  const defaultStyle = { top: '14px', fontWeight: '400' };

  const handlePhoneInputChange = (value) => {

    setAllNumber(value);
    if (value !== undefined) {
      if (isPossiblePhoneNumber(value)) {
        const pn = parsePhoneNumber(value);
        if (pn && isValidPhoneNumber(pn.nationalNumber, pn.country)) {
          setIsValidPhone(true);
          setPhone(pn.nationalNumber);
          setCountryCode(pn.countryCallingCode);
        } else {
          setIsValidPhone(false);
        }
      } else {
        setIsValidPhone(false);
      }
    }
  };
  const isValidUrl = (url) => {
    const urlRegex =
      /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    return urlRegex.test(url);
  };

  const checkValidEmail = (email) => {
    const validation = /^(?!.*(?:\.com.*\.com|\.in.*\.in|\.co.*\.co))[a-zA-Z0-9+?._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return validation.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    // setTimeout(() => {
    //   setIsValidFullName(true);
    //   setIsValidPhone(true);
    //   setIsValidEmail(true);
    //   setIsValidLink(true);
    //   setIsValidPdf(true);
    //   setInvalidError(" ");
    // }, 3000);
    e.preventDefault();
    setIsValidFullName(true);
    setIsValidPhone(true);
    setIsValidEmail(true);
    setIsValidLink(true);
    setIsValidPdf(true);
    setInvalidError("");
    const trimmedName = name.trim();
    const nameRegex = /^[a-zA-Z\s]+$/; // Allows only alphabets and spaces

    if (!trimmedName) {
      setIsValidFullName(false);
      setInvalidError("Please Provide Your Full Name");
    } else if (!nameRegex.test(trimmedName)) {
      setIsValidFullName(false);
      setInvalidError("Name should not contain special characters");
    }
    else if (!phone || allNumber === undefined) {
      setIsValidPhone(false);
      setInvalidError("Please Provide Your Phone Number");
    } else if (!isValidPhoneNumber(allNumber)) {
      setIsValidPhone(false);
      setInvalidError("Please Provide Valid Phone Number");
    } else if (!email) {
      setIsValidEmail(false);
      setInvalidError("Please Provide Your Email");
    } else if (!checkValidEmail(email)) {
      setIsValidEmail(false);
      setInvalidError("Please Provide Valid Email");
    } else if (!isValidUrl(linkedIn) && linkedIn !== "") {
      setIsValidLink(false);
      setInvalidError("Please Provide Valid Invalid Url");
    }

    // else if (selectedFile && !selectedFile?.type?.includes("pdf") && !selectedFile?.type?.includes("pdf")) {
    //   setIsValidPdf(false);
    //   setInvalidError("Only Pdf files are allowed");
    // }
    // else if (!selectedFile) {
    //   setIsValidPdf(false);
    //   setInvalidError("CV Is Mandatory");
    // } 
    // else if (errorData?.response?.data?.error === "Invalid file type. Only PDF and DOC allowed.") {
    //   setIsValidPdf(false);
    //   setInvalidError("Only Pdf and Doc files are allowed");
    // }
    // else {
    else {
      dispatch(
        postApplicant(
          navigate,
          name,
          email,
          countryCode,
          phone,
          extraDetails,
          testId,
          linkedIn,
          selectedFile,
          testType
        )
      );
    }
    // }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };
  const handleFileChange = (e) => {

    setLabelHandeller("");
    const file = e.target.files[0];
    if (file && !file?.type?.includes("pdf") && !file?.type?.includes("pdf")) {
      setIsValidPdf(false);
      setInvalidError("Please Select File,Type .pdf");
    } else {
      setIsValidPdf(true);
      setInvalidError("")
      setSelectedFile(file);
      setUploadCv(e.target.value);
    }
  };
  const resetFile = () => {

    setSelectedFile('')
    setUploadCv('')

  }
  useEffect(() => {
    dispatch(validToken(token, navigate)).then((response) => {
      if (response.msg == "Token Invalid or Expired") {
        setShowing(true)
      }
    })
      .catch((err) => {
        console.log("err", err.message);
      });
  }, []);
  useEffect(() => {
    setIsValidFullName(true);
    setIsValidPhone(true);
    setIsValidEmail(true);
    setIsValidLink(true);
    setIsValidPdf(true);
    setInvalidError("");
    switch (labelHandeler) {

      case "name":
        !name && setIsValidFullName(false)
        break;
      case "phone":
        if (allNumber !== undefined) {
          isValidPhoneNumber(allNumber) && setIsValidPhone(true);
        } else {
          console.warn("invalid phone " + phone + " " + countryCode);
        }
        break;
      case "email":
        !checkValidEmail(email) && setIsValidEmail(false)
        break;
      case "linkedin":
        !isValidUrl(linkedIn) && linkedIn.length > 0 && setIsValidLink(false)
      default:
        break;
    }
  }, [labelHandeler]);
  return (

    <section className="log_in candidate">
      <div className="container">
        <CandidateHeader />
        <div className="login_area py-4">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-xl-5 col-md-8">
              {testToken ? (
                <div className="log_in_box p-4 p-lg-5">
                  <img
                    src="site_logo.png"
                    alt=""
                    className="img-fluid mobile_logo mx-auto mb-3"
                  />
                  <h3>Fill in Details</h3>
                  <div action="" className="row g-3">
                    <div className="col-12">
                      {/* <span style={{ color: "red", marginLeft: "100%" }}>*</span> */}
                      <label
                        style={
                          labelHandeler !== "name" && name === ""
                            ? defaultStyle
                            : labelStyle
                        }
                        htmlFor="name"
                        className="candidate-details-placeholder"
                        onClick={() => setLabelHandeller("name")}
                      >
                        Full Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        autoComplete="off"
                        // placeholder="Full Name"
                        value={name.trimStart()}
                        className={`form-control place_text ${isValidFullName ? "" : "is-invalid"
                          }`}
                        name="name"
                        onChange={(e) => {
                          setName(e.target.value.replace(/\d+/g, ""));
                          e.target.value.replace(/\d+/g, "").length > 0 ? setIsValidFullName(true) : setIsValidFullName(false);
                        }}
                        onKeyDown={handleKeyPress}
                        onFocus={() => setLabelHandeller("name")}
                      />
                      {!isValidFullName && (
                        <div >
                          <p className="text-danger" style={{ textAlign: 'left' }}>
                            {invalidError}
                          </p>

                        </div>
                      )}
                    </div>
                    <div className="col-12">
                      <label
                        style={
                          labelHandeler !== "phone" && allNumber === undefined
                            ? defaultStyle
                            : labelStyle
                        }
                        htmlFor="phone"
                        className="candidate-details-placeholder"
                        onClick={() => setLabelHandeller("phone")}
                      >
                        Phone No<span style={{ color: "red" }}>*</span>
                      </label>
                      {labelHandeler !== "phone" && allNumber === undefined ?
                        <input
                          type="text"
                          className="form-control place_text"
                          onFocus={() => {
                            setLabelHandeller("phone");
                            // this timeout is added to make it asyncronous only 
                            // because at the time of clicking the ref is not there
                            setTimeout(() => {
                              phoneInputRef.current.focus()
                            }, 0);
                          }}
                        />
                        : <div

                          className={`form-control place_text ${isValidPhone ? "" : "is-invalid"
                            }`}
                        >

                          <PhoneInput
                            id="phone"
                            autoComplete="off"
                            international
                            defaultCountry="IN"
                            maxLength="15"
                            ref={phoneInputRef}
                            value={allNumber?.trimStart()}
                            onChange={(e) => handlePhoneInputChange(e)}
                          />
                        </div>}
                      {/* {invalidError.replace(/[_\s]+/g, ' ')} */}
                      {!isValidPhone && (
                        <div className="text-danger"> {invalidError}</div>
                      )}
                    </div>
                    <div className="col-12">
                      <label
                        style={
                          labelHandeler !== "email" && email === ""
                            ? defaultStyle
                            : labelStyle
                        }
                        htmlFor="email"
                        className="candidate-details-placeholder"
                        onClick={() => setLabelHandeller("email")}
                      >
                        Email ID<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="email"
                        id="email"
                        // placeholder="Email ID *"
                        className={`form-control place_text ${isValidEmail ? "" : "is-invalid"
                          }`}
                        name="email"
                        autoComplete="off"
                        value={email.trimStart()}
                        onChange={(e) => {
                          setEmail(e.target.value);
                          checkValidEmail(email) ? setIsValidEmail(true) : setIsValidEmail(false);
                        }}
                        onKeyDown={handleKeyPress}
                        onFocus={() => setLabelHandeller("email")}
                      />
                      {!isValidEmail && (
                        <div className="text-danger"> {invalidError}</div>
                      )}
                    </div>

                    <div className="col-12">
                      <label
                        style={
                          labelHandeler !== "linkedin" && linkedIn === ""
                            ? defaultStyle
                            : labelStyle
                        }
                        htmlFor="linkedin"
                        className="candidate-details-placeholder"
                        onClick={() => setLabelHandeller("linkedin")}
                      >
                        LinkedIn
                      </label>
                      <input
                        type="text"
                        id="linkedin"
                        // placeholder="LinkedIn"
                        className={`form-control place_text ${isValidLink ? "" : "is-invalid"
                          }`}
                        name="linkedin"
                        value={linkedIn.trimStart()}
                        onChange={(e) => {
                          setLinkedIn(e.target.value);
                          isValidUrl(linkedIn) || linkedIn.length == 0 ? setIsValidLink(true) : setIsValidLink(false);
                        }
                        }
                        onKeyDown={handleKeyPress}
                        onFocus={() => setLabelHandeller("linkedin")}
                      />
                      {!isValidLink && (
                        <div className="text-danger"> {invalidError}</div>
                      )}
                    </div>

                    <div className="col-12">
                      <label
                        htmlFor="uploadCv"
                        className="candidate-details-placeholder"
                        style={{ visibility: 'hidden' }}
                      >uploadcv</label>
                      <div
                        style={{ cursor: "pointer" }}
                        className=" form-control d-flex align-items-center justify-content-between position-relative "
                      >
                        <p
                          style={{
                            fontSize: "16px",
                            color: "grey",
                            cursor: "pointer",
                          }}
                          className={`mb-0  ${uploadCv ? "url_txt" : ""}`}
                        >
                          {uploadCv ? uploadCv : "Upload Cv"}
                        </p>
                        <p className="mb-0 ps-3">
                          <span
                            style={{
                              fontSize: "12px",
                              color: "grey",
                              cursor: "pointer",
                            }}
                          >
                            {uploadCv ? null : ".pdf"}
                          </span>{" "}
                          <span className="position-relative">
                            {/* <input
                                style={{ cursor: "pointer" }}
                                type="file"
                                accept="application/pdf,.doc,.docx"
                                placeholder={
                                  uploadCv ? uploadCv : "upload cv *"
                                }
                                className={`form_control  ${uploadCv ? "d-block" : "d-none"
                                  }`}
                                name="upload Cv"
                                onChange={(e) => {handleFileChange(e)
                                }}
                                onKeyDown={handleKeyPress}
                              /> */}

                            {!uploadCv ? (
                              <PiUploadSimple
                                style={{
                                  cursor: "pointer",
                                  background: "#3AB2E4",
                                  color: "white",
                                  margin: "0px",
                                  height: "25px",
                                  width: "25px",
                                  padding: "3px",
                                  borderRadius: "50%",
                                }}
                              />
                            ) : (
                              <ImCross onClick={() => resetFile()} />
                            )}
                          </span>
                        </p>
                        <input
                          style={{
                            cursor: "pointer",
                            height: "100% ",
                            width: `${uploadCv ? "80%" : "100%"}`,
                          }}
                          accept="application/pdf"
                          type="file"
                          placeholder={uploadCv ? uploadCv : "upload cv "}
                          className={`form_control  ${"d-block"}`}
                          name="uploadCv"
                          id="uploadCv"
                          onChange={(e) => {
                            handleFileChange(e);

                          }}
                          onKeyDown={handleKeyPress}
                          onFocus={() => setLabelHandeller("")}
                        />
                      </div>
                      {!isValidPdf && (
                        <div className="text-danger"> {invalidError}</div>
                      )}
                    </div>

                    <div className="col-12">
                      <textarea
                        type="text"
                        rows="5"
                        placeholder="If you want you can provide link of your CV or Linkedin Profile"
                        className="form-control"
                        name="extraDetails"
                        onChange={(e) => {
                          setExtraDetails(e.target.value);
                        }}
                        onFocus={() => setLabelHandeller("")}
                        onKeyDown={handleKeyPress}
                      ></textarea>
                    </div>
                    <div className="px-2 d-flex justify-content-center w-100">
                      <button
                        disabled={loading}
                        onClick={handleSubmit}
                        className="btn btn-primary"
                      >
                        {loading ? <Spiner1 /> : "Next"}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                testTokenError?.errorDataGet?.response?.data?.msg == "The Token Is Invalid or Expired" ? (
                  <Modal
                    show={true}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header></Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                    <Modal.Body>
                      <div className="second_modal_box">
                        <div className="container-fluid">
                          <div className="row">
                            <div className=" col-12 mb-3">
                              <div className="second_modal_text-style d-flex align-items-center justify-content-center flex-column">
                                <img
                                  src={testFailed}
                                  className="img_style mb-3"
                                  alt="......."
                                />
                                <p>
                                  <span className="text-danger">
                                    {testTokenError?.errorDataGet?.response?.data?.msg}!
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                  </Modal>
                ) : (
                  <b className="fs-4 d-flex justify-content-center">
                    <div className="col-3">
                      <div
                        className="snippet d-flex align-items-center "
                        data-title="dot-flashing"
                      >
                        <span style={{ marginRight: "20px", color: "#896ffb" }}>
                          Loading
                        </span>
                        <div className="stage">
                          <div className="dot-flashing"></div>
                        </div>
                      </div>
                    </div>
                  </b>)
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
