import React, { useEffect, useState } from 'react'
import './candidates.css'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCandidate } from '../../Redux/Candidate/findCandidate/action';

const Candidateprofile = (Sidedata, onSidedataChange) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const id = useParams();
    const singleCandidate = useSelector((state) => state.findCandidate);
    const candidateData = singleCandidate.data.data;

    const [tabChange, setTabChange] = useState(false);
    const openExperienceTab = () => {
        setTabChange(true);
    }
    const openDetailTab = () => {
        setTabChange(false);
    }

    const backPage = () => {
        navigate("/candidates/all-candidates")
    }

    const copyLink = () => {
        const path = `/candidates/profile/${id?.id}`;
        const fullUrl = window.location.origin + path;

        navigator.clipboard.writeText(fullUrl).then(() => {
            toast.success("Link Copied!!");
        }).catch(err => {
            toast.error('Failed To Copy');
        });
    }


    useEffect(() => {
        dispatch(getCandidate(id.id, navigate))
    }, [])
    return (
        <>
            <div className='candidateProfilePage'>
                <div className="container">
                    <div className="row justify-content-center my-3">
                        <div className="col-xxl-8 col-xl-10 col-lg-11">
                            <div className="card candidateCard">
                                <div className="card-header">
                                    <div className="row g-3 align-items-center justify-content-between">
                                        <div className="col-auto">
                                            <h6>Candidate Profile: <span> {candidateData?.applied_for}</span></h6>
                                        </div>
                                        <div className="col-auto">
                                            <button className='btn btn-candidate me-1' onClick={() => copyLink()}>Copy Profile Link</button>
                                            {/* <button className='btn btn-candidate me-1' onClick={editPage}>Edit Profile</button> */}
                                            <button className='btn btn-candidate' onClick={backPage}>Back</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row g-3 align-items-center justify-content-between">
                                        <div className="col-12 col-sm-auto">
                                            <div className="candiateBasics">
                                                {
                                                    candidateData?.profile_image &&
                                                    <div className="candiateImage">
                                                        {/* <img src={candidateData?.profile_image} alt="" /> */}
                                                        <img src={candidateData?.profile_image?.replace("\/uploads\/profile\/", `${process.env.REACT_APP_BASE_URL}/uploads/profile/`)} alt="" />
                                                    </div>
                                                }
                                                {
                                                    !candidateData?.profile_image && candidateData?.gender == "Female" &&
                                                    <div className="candiateImage">
                                                        <img src="/images/female.png" alt="" />
                                                    </div>
                                                }
                                                {
                                                    !candidateData?.profile_image && candidateData?.gender == "Male" &&
                                                    <div className="candiateImage">
                                                        <img src="/images/male.jpg" alt="" />
                                                    </div>
                                                }
                                                <div className='text-center text-sm-start'>
                                                    <h5>{candidateData?.first_name} {candidateData?.last_name}</h5>
                                                    <p>  {candidateData?.experience_levels?.abbreviation} {candidateData?.designation?.name}</p>
                                                    <div style={{ fontSize: '14px' }}>
                                                        <i className="fas fa-map-marker-alt me-2"></i>
                                                        <span>{candidateData?.contact?.city}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-auto">
                                            <div className="compBasBtns">
                                                <button className='btn btn-outline-primary'>
                                                    <i className="fa-solid fa-cloud-arrow-down me-2"></i>
                                                    <Link to={`${candidateData?.resume}`}>
                                                        <span>Preview Resume</span>
                                                    </Link>
                                                </button>

                                                {candidateData?.contact?.phone_primary ? (<button className='btn btn-outline-primary'>
                                                    <i className="fa-solid fa-mobile-screen me-2"></i>
                                                    <span>{candidateData?.contact?.phone_primary}</span>
                                                </button>) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <div className="row justify-content-between g-3">
                                        {candidateData?.contact?.email_primary ? (<div className="col-sm-6">
                                            <h6>Email Address</h6>
                                            <a href="mailto:msaha21@yopmail.com" className='text-truncate'>
                                                <i className="fa-regular fa-envelope me-2"></i>
                                                <span>{candidateData?.contact?.email_primary}</span>
                                            </a>
                                        </div>) : null}
                                        {candidateData?.socials?.length > 0 ? (<div className="col-auto">
                                            <h6>Social Links</h6>
                                            {/* <a href="" target='_blank' className='me-2'><i className="fa-brands fa-square-facebook"></i></a> */}
                                            {candidateData?.socials?.map((e, i) => (
                                                <a href={e?.link} target='_blank' key={i}>
                                                    {/* <i className="fa-brands fa-linkedin"></i> */}
                                                    {e?.name}
                                                </a>))}
                                        </div>) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xxl-8 col-xl-10 col-lg-11">
                            <div className="row g-3">
                                <div className="col-md-8">
                                    <div className="bg-white p-4 rounded mb-2">
                                        <div className="candidateTabNav">
                                            <button onClick={openDetailTab} className={!tabChange && 'active'}>Details</button>
                                            <button onClick={openExperienceTab} className={tabChange && 'active'}>Work Experience</button>
                                        </div>
                                    </div>
                                    <div className="tabBodyArea">
                                        {!tabChange && <>
                                            {candidateData?.qualifications?.length > 0 ? (<div className="bg-white p-4 rounded">
                                                <h5 className='tabHeader'>Education Qualification</h5>
                                                {candidateData?.qualifications?.map((e, i) => (<div className="qualificationBlock" key={i}>
                                                    <h6>Degree in {e?.degree}</h6>
                                                    <p>{e?.insitute}</p>
                                                    <p>Passout - <span>{e?.session_end}</span></p>
                                                    <p>Status :{e?.status?.map((e, i) => (<span key={i}>{e}</span>))}
                                                    </p>
                                                </div>))}
                                            </div>) : null}
                                        </>}
                                        {tabChange && <>
                                            <div className="bg-white p-4 rounded mb-2">
                                                <h5 className='tabHeader'>Work Experience</h5>
                                                <div className="qualificationBlock">
                                                    {candidateData?.work_details?.last_company &&
                                                        <>
                                                            <h6 className='fs-6 fw-normal'>Currently Working: <span> {candidateData?.work_details?.currently_working}</span></h6>
                                                            <div className="border-dotted my-4"></div>
                                                        </>
                                                    }
                                                    <h6 className='fs-6 fw-normal'>Expected CTC: <span>{candidateData?.work_details?.expected_ctc}</span></h6>
                                                </div>
                                            </div>
                                            <div className="bg-white p-4 rounded mb-2">
                                                {/* <h5 className='tabHeader'>Job History</h5> */}
                                                <div className="qualificationBlock">
                                                    {/* <h6>Job History 1</h6> */}
                                                    <h6 className='fs-6 fw-normal'>Company Name: {candidateData?.work_details?.last_company}</h6>
                                                    <h6 className='fs-6 fw-normal'>Job Title:  <span>{candidateData?.work_details?.last_position}</span></h6>
                                                    <h6 className='fs-6 fw-normal'>Joining Date: <span> {candidateData?.work_details?.joining_date_month} {candidateData?.work_details?.joining_date_year}</span></h6>
                                                    <h6 className='fs-6 fw-normal'>End Date:  <span>{candidateData?.work_details?.termination_date_month} {candidateData?.work_details?.termination_date_year}</span></h6>
                                                </div>
                                            </div>
                                            {candidateData?.project_details?.some(project => project?.name && project?.link) ? (<div className="bg-white p-4 rounded mb-4">
                                                <h5 className='tabHeader'>Project Link</h5>
                                                {candidateData?.project_details.map((e, i) => (<>
                                                    <div className="qualificationBlock border-0" key={i}>
                                                        <h6>{e?.name}</h6>
                                                        <a href={e?.link} target='_blank' className='d-block txt__para text-truncate'>{e?.link}</a>
                                                    </div>
                                                    <a href={e?.link} target='_blank' className='btn btn-outline-primary px-5'>Visit</a>
                                                </>))}
                                            </div>) : null}
                                        </>}
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="bg-white p-4 rounded mb-2">
                                        <h5 className='tabHeader'>Lead Source</h5>
                                        <div className="qualificationBlock mb-0 pb-0">
                                            <h6>{candidateData?.lead_source_name?.name}</h6>
                                        </div>
                                    </div>
                                    <div className="bg-white p-4 rounded mb-2">
                                        {candidateData?.work_details?.job_preference_name?.length > 0 ? (<>
                                            <h5 className='tabHeader'>Job Preference</h5>
                                            {candidateData?.work_details?.job_preference_name?.map((e, i) => (< div className="qualificationBlock ps-0 border-0 mb-0" key={i}>
                                                <span className='badge'>{e}</span>
                                            </div>))}
                                            <div className="border-dotted mb-3"></div>
                                        </>) : null}

                                        {candidateData?.contact?.contact_time?.length > 0 ? (<>
                                            <h5 className='tabHeader'>Best time to connect</h5>
                                            {candidateData?.contact?.contact_time?.map((e, i) => (<p key={i}>{e}</p>))}
                                        </>) : null}

                                        {candidateData?.skills_list?.length > 0 ? (<>
                                            <div className='my-3'>
                                                <h5 className='tabHeader'>Skills</h5>
                                                {candidateData?.skills_list?.map((e, i) => (<div className="skillBadges mt-2" key={i}>
                                                    <span>{e?.name} ({e?.exp_year} Years {e?.exp_month} Months)</span>
                                                </div>))}
                                            </div>
                                        </>) : null}
                                    </div>

                                    {candidateData?.languages?.length > 0 ? (<div className="bg-white p-4 rounded mb-2">
                                        <h5 className='tabHeader'>Language</h5>
                                        {candidateData?.languages?.map((e, i) => (<div className="qualificationBlock" key={i}>
                                            <h6>{e?.name}</h6>
                                            <p>Language Rating: ({e?.rating}/10)</p>
                                        </div>))}
                                    </div>) : null}

                                    {candidateData?.system_hardware ? (<div className="bg-white p-4 rounded mb-4">
                                        <h5 className='tabHeader'>Hardware</h5>
                                        {
                                            candidateData?.system_hardware?.computer || candidateData?.system_hardware?.ram ? (

                                                <div className="qualificationBlock">
                                                    <h6>{candidateData?.system_hardware?.computer}</h6>
                                                    <p>{candidateData?.system_hardware?.ram}</p>
                                                </div>
                                            ) : null
                                        }
                                        <div className="qualificationBlock">
                                            <h6>Internet Type</h6>
                                            <p>{candidateData?.system_hardware?.internet_type}</p>
                                        </div>
                                        <div className="qualificationBlock mb-0 pb-0">
                                            <h6>Candidate will upgrade</h6>
                                        </div>
                                    </div>) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default Candidateprofile