import axios from "axios";
import {
  POST_TEST_SUCCESS,
  POST_TEST_FAILURE,
  POST_TEST_REQUEST,
  GET_TEST_REQUEST,
  GET_TEST_SUCCESS,
  GET_TEST_FAILURE,
  CREATE_TEST_REQUEST,
  CREATE_TEST_SUCCESS,
  CREATE_TEST_FAILURE,
  TEST_FILTER_PROGRAM_REQUEST,
  TEST_FILTER_PROGRAM_SUCCESS,
  TEST_FILTER_PROGRAM_FAILURE,
  TEST_FILTER_CUSTOM_REQUEST,
  TEST_FILTER_CUSTOM_SUCCESS,
  TEST_FILTER_CUSTOM_FAILURE,
  TEST_BY_ID_REQUEST,
  TEST_BY_ID_SUCCESS,
  TEST_BY_ID_FAILURE,
  TEST_BY_APPLICANT_ID_REQUEST,
  TEST_BY_APPLICANT_ID_SUCCESS,
  TEST_BY_APPLICANT_ID_FAILURE,
  STATE_CHANGE_REQUEST,
  STATE_CHANGE_SUCCESS,
  STATE_CHANGE_FAILURE,
  ARCHIVE_CHANGE_REQUEST,
  ARCHIVE_CHANGE_SUCCESS,
  ARCHIVE_CHANGE_FAILURE,
  CREATE_TOKEN_REQUEST,
  CREATE_TOKEN_SUCCESS,
  CREATE_TOKEN_FAILURE,
  SEND_MAIL_REQUEST,
  SEND_MAIL_SUCCESS,
  SEND_MAIL_FAILURE,
  CATEGORY_BY_TEST_REQUEST,
  CATEGORY_BY_TEST_SUCCESS,
  CATEGORY_BY_TEST_FAILURE,
  DELETED_TEST_FAILURE,
  DELETED_TEST_SUCCESS,
  DELETED_TEST_REQUEST,
  DELETE_TEST_PERMANENTLY_FAILURE,
  DELETE_TEST_PERMANENTLY_SUCCESS,
  DELETE_TEST_PERMANENTLY_REQUEST,
  TEST_SEARCH_REQUEST,
  TEST_SEARCH_SUCCESS,
  TEST_SEARCH_FAILURE,
  TEST_DUPLICATE_REQUEST,
  TEST_DUPLICATE_SUCCESS,
  TEST_DUPLICATE_FAILURE,
  LOGICAL_TEST_REQUEST,
  LOGICAL_TEST_SUCCESS,
  LOGICAL_TEST_FAILURE,
} from "./actionType";
import { toast } from "react-toastify";
import { errorCall } from "../ErrorHandling/action";



export const Post_Test_Req = (data) => ({
  type: POST_TEST_REQUEST,
  payload: data,
});
export const Post_Test_Succ = (data) => ({
  type: POST_TEST_SUCCESS,
  payload: data,
});
export const Post_Test_Fail = (error) => ({
  type: POST_TEST_FAILURE,
  payload: error,
});

export const postTest = (webToken, navigate, urlPath, title, testType) => {
  return async function test(dispatch, getState) {
    dispatch(Post_Test_Req());
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/addtest`,
        {
          title: title.title,
          testType
        },
        {
          headers: {
            Authorization: `Bearer ${webToken}`,
          },
        }
      );
      dispatch(Post_Test_Succ(response?.data?.result._id));
      if (response.data) {
        toast.success('Test Added Successfully');
        const url = urlPath;
        const baseUrl = process.env.REACT_APP_FRONTEDND_DOMAIN;
        const parts = url?.split(baseUrl);
        const setTestId = parts[1]?.replace("/test-add", "");
        navigate(
          `/test/create-test-screen/${response?.data?.result._id}`
        );
      }
    } catch (error) {
      console.log(error?.response?.data?.msg);
      toast.warning(error?.response?.data?.msg);
      if (error?.message === "Network Error" || error?.response?.status === 500) {
        dispatch(errorCall(error))
        return;
      }
      dispatch(Post_Test_Fail(error));
      if (error?.response?.data?.message === "z") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  };
};

export const Get_Test_Req = (data) => ({
  type: GET_TEST_REQUEST,
  payload: data,
});
export const Get_Test_Succ = (data) => ({
  type: GET_TEST_SUCCESS,
  payload: data,
});
export const Get_Test_Fail = (error) => ({
  type: GET_TEST_FAILURE,
  payload: error,
});

//Get test details by test id
export const getTest = (webToken, id, navigate, testType = 'mcq') => {
  // if (!id) {
  //   return { msg: "Please Give Id" };
  // }
  return async function getTestThunk(dispatch, getState) {
    dispatch(Get_Test_Req());
    try {
      if (testType === 'logical-test') {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/logical-test/get-single/${id}`, {},
          {
            headers: {
              Authorization: `Bearer ${webToken}`,
            },
          }
        );
        dispatch(Get_Test_Succ(response?.data?.data));
        return response?.data?.data

      } else {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/gettest/${id}`,
          {
            headers: {
              Authorization: `Bearer ${webToken}`,
            },
          }
        );
        dispatch(Get_Test_Succ(response?.data?.result));
        return response?.data?.result
      }

    } catch (error) {
      if (error?.message == "Network Error" || error?.response?.status == 500 || error?.response?.status == 404) {
        dispatch(errorCall(error))
        dispatch(Get_Test_Fail(error));
        dispatch(errorCall(error))
      }
      else if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        dispatch(Get_Test_Fail(error.response?.data))
      }
      return error
    }
  };
};




export const Create_Test_Req = (data) => ({
  type: CREATE_TEST_REQUEST,
});
export const Create_Test_Succ = (data) => ({
  type: CREATE_TEST_SUCCESS,
  payload: data,
});
export const Create_Test_Fail = (error) => ({
  type: CREATE_TEST_FAILURE,
  payload: error,
});

//create test page details update
export const addCreateTest = (
  webToken,
  id,
  title,
  maxTime,
  testVersion,
  shuffleQuestions,
  isDraft,
  navigate,
  toaster
) => {
  return async function test(dispatch, getState) {
    dispatch(Create_Test_Req())
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/addcreatetest/${id}`,
        {
          title: title,
          maxTime: maxTime,
          testVersion: testVersion,
          isDraft: isDraft,
          shuffleQuestions: shuffleQuestions,
        },
        {
          headers: {
            Authorization: `Bearer ${webToken}`,
          },
        }
      );
      if (response.data) {
        if (response?.data?.result.isDraft === true) {
          dispatch(Create_Test_Succ("Test Moved To Draft"))
          if (!toaster) {
            toast.success("Test Moved To Draft Successfully");
          }
        } else if (response?.data?.result.isDraft === false) {
          dispatch(Create_Test_Succ("Test Published"))
          toast.success("Test Published Successfully");
        }
      }
    } catch (error) {
      if (error?.response?.data?.msg) {
        toast.warn(error?.response?.data?.msg)
      }
      if (error?.message == "Network Error" || error?.response?.status == 500) {
        dispatch(errorCall(error))
        dispatch(Create_Test_Fail(error))
      }
      else if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        dispatch(Create_Test_Fail(error?.response?.data));
      }
    }
  };
};


// export const Test_Filter_Prg_Req = (data) => ({
//   type: TEST_FILTER_PROGRAM_REQUEST,
//   payload: data,
// });
// export const Test_Filter_Prg_Succ = (data) => ({
//   type: TEST_FILTER_PROGRAM_SUCCESS,
//   payload: data,
// });
// export const Test_Filter_Prg_Fail = (error) => ({
//   type: TEST_FILTER_PROGRAM_FAILURE,
//   payload: error,
// });


//filter with type of test
// export const testFilterProgram = (webToken, navigate) => {

//   return async function test(dispatch, getState) {
//     dispatch(Test_Filter_Prg_Req())
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/test_type`,
//         {
//           testType: "program test",
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${webToken}`,
//           },
//         }
//       );
//       dispatch(Test_Filter_Prg_Succ(response?.data?.result));
//     } catch (error) {
//       if (error?.message == "Network Error" || error?.response?.status == 500) {
//         dispatch(errorCall(error))
//         dispatch(Create_Test_Fail(error))
//       }
//       else if (error?.response?.data?.message === "Unauthenticated") {
//         localStorage.removeItem("token");
//         navigate("/login");
//       } else {
//         dispatch(Test_Filter_Prg_Succ(error.response?.data))
//       }
//     }
//   };
// };


export const Test_Filter_Cus_Req = (data) => ({
  type: TEST_FILTER_CUSTOM_REQUEST,
  payload: data,
});
export const Test_Filter_Cus_Succ = (data) => ({
  type: TEST_FILTER_CUSTOM_SUCCESS,
  payload: data,
});
export const Test_Filter_Cus_Fail = (error) => ({
  type: TEST_FILTER_CUSTOM_FAILURE,
  payload: error,
});

//filter with type of test
export const testFilterCustom = (navigate, webToken, draft, archive, testType) => {
  const draFt = draft || false;
  return async function test(dispatch, getState) {
    dispatch(Test_Filter_Cus_Req())
    try {
      const drft = draFt ? draft : true;
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/test_type`,
        {
          testType: testType ? testType : "custom test",
          isDraft: draFt,
          isArchive: archive,
        },
        {
          headers: {
            Authorization: `Bearer ${webToken}`,
          },
        }
      );
      if (response?.data?.result) {
        dispatch(Test_Filter_Cus_Succ(response?.data?.result));
      }
    } catch (error) {
      if (error?.message === "Network Error" || error?.response?.status === 500) {
        dispatch(errorCall(error))
        return;
      }
      dispatch(Test_Filter_Cus_Fail(error));
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  };
};




export const Test_By_Id_Req = (data) => ({
  type: TEST_BY_ID_REQUEST,
  payload: data,
});
export const Test_By_Id_Succ = (data) => ({
  type: TEST_BY_ID_SUCCESS,
  payload: data,
});
export const Test_By_Id_Fail = (error) => ({
  type: TEST_BY_ID_FAILURE,
  payload: error,
});


//test details with test id
export const testById = (webToken, id, navigate) => {
  return async function test(dispatch, getState) {
    dispatch(Test_By_Id_Req())
    // dispatch(setResult([]))
    axios
      .get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/gettest/${id}`, {
        headers: {
          Authorization: `Bearer ${webToken}`,
        },
      })
      .then((res) => {
        dispatch(Test_By_Id_Succ(res.data.result));
        return "res"
      })
      .catch((error) => {
        if (error?.message === "Network Error" || error?.response?.status === 500) {
          dispatch(errorCall(error))
          return;
        }
        dispatch(Test_By_Id_Fail(error))
        if (error?.response?.data?.message === "Unauthenticated") {
          localStorage.removeItem("token");
          navigate("/login");
        }
        return error
      });
  };
};

export const Test_By_Applicant_Id_Req = (data) => ({
  type: TEST_BY_APPLICANT_ID_REQUEST,
  payload: data,
});
export const Test_By_Applicant_Id_Succ = (data) => ({
  type: TEST_BY_APPLICANT_ID_SUCCESS,
  payload: data,
});
export const Test_By_Applicant_Id_Fail = (error) => ({
  type: TEST_BY_APPLICANT_ID_FAILURE,
  payload: error,
});


export const testByIdApplicant = (id, navigate) => {
  return async function test(dispatch, getState) {
    dispatch(Test_By_Applicant_Id_Req())
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/applicant/gettestapplicant/${id}`
      )
      .then((res) => {
        dispatch(Test_By_Applicant_Id_Succ(res.data.result));
      })
      .catch((error) => {
        if (error?.message === "Network Error" || error?.response?.status === 500) {
          dispatch(errorCall(error))
          return;
        }
        dispatch(Test_By_Applicant_Id_Fail(error))
        if (error?.response?.data?.message === "Unauthenticated") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });
  };
};





// export const State_Change_Req = (data) => ({
//   type: STATE_CHANGE_REQUEST,
//   payload: data,
// });
// export const State_Change_Succ = (data) => ({
//   type: STATE_CHANGE_SUCCESS,
//   payload: data,
// });
// export const State_Change_Fail = (error) => ({
//   type: STATE_CHANGE_FAILURE,
//   payload: error,
// });

//change the state of test
// export const stateChange = (webToken, isDraft, isArchive, ids, status, navigate) => {
//   return async function test(dispatch, getState) {
//     dispatch(State_Change_Req())
//     await axios
//       .put(
//         `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/teststatus/${ids}`,
//         { isDraft: isDraft, isArchive: isArchive, status: status },
//         {
//           headers: {
//             Authorization: `Bearer ${webToken}`,
//           },
//         }
//       )
//       .then((res) => {
//         if (res) {
//           dispatch(State_Change_Succ("Success"))
//         }
//       })
//       .catch((error) => {
//         if (error?.message === "Network Error" || error?.response?.status === 500) {
//           dispatch(errorCall(error))
//           return;
//         }
//         dispatch(State_Change_Fail(error))
//         toast.warn(error.response?.data?.msg);
//         if (error?.response?.data?.message === "Unauthenticated") {
//           localStorage.removeItem("token");
//           navigate("/login");
//         }
//       });
//   };
// };




// export const Archive_Change_Req = (data) => ({
//   type: ARCHIVE_CHANGE_REQUEST,
//   payload: data,
// });
// export const Archive_Change_Succ = (data) => ({
//   type: ARCHIVE_CHANGE_SUCCESS,
//   payload: data,
// });
// export const Archive_Change_Fail = (error) => ({
//   type: ARCHIVE_CHANGE_FAILURE,
//   payload: error,
// });
//change the state of test
// export const archiveChange = (webToken, status, isDraft, isArchive, ids, navigate) => {
//   return async function test(dispatch, getState) {
//     dispatch(Archive_Change_Req())
//     await axios
//       .put(
//         `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/teststatus/${ids}`,
//         { status: status, isDraft: isDraft, isArchive: isArchive },
//         {
//           headers: {
//             Authorization: `Bearer ${webToken}`,
//           },
//         }
//       )
//       .then((res) => {
//         if (res) {
//           dispatch(Archive_Change_Succ("Successful"))
//         }
//       })
//       .catch((error) => {
//         if (error?.message === "Network Error" || error?.response?.status === 500) {
//           dispatch(errorCall(error))
//           return;
//         }
//         dispatch(Archive_Change_Fail(error))
//         if (error?.response?.data?.message === "Unauthenticated") {
//           localStorage.removeItem("token");
//           navigate("/login");
//         }
//       });
//   };
// };



export const Create_Token_Req = (data) => ({
  type: CREATE_TOKEN_REQUEST,
  payload: data,
});
export const Create_Token_Succ = (data) => ({
  type: CREATE_TOKEN_SUCCESS,
  payload: data,
});
export const Create_Token_Fail = (error) => ({
  type: CREATE_TOKEN_FAILURE,
  payload: error,
});

//token create for url of exam
export const createToken = (webToken, testId, navigate, type) => {
  return async function test(dispatch, getState) {
    dispatch(Create_Token_Req())
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/link`,
        {
          testId: testId,
        },
        {
          headers: {
            Authorization: `Bearer ${webToken}`,
          },
        }
      ).then((response) => {
        dispatch(Create_Token_Succ((
          `${process.env.REACT_APP_URL}candidate/${type != "custom test" ? "logical" : "mcq"}/${testId}/${response?.data?.result.testToken}`
        )
        ))
      }).catch((error) => {
        console.log(error);
      })
    } catch (error) {
      if (error?.message === "Network Error" || error?.response?.status === 500) {
        dispatch(errorCall(error))
        return;
      }
      dispatch(Create_Token_Fail(error))
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  };
};


export const Send_Mail_Req = (data) => ({
  type: SEND_MAIL_REQUEST,
  payload: data,
});
export const Send_Mail_Succ = (data) => ({
  type: SEND_MAIL_SUCCESS,
  payload: data,
});
export const Send_Mail_Fail = (error) => ({
  type: SEND_MAIL_FAILURE,
  payload: error,
});

//for sending test mail to aplicant
export const sendMail = (
  webToken,
  navigate,
  email,
  name,
  designation,
  expDate,
  testId,
  type

) => {
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  let year = currentDate.getFullYear();
  let month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed, so we add 1
  let day = currentDate.getDate().toString().padStart(2, '0');
  let formattedDate = `${year}-${month}-${day}`
  return async function test(dispatch, getState) {
    dispatch(Send_Mail_Req)
    const data = type == "Send" ? { email: email, name: name, designation: designation, expDate: expDate, testId: testId } : { email: " demo@mail.com", name: "Demo", designation: "Demo", expDate: formattedDate, testId: testId }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/mail`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data) {
        dispatch(Send_Mail_Succ(response.data.msg));
        return response
      } else {
        dispatch(Send_Mail_Succ("Unsuccessfull"));
        return "try again"
      }
    } catch (error) {
      dispatch(Send_Mail_Fail(error));
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
      if (error?.message === "Network Error" || error?.response?.status === 500) {
        dispatch(errorCall(error))
        return;
      }
    }
  };
};





// export const Category_By_Test_Req = (data) => ({
//   type: CATEGORY_BY_TEST_REQUEST,
//   payload: data,
// });
// export const Category_By_Test_Succ = (data) => ({
//   type: CATEGORY_BY_TEST_SUCCESS,
//   payload: data,
// });
// export const Category_By_Test_Fail = (error) => ({
//   type: CATEGORY_BY_TEST_FAILURE,
//   payload: error,
// });

// export const categoryByTest = (webToken, id, navigate) => {
//   return async function test(dispatch) {
//     dispatch(Category_By_Test_Req())
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/category/${id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${webToken}`,
//           },
//         }
//       );
//       if (response.data) {
//         dispatch(Category_By_Test_Succ(response?.data?.result));
//         return response?.data?.result
//       } else {
//         dispatch(Category_By_Test_Succ("Unsuccessfull"));
//       }
//     } catch (error) {
//       if (error?.message == "Network Error" || error?.response?.status == 500) {
//         dispatch(errorCall(error))
//         dispatch(Category_By_Test_Fail(error))
//       }
//       else if (error?.response?.data?.message === "Unauthenticated") {
//         localStorage.removeItem("token");
//         navigate("/login");
//         return
//       }
//       else { dispatch(Category_By_Test_Fail(error.response?.data)) }
//       return error
//     }
//   };
// };


export const Deleted_Test_Req = (data) => ({
  type: DELETED_TEST_REQUEST,
  payload: data,
});
export const Deleted_Test_Succ = (data) => ({
  type: DELETED_TEST_SUCCESS,
  payload: data,
});
export const Deleted_Test_Fail = (error) => ({
  type: DELETED_TEST_FAILURE,
  payload: error,
});


export const deletedTest = (navigate, webToken,) => {
  return async function test(dispatch, getState) {
    dispatch(Deleted_Test_Req())
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/getdeletedtest`,
        {
          headers: {
            Authorization: `Bearer ${webToken}`,
          },
        }
      )
      .then((res) => {
        dispatch(Deleted_Test_Succ(res.data.result));
      })
      .catch((error) => {
        if (error?.message === "Network Error" || error?.response?.status === 500) {
          dispatch(errorCall(error))
          return;
        }
        dispatch(Deleted_Test_Fail(error))
        if (error?.response?.data?.message === "Unauthenticated") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });
  };
};




export const Delete_Test_Permanently_Req = (data) => ({
  type: DELETE_TEST_PERMANENTLY_REQUEST,
  payload: data,
});
export const Delete_Test_Permanently_Succ = (data) => ({
  type: DELETE_TEST_PERMANENTLY_SUCCESS,
  payload: data,
});
export const Delete_Test_Permanently_Fail = (error) => ({
  type: DELETE_TEST_PERMANENTLY_FAILURE,
  payload: error,
});

export const testDeletePermanently = (navigate, webToken, id) => {
  return async function test(dispatch, getState) {
    dispatch(Delete_Test_Permanently_Req())
    axios
      .delete(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/testdelete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${webToken}`,
          },
        }
      )
      .then((res) => {
        dispatch(Delete_Test_Permanently_Succ(res));
        toast.success("Test Deleted")
      })
      .catch((error) => {
        if (error?.message === "Network Error" || error?.response?.status === 500) {
          dispatch(errorCall(error))
          return;
        }
        dispatch(Delete_Test_Permanently_Fail(error))
        if (error?.response?.data?.message === "Unauthenticated") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });
  };
};




export const Test_Search_Req = (data) => ({
  type: TEST_SEARCH_REQUEST,
  payload: data,
});
export const Test_Search_Succ = (data) => ({
  type: TEST_SEARCH_SUCCESS,
  payload: data,
});
export const Test_Search_Fail = (error) => ({
  type: TEST_SEARCH_FAILURE,
  payload: error,
});

export const testSearch = (navigate, webToken, dataLimit, value, page, testType, isDraft, status = true) => {
  return async function test(dispatch, getState) {
    if (testType === 'logical-test') {
      dispatch({ type: LOGICAL_TEST_REQUEST })

      axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/logical-test/get-all-test`, { params: { status, isArchive: isDraft, limit: dataLimit, search: value, page: page }, headers: { Authorization: `Bearer ${webToken}`, } }).then((response) => {
        dispatch({ type: LOGICAL_TEST_SUCCESS, payload: response.data.data, pagination: response.data.pagination, total_deleted: response.data.total_deleted });
      }).catch((error) => {
        if (error?.message === "Network Error" || error?.response?.status === 500) {
          return dispatch(errorCall(error))
        }

        dispatch({ type: LOGICAL_TEST_FAILURE, payload: error?.response?.data })

        if (error?.response?.data?.message === "Unauthenticated") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      })

    } else {
      dispatch(Test_Search_Req());

      axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/testpaginate`, { limit: dataLimit, search: value, page: page, testType: testType }, { headers: { Authorization: `Bearer ${webToken}`, }, }).then((res) => {
        dispatch(Test_Search_Succ(res));
      }).catch((error) => {
        if (error?.message === "Network Error" || error?.response?.status === 500) {
          return dispatch(errorCall(error))
        }
        dispatch(Test_Search_Fail(error))
        if (error?.response?.data?.message === "Unauthenticated") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });

    }

  };
};


export const Test_duplicate_Req = (data) => ({
  type: TEST_DUPLICATE_REQUEST,
  payload: data,
});
export const Test_duplicate_Succ = (data) => ({
  type: TEST_DUPLICATE_SUCCESS,
  payload: data,
});
export const Test_duplicate_Fail = (error) => ({
  type: TEST_DUPLICATE_FAILURE,
  payload: error,
});

export const testduplicate = (navigate, webToken, testId) => {
  return async function test(dispatch, getState) {
    dispatch(Test_duplicate_Req());
    return axios.post(
      `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/duplicate_test/${testId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${webToken}`,
        },
      }
    )
      .then((res) => {
        dispatch(Test_duplicate_Succ(res));
        return res.data.result
      }).then((response) => {
        toast.success("Test Duplicated Successfully")
        navigate(`/test/test-edit/${response?._id}`)
        return response
      })
      .catch((error) => {
        if (error?.message === "Network Error" || error?.response?.status === 500) {
          dispatch(errorCall(error))
          return;
        }
        dispatch(Test_duplicate_Fail(error))
        if (error?.response?.data?.message === "Unauthenticated") {
          localStorage.removeItem("token");
          navigate("/login");
          return
        }
        return error
      });
  };
};