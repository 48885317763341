import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import SettingsNavbar from '../Components/SettingsNavbar';
import { Link } from 'react-router-dom';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import Avatar from '../Components/settings/Avatar';
import { findDesignation } from '../Redux/settings/findDesignation/action';
import { editDesignation } from '../Redux/settings/updateDesignation/action';
import Spiner1 from '../Components/Spiner1';
import { toast } from 'react-toastify';

const UpdateDesignation = (props) => {
    let { id } = useParams();
    const [designationName, setDesignationName] = useState("");
    const [status1, setStatus] = useState("1");
    const designationList = useSelector(state => state.findDesignationS);
    const updateDesignationS = useSelector(state => state.updateDesignationS);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const changeSkilName = (e) => {
        setDesignationName(e.target.value);
    }
    const addDesignationA = (e) => {
        e.preventDefault()
        if (designationName.trim().length === 0) {
            toast.warning('please enter a designation');
            return;
        }
        dispatch(editDesignation(id, designationName, status1, navigate));
    }
    useEffect(() => {
        designationList?.data?.data?.name && setDesignationName(designationList?.data?.data?.name);
        designationList?.data?.data &&setStatus(designationList?.data?.data?.status);
    }, [designationList?.data?.data])


    useEffect(() => {
        dispatch(findDesignation(id, navigate));
        // setDesignationName(designationList?.data?.data?.name)
    }, [])
    return (
        <div>
            <SettingsNavbar navdata={{ header: "Update Designation", buttonText: "designation Button", buttonPath: "/settings/skillsuggetion" }} newSidedata={props.Sidedata} newonSidedataChange={props.onSidedataChange} />
               <div className="user-action-area">
                   <div className="container-fluid">
                       <div className="row">
                           <div className="col-md-12">
                               <Link to={"/settings/designation"}> <BsFillArrowLeftCircleFill /> Designation List</Link>
                           </div>
                       </div>
                   </div>
               </div>
               <div className="create-company-form placeholder-glow">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="type">
                                <span className='font-weight-bold ms-4' >Type</span>
                            </div>

                            <div className="form">
                                <div className="row g-2">
                                    <div className="col-md-4">
                                        <div className="position-relative list_search_fild">
                                            <input type="text" name="query" placeholder="Designation Name"
                                                aria-label="" value={designationName} className={`form-control coustom_P h-100 ${designationList?.isLoading?" placeholder":""}`} onChange={(e) => changeSkilName(e)} />
                                            <Avatar placeholder={designationList?.isLoading?" placeholder":""} name={designationName} size={"34px"} position="absolute" top="3px" left="5px" />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <select className={`col-sm-4 mb-2 form-control form-control-custom  ${designationList?.isLoading?" placeholder":""}`} value={status1} onChange={(e) => setStatus(e.target.value)} >
                                            <option value="1" >Active</option>
                                            <option value="0" >Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <button className={`btn btn-success button-outline choosed mt-3  ${designationList?.isLoading?"placeholder":""}`} disabled={designationList?.isLoading || updateDesignationS.isLoading} onClick={(e) => addDesignationA(e)}>
                                {designationList?.isLoading || updateDesignationS.isLoading ? <Spiner1 /> : "Update"}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateDesignation
