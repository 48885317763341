import axios from "axios";
import {
  RESULT_DATA_REQUEST,
  RESULT_DATA_SUCCESS,
  RESULT_DATA_FAILURE,
  RESULT_SINGLE_REQUEST,
  RESULT_SINGLE_SUCCESS,
  RESULT_SINGLE_FAILURE,
  RESULT_SINGLE_APPLICANT_REQUEST,
  RESULT_SINGLE_APPLICANT_SUCCESS,
  RESULT_SINGLE_APPLICANT_FAILURE,
  RESULT_REQUEST,
  RESULT_SUCCESS,
  RESULT_FAILURE,
  PAGINATION_REQUEST,
  PAGINATION_SUCCESS,
  PAGINATION_FAILURE,
  POST_RESULT_REQUEST,
  POST_RESULT_SUCCESS,
  POST_RESULT_FAILURE,
  UPDATE_RESULT_REQUEST,
  UPDATE_RESULT_SUCCESS,
  UPDATE_RESULT_FAILURE,
  UPDATE_MARKS_REQUEST,
  UPDATE_MARKS_SUCCESS,
  UPDATE_MARKS_FAILURE,
  ARCHIVE_REQUEST,
  ARCHIVE_SUCCESS,
  ARCHIVE_FAILURE,
  UNARCHIVE_ALL_REQUEST,
  UNARCHIVE_ALL_SUCCESS,
  UNARCHIVE_ALL_FAILURE,
  DOWNLOAD_RESULT_REQUEST,
  DOWNLOAD_RESULT_SUCCESS,
  DOWNLOAD_RESULT_FAILURE,
  AVERAGE_RESULT_REQUEST,
  AVERAGE_RESULT_SUCCESS,
  AVERAGE_RESULT_FAILURE,
  RESULT_SEARCH_REQUEST,
  RESULT_SEARCH_SUCCESS,
  RESULT_SEARCH_FAILURE,
  ALL_RESULT_REQUEST,
  ALL_RESULT_SUCCESS,
  ALL_RESULT_FAILURE,
  ANS_SHEET_REQUEST,
  ANS_SHEET_SUCCESS,
  ANS_SHEET_FAILURE,
} from "./actionType";
import { errorCall } from "../ErrorHandling/action";

// export const resultData_Req = ((data) => ({
//   type: RESULT_DATA_REQUEST,
// }))
// export const resultData_Succ = ((data) => ({
//   type: RESULT_DATA_SUCCESS,
//   payload: data
// }))
// export const resultData_Fail = ((error) => ({
//   type: RESULT_DATA_FAILURE,
//   payload: error
// }))

// export const resultsData = (webToken, page, isArchived, search, marks, status, navigate) => {
//   return async function getTestThunk(dispatch, getState) {
//     dispatch(resultData_Req())
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/result`,
//         {
//           isArchived: isArchived,
//           search: search,
//           page: page,
//           testMarks: marks,
//           testStatus: status
//         }, {
//         headers: {
//           Authorization: `Bearer ${webToken}`,
//         }
//       }
//       );
//       dispatch(resultData_Succ(response.data));
//     } catch (error) {
//       dispatch(resultData_Fail(error.response?.data));
//       if (error?.response?.data?.message === "Unauthenticated") {
//         localStorage.removeItem("token");
//         navigate("/login");
//       }
//     }
//   };
// };



export const singleResult_Req = ((data) => ({
  type: RESULT_SINGLE_REQUEST,
}))
export const singleResult_Succ = ((data) => ({
  type: RESULT_SINGLE_SUCCESS,
  payload: data
}))
export const singleResult_Fail = ((error) => ({
  type: RESULT_SINGLE_FAILURE,
  payload: error
}))
export const singleResult = (webToken, id, navigate) => {
  return async function getTestThunk(dispatch, getState) {
    dispatch(singleResult_Req())
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/singleresult/${id}`, {
        headers: {
          Authorization: `Bearer ${webToken}`,
        }
      }
      );
      if (response?.data?.result) {
        dispatch(singleResult_Succ(response?.data?.result));
      }
    } catch (error) {
      if (error?.message == "Network Error" || error?.response?.status == 500) {
        dispatch(errorCall(error))
      }
      dispatch(singleResult_Fail(error.response?.data))
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  };
};


export const singleAppResult_Req = ((data) => ({
  type: RESULT_SINGLE_APPLICANT_REQUEST,
}))
export const singleAppResult_Succ = ((data) => ({
  type: RESULT_SINGLE_APPLICANT_SUCCESS,
  payload: data
}))
export const singleAppResult_Fail = ((error) => ({
  type: RESULT_SINGLE_APPLICANT_FAILURE,
  payload: error
}))
export const singleResultApplicant = (id, navigate) => {
  return async function getTestThunk(dispatch, getState) {
    dispatch(singleAppResult_Req())
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/applicant/singleresultapplicant/${id}`, { responseType: "filtered" }
      );
      if (response.data.result) {
        dispatch(singleAppResult_Succ(response.data.result));
      }
    } catch (error) {
      singleAppResult_Fail(error.response?.data)
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  };
};


// export const Result_Req = ((data) => ({
//   type: RESULT_REQUEST,
// }))
// export const Result_Succ = ((data) => ({
//   type: RESULT_SUCCESS,
//   payload: data
// }))
// export const Result_Fail = ((error) => ({
//   type: RESULT_FAILURE,
//   payload: error
// }))
// export const result = (webToken, id, navigate) => {
//   return async function getTestThunk(dispatch, getState) {
//     dispatch(Result_Req())
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/singleresult/${id}`, {
//         headers: {
//           Authorization: `Bearer ${webToken}`,
//         }
//       }
//       );
//       dispatch(Result_Succ(response.data.result));
//     } catch (error) {
//       dispatch(Result_Fail(error.response?.data));
//       if (error?.response?.data?.message === "Unauthenticated") {
//         localStorage.removeItem("token");
//         navigate("/login");
//       }
//     }
//   };
// };



// export const paginate_Req = ((data) => ({
//   type: PAGINATION_REQUEST,
// }))
// export const paginate_Succ = ((data) => ({
//   type: PAGINATION_SUCCESS,
//   payload: data
// }))
// export const paginate_Fail = ((error) => ({
//   type: PAGINATION_FAILURE,
//   payload: error
// }))
// export const paginateData = (webToken, page, navigate) => {
//   return async function getTestThunk(dispatch, getState) {

//     dispatch(paginate_Req())
//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/result`, {
//         page: page
//       }, {
//         headers: {
//           Authorization: `Bearer ${webToken}`,
//         }
//       }
//       );
//       dispatch(paginate_Succ(response.data));
//     } catch (error) {
//       dispatch(paginate_Fail(error.response?.data))
//       if (error?.response?.data?.message === "Unauthenticated") {
//         localStorage.removeItem("token");
//         navigate("/login");
//       }
//     }
//   };
// };







export const postRes_Req = ((data) => ({ type: POST_RESULT_REQUEST, }))
export const postRes_Succ = ((data) => ({ type: POST_RESULT_SUCCESS, payload: data }))
export const postRes_Fail = ((error) => ({ type: POST_RESULT_FAILURE, payload: error }))

export const postResult = (navigate, testID, applicantID, type) => {
  return async function getTestThunk(dispatch, getState) {
    dispatch(postRes_Req())
    try {
      let response = ""

      if (type === "logical") {
        response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/applicant/applicant-logical-test/${applicantID}`, { testID: testID, });
      } else {
        response = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/applicant/applicanttest/${applicantID}`, { testID: testID, });
      }

      dispatch(postRes_Succ(response.data.result));
      if (response.data.result) {
        navigate(`/candidate-test/${type}/${testID}/${response.data.result._id}`);
      }
    } catch (error) {
      if (error?.message == "Network Error" || error?.response?.status == 500) {
        return dispatch(errorCall(error))
      }
      dispatch(postRes_Fail(error.response?.data));
    }
  };
};




export const updateRes_Req = ((data) => ({
  type: UPDATE_RESULT_REQUEST,
}))
export const updateRes_Succ = ((data) => ({
  type: UPDATE_RESULT_SUCCESS,
  payload: data
}))
export const updateRes_Fail = ((error) => ({
  type: UPDATE_RESULT_FAILURE,
  payload: error
}))
export const updateResult = (
  resultId,
  questionID,
  applicantAnswer,
  ansId,
  testStatus
) => {
  return async function getTestThunk(dispatch, getState) {
    dispatch(updateRes_Req())
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/applicant/updateapplicanttest/${resultId}`,
        {
          answers: {
            questionID: questionID,
            applicantAnswers: applicantAnswer,
            ansId: ansId,
          },
          testStatus: testStatus
        }
      );
      if (res) {
        dispatch(updateRes_Succ("Success"))
      }
    } catch (error) {
      if (error?.message == "Network Error" || error?.response?.status == 500) {
        dispatch(errorCall(error))
        return
      }
      dispatch(updateRes_Fail("Failed"))
    }
  };
};

export const marks_Req = ((data) => ({
  type: UPDATE_MARKS_REQUEST,
}))
export const marks_Succ = ((data) => ({
  type: UPDATE_MARKS_SUCCESS,
  payload: data
}))
export const marks_Fail = ((error) => ({
  type: UPDATE_MARKS_FAILURE,
  payload: error
}))
export const UpadateMarks = (testID, resultId, navigate) => {
  return async function getTestThunk(dispatch, getState) {
    dispatch(marks_Req())
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/applicant/marks/${testID}/${resultId}`);
      dispatch(marks_Succ(response.data.result));
      if (response.data.result) {
        // navigate(`/candidate-test/${testID}/${applicantID}`);
      }
    } catch (error) {
      if (error?.message === "Network Error" || error?.response?.status === 500) {
        dispatch(errorCall(error))
        return;
      }
      dispatch(marks_Fail("Failed"))
    }
  };
};



export const archive_Req = ((data) => ({
  type: ARCHIVE_REQUEST,
}))
export const archive_Succ = ((data) => ({
  type: ARCHIVE_SUCCESS,
  payload: data
}))
export const archive_Fail = ((error) => ({
  type: ARCHIVE_FAILURE,
  payload: error
}))
export const updateStatusArchive = (
  webToken,
  resultId,
  isArchived,
  navigate
) => {
  return async function getTestThunk(dispatch, getState) {
    dispatch(archive_Req())
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/archivestatus/${resultId}`,
        {
          isArchived: isArchived
        }, {
        headers: {
          Authorization: `Bearer ${webToken}`,
        }
      }
      );
      if (res) {
        dispatch(archive_Succ("Success"))
      }
    } catch (error) {
      if (error?.message === "Network Error" || error?.response?.status === 500) {
        dispatch(errorCall(error))
        return;
      }
      dispatch(archive_Fail(error))
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  };
};


export const unarchive_Req = ((data) => ({
  type: UNARCHIVE_ALL_REQUEST,
}))
export const unarchive_Succ = ((data) => ({
  type: UNARCHIVE_ALL_SUCCESS,
  payload: data
}))
export const unarchive_Fail = ((error) => ({
  type: UNARCHIVE_ALL_FAILURE,
  payload: error
}))

export const unarchiveAllResults = (
  webToken,
  isArchived,
  navigate
) => {
  return async function getTestThunk(dispatch, getState) {
    dispatch(unarchive_Req())
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/unarchiveall`,
        {
          isArchived: isArchived,
        }, {
        headers: {
          Authorization: `Bearer ${webToken}`,
        }
      }
      );
      if (res) {
        dispatch(unarchive_Succ("Success"))
      }
    } catch (error) {
      if (error?.message === "Network Error" || error?.response?.status === 500) {
        dispatch(errorCall(error))
        return;
      }
      dispatch(unarchive_Fail(error))
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  };
};

// export const download_Req = ((data) => ({
//   type: DOWNLOAD_RESULT_REQUEST,
// }))
// export const download_Succ = ((data) => ({
//   type: DOWNLOAD_RESULT_SUCCESS,
//   payload: data
// }))
// export const download_Fail = ((error) => ({
//   type: DOWNLOAD_RESULT_FAILURE,
//   payload: error
// }))

// export const downloadResult = (webToken, navigate) => {
//   return async function test(dispatch, getState) {
//     dispatch(download_Req())
//     axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/downloadresult`, {
//       headers: {
//         Authorization: `Bearer ${webToken}`,
//       }
//     }).then((res) => {
//       dispatch(download_Succ(res.data.result));
//     }).catch((error) => {
//       if (error?.message === "Network Error" || error?.response?.status === 500) {
//         dispatch(errorCall(error))
//         return;
//       }
//       dispatch(download_Fail(error))
//       if (error?.response?.data?.message === "Unauthenticated") {
//         localStorage.removeItem("token");
//         navigate("/login");
//       }
//     })
//   }
// }




// export const average_Req = ((data) => ({
//   type: AVERAGE_RESULT_REQUEST,
// }))
// export const average_Succ = ((data) => ({
//   type: AVERAGE_RESULT_SUCCESS,
//   payload: data
// }))
// export const average_Fail = ((error) => ({
//   type: AVERAGE_RESULT_FAILURE,
//   payload: error
// }))
// export const averageScore = (webToken, id, navigate) => {
//   return async function test(dispatch, getState) {
//     dispatch(average_Req())
//     axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/averagescore/${id}`, {
//       headers: {
//         Authorization: `Bearer ${webToken}`,
//       }
//     }).then((res) => {
//       dispatch(average_Succ(res.data))
//     }).catch((err) => {
//       if (err.message === "Network Error" || err.response.status === 500) {
//         dispatch(errorCall(err))
//         return;
//       }
//       if (err.response?.data?.message == "Unauthenticated") {
//         dispatch(average_Fail(err.response?.data?.message))
//         //   window.location.href = 'https://hirekolkata.klizos.com/login'
//         //  toast.warn("token is invalid")
//       }
//       return err
//     })
//   }
// }



export const search_Req = ((data) => ({
  type: RESULT_SEARCH_REQUEST,
}))
export const search_Succ = ((data) => ({
  type: RESULT_SEARCH_SUCCESS,
  payload: data
}))
export const search_Fail = ((error) => ({
  type: RESULT_SEARCH_FAILURE,
  payload: error
}))
export const resultSearch = (testType = 'MCQ', webToken, navigate, page, type, search, testMarks, include = false, testStatus, testName) => {
  return async function test(dispatch, getState) {
    dispatch(search_Req())
    if (testType === 'MCQ') {
      axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/results`, { page, type, search, testMarks, include, testStatus, testName }, { headers: { Authorization: `Bearer ${webToken}`, } }).then((res) => {
        dispatch(search_Succ(res?.data));
      }).catch((error) => {
        if (error?.message === "Network Error" || error?.response?.status === 500) {
          dispatch(errorCall(error))
          return;
        }
        dispatch(search_Fail(error))
        if (error?.response?.data?.message === "Unauthenticated") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      })
    } else {
      axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/logical-test/get-all-result`, { page, type, search, include }, { headers: { Authorization: `Bearer ${webToken}`, } }).then((res) => {
        dispatch(search_Succ(res?.data));
      }).catch((error) => {
        if (error?.message === "Network Error" || error?.response?.status === 500) {
          dispatch(errorCall(error))
          return;
        }
        dispatch(search_Fail(error))
        if (error?.response?.data?.message === "Unauthenticated") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      })
    }
  }
}

export const archiveResultSearch = (webToken, navigate, page) => {
  return async function test(dispatch, getState) {
    dispatch(search_Req())
    axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}api/logical-test/get-archive-result`, { page }, { headers: { Authorization: `Bearer ${webToken}`, } }).then((res) => {
      dispatch(search_Succ(res?.data));
    }).catch((error) => {
      if (error?.message === "Network Error" || error?.response?.status === 500) {
        dispatch(errorCall(error))
        return;
      }
      dispatch(search_Fail(error))
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    })
  }
}



export const allRes_Req = ((data) => ({
  type: ALL_RESULT_REQUEST,
}))
export const allRes_Succ = ((data) => ({
  type: ALL_RESULT_SUCCESS,
  payload: data
}))
export const allRes_Fail = ((error) => ({
  type: ALL_RESULT_FAILURE,
  payload: error
}))
export const allResult = (webToken, navigate) => {
  return async function test(dispatch, getState) {
    dispatch(allRes_Req())
    axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/allresult`, {
      headers: {
        Authorization: `Bearer ${webToken}`,
      }
    }).then((res) => {
      dispatch(allRes_Succ(res?.data?.result));
    }).catch((error) => {
      if (error?.message === "Network Error" || error?.response?.status === 500) {
        dispatch(errorCall(error))
        return;
      }
      dispatch(allRes_Fail(error));
      if (error?.response?.data?.message === "Unauthenticated") {
        localStorage.removeItem("token");
        navigate("/login");
      }
    })
  }
}



export const Ans_Sheet_Req = (data) => ({
  type: ANS_SHEET_REQUEST,
  payload: data,
});
export const Ans_Sheet_Succ = (data) => ({
  type: ANS_SHEET_SUCCESS,
  payload: data,
});
export const Ans_Sheet_Fail = (error) => ({
  type: ANS_SHEET_FAILURE,
  payload: error,
});

export const ansData = (navigate, webToken, id) => {
  return async function test(dispatch, getState) {
    dispatch(Ans_Sheet_Req());
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}api/admin/answersheet/${id}`,
        {
          headers: {
            Authorization: `Bearer ${webToken}`,
          },
        }
      )
      .then((res) => {
        dispatch(Ans_Sheet_Succ(res.data));
        // toast.success("Test Deleted")
      })
      .catch((error) => {
        if (error?.message === "Network Error" || error?.response?.status === 500 || error?.response?.status === 404) {
          dispatch(errorCall(error))
          return;
        }
        dispatch(Ans_Sheet_Fail(error))
        if (error?.response?.data?.message === "Unauthenticated") {
          localStorage.removeItem("token");
          navigate("/login");
        }
      });
  };
};