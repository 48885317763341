import React, { useEffect } from "react";
import { useState } from "react";
import "./css/CustomTest.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { testDeletePermanently, testSearch } from "../../Redux/TestReducer/action";
import { toast } from "react-toastify";
import { errorCall } from "../../Redux/ErrorHandling/action";
import Swal from "sweetalert2";
import Paginate from "./Pagination/Pagination";
import { HiChevronLeft } from "react-icons/hi";
import moment from "moment";
import axios from "../../config/axiosNode";

export default function TrashBin(props) {
    const tokenData = localStorage.getItem("token");
    const location = useLocation()?.state;

    const customload = useSelector((state) => state?.test);
    const { isLoadingCustom } = customload
    const [title] = useState("");
    const [testVersion] = useState("");
    const [toaster, setToaster] = useState(false);
    const [deletedTests, setDeletedTests] = useState();
    const [testType, setTestType] = useState("custom test");

    const searchedData = useSelector(state => state?.test?.testSearch);
    const { tests, pagination } = useSelector((state) => state.logicalTest)

    const dataLimit = 10;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const usersPermission = localStorage?.getItem("userPermission")
    const permissionList = usersPermission?.split(',');


    const handleRetrieve = async (id) => {
        if (testType === "logical-test") {

            axios.put(`api/revert-from-trash/${id}`).then((response) => {
                if (response.data.success) {
                    toast.success(response.data.message);
                    dispatch(testSearch(navigate, tokenData, dataLimit, searchData, page, testType, 'any', false));
                    navigate('/test/all-test');
                } else {
                    toast.error(response.data.message);
                }
            }).catch((error) => {
                if (error.message === "Network Error" || error.response.status === 500) {
                    dispatch(errorCall(error))
                    return;
                }
                if (error?.response?.data?.message === "Unauthenticated") {
                    localStorage.removeItem("token");
                    navigate("/login");
                }
                toast.error(error.response ? error.response.data.message : error.toString());
            })


        } else {

            if (toaster === false) {
                try {
                    const response = await axios.put(`api/admin/teststatus/${id}`, { status: false, isDraft: true, isArchive: false });
                    if (response) {
                        toast.success("Test retrieved and moved to draft");
                        navigate('/test/all-test');
                    }
                    setToaster(true);
                    setTimeout(() => {
                        setToaster(false);
                    }, 2000);
                } catch (error) {
                    if (error.message === "Network Error" || error.response.status === 500) {
                        dispatch(errorCall(error))
                        return;
                    }
                    if (error?.response?.data?.message === "Unauthenticated") {
                        localStorage.removeItem("token");
                        navigate("/login");
                    }
                }
            }
        }
    }

    useEffect(() => {
        if (!tokenData) {
            navigate("/login")
        }
    }, [navigate, tokenData]);

    const menuClick = () => {
        props.onSidedataChange(!props.newSidedata);
    };

    const deletedTestList = customload?.DeletedTest
    useEffect(() => {
        setDeletedTests(deletedTestList)
    }, [deletedTestList])



    const permanentDeleteTest = (e) => {
        const id = e.target.name
        dispatch(testDeletePermanently(navigate, tokenData, id))
        dispatch(
            testSearch(
                navigate,
                tokenData,
                dataLimit,
                searchData,
                page
            )
        );
    }

    const handleDeleteTest = async (e) => {
        if (toaster === false) {
            const result = await Swal.fire({
                title: 'Are you sure, you want to delete the test permanently?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3ab2e4',
                cancelButtonColor: 'grey',
                cancelButtonText: "No",
                confirmButtonText: 'Yes',

                customClass: {
                    confirmButton: 'Swal_confirm',
                    cancelButton: 'Swal_cancel',
                    icon: 'Swal_icon',
                    title: 'Swal_title'
                },
            });

            if (result.isConfirmed) {
                permanentDeleteTest(e)
            }
        }
    }

    const handleDelete = (test_id) => {
        Swal.fire({
            title: 'Do you want to delete the test permanently ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3ab2e4',
            cancelButtonColor: 'grey',
            cancelButtonText: "No",
            confirmButtonText: 'Yes',

            customClass: {
                confirmButton: 'Swal_confirm',
                cancelButton: 'Swal_cancel',
                icon: 'Swal_icon',
                title: 'Swal_title'
            },
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`api/logical-test/delete-test/${test_id}`).then((result) => {
                    if (result.data.success) {
                        Swal.fire({
                            title: "Test Deleted!",
                            text: result.data.message,
                            icon: "success"
                        }).then((result) => {
                            if (result.isConfirmed) {
                                dispatch(testSearch(navigate, tokenData, dataLimit, searchData, page, testType, 'any', false));
                            }
                        });
                    } else {
                        Swal.fire({
                            title: "Error",
                            text: "Failed to delete test. Please try again later.",
                            icon: "error"
                        });
                    }
                })
            }
        })
    }


    const [searchData, setSearchData] = useState("");
    const [page, setPage] = useState(1);

    const pageChange = (e) => {
        dispatch(testSearch(navigate, tokenData, dataLimit, searchData, e, testType, 'any', false));
        setPage(e);
        if (e === "previous") {
            // setPage(page - 1);
            dispatch(testSearch(navigate, tokenData, dataLimit, searchData, page - 1, testType, 'any', false));
            // if (live) {
            //     setTestList(searchedData?.data?.draftFalse?.tests);
            // } if (draft) {
            setDeletedTests(searchedData?.data?.statusTrue?.tests);
            // }
        } else if (e === "next") {
            // setPage(page + 1);
            dispatch(testSearch(navigate, tokenData, dataLimit, searchData, page + 1, testType, 'any', false));
            // if (live) {
            //     setTestList(searchedData?.data?.draftFalse?.tests);
            // } if (draft) {
            setDeletedTests(searchedData?.data?.statusTrue?.tests);
            // }
        }
    };



    // useEffect(() => {
    //     dispatch(deletedTest(navigate, tokenData));
    // }, [searchedData]);


    useEffect(() => {
        dispatch(testSearch(navigate, tokenData, dataLimit, searchData, page, testType, 'any', false));
    }, [dispatch, navigate, page, searchData, testType, tokenData])


    const handleSearch = (e) => {
        e.preventDefault();
        setPage(1);
        dispatch(testSearch(navigate, tokenData, dataLimit, searchData, page, testType, 'any', false));
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            if (searchData !== 0) {
                handleSearch(event);
            }
        }
    };

    const searchValue = (e) => {
        const newValue = e.target.value;
        setSearchData(newValue);
        setPage(1);
    };

    const handleType = (e) => {
        setTestType(e.target.value);
        e.preventDefault();
        setPage(1);
        // dispatch(testSearch(navigate, tokenData, dataLimit, searchData, page, e.target.value));
    };

    return (
        <div className="content_app">
            <div className="body_content">
                <div className="container-fluid">
                    <button className="fa_bars  d-lg-none" onClick={menuClick}>
                        <i className="fa-solid fa-bars fs-4"></i>
                    </button>
                    <div className="row">
                        <div className="col-12 hjklop_right">
                            <div className="container-fluid px-2 px-lg-3 px-xl-5 py-5 custom_test">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="custom_test_content">
                                            <div className="row align-items-end g-3">
                                                <div
                                                    className="Test_btn"
                                                    onClick={() =>
                                                        navigate(`/test/all-test`)
                                                    }
                                                >
                                                    {" "}
                                                    <HiChevronLeft className="fs-3" />
                                                    All Tests
                                                </div>
                                                <div className="col-md-12 order-1 order-md-1">
                                                    <div className=" d-flex align-items-center justify-content-sm-between justify-content-center flex-wrap" style={{ gap: '10px' }}>
                                                        <h4 className="custom_text fw-bold text-md-start text-center">
                                                            Trash Bin
                                                        </h4>
                                                        <div className="top_right justify-content-md-end col-md-12">
                                                            <div className="col-md-3" style={{ marginRight: "10px" }}>
                                                                <select className="form-control form-control-custom" onChange={(e) => handleType(e)} value={testType} style={{ height: "42px" }}>
                                                                    <option value="custom test">MCQ Test</option>
                                                                    <option value="logical-test">Logical Test</option>
                                                                </select>
                                                            </div>
                                                            <div className="d-flex right_inner">
                                                                <div className="input-group">
                                                                    <div className="form-control input_div d-flex">
                                                                        <input
                                                                            value={searchData ? searchData : ""}
                                                                            onChange={(e) => {
                                                                                searchValue(e);
                                                                            }}
                                                                            className="input_search w-100"
                                                                            type="search"
                                                                            placeholder="Search"
                                                                            name="search"
                                                                            autoComplete="off"
                                                                            onKeyDown={handleKeyPress}
                                                                        />
                                                                        <div className="setting_search">
                                                                        </div>
                                                                    </div>
                                                                    <div className="input-group-btn">
                                                                        {searchData === 0 ? (
                                                                            <button
                                                                                className="btn btn-default"
                                                                                type="submit"
                                                                                disabled
                                                                            >
                                                                                Search
                                                                            </button>
                                                                        ) : (
                                                                            <button
                                                                                onClick={handleSearch}
                                                                                className="btn btn-default"
                                                                                type="submit"
                                                                            >
                                                                                Search
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-8 order-3 order-md-2">
                                                    <div className="custom_tab_btns text-center text-md-start">
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table_box p-lg-4 mt-4">
                                                <div className="trash_bin_table">
                                                    <table className="table table-striped  table-sm ">
                                                        <thead id="table-header" className="thead-dark">
                                                            <tr className="table_row ">
                                                                <th>Test Name</th>
                                                                <th>Questions</th>
                                                                <th>Last Updated</th>
                                                                {permissionList?.includes("65bb976653e762c89b9af17c") && <th>Actions</th>}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                testType === 'logical-test' ? tests && tests.length > 0 ? tests.map((element) => (
                                                                    <tr className="tbl_row" key={element._id}>
                                                                        <th className="first_text py-2 border-top" value={title} onClick={() => { permissionList?.includes("65bb976653e762c89b9af17c") && navigate(`/test/test-edit/${element._id}`, { state: location }) }}>
                                                                            {element.title}{" "}
                                                                            <span className="after_first" value={testVersion}>
                                                                                v.{element.testVersion}
                                                                            </span>{" "}
                                                                        </th>
                                                                        <td className="py-2  border-top">
                                                                            <b>{element.total.question}</b>
                                                                            <span> Questions</span>
                                                                        </td>
                                                                        <td className="py-2  border-top">
                                                                            {moment(element.updatedAt).format('YYYY-MM-DD')}
                                                                        </td>
                                                                        {
                                                                            permissionList?.includes("65bb976653e762c89b9af17c") && (
                                                                                <td className="py-2  border-top">
                                                                                    <div className="table_icons">
                                                                                        <span name={element._id} className="btnclick" onClick={() => handleRetrieve(element._id)}>
                                                                                            {" "}
                                                                                            <button style={{ border: '0px', backgroundColor: 'transparent', fontSize: '27px' }} title="Retrieve">
                                                                                                <i className="fa-solid fa-recycle text-primary"></i>
                                                                                            </button>
                                                                                        </span>
                                                                                        <button className="custom_table_btn del_btn" name={element._id} value={element.title} onClick={() => handleDelete(element._id)} title="Delete"                                                                                    >
                                                                                            <img src="/logos/delete_logo.svg" alt="logo" name={element._id} value={element.title} />
                                                                                        </button>
                                                                                    </div>
                                                                                </td>
                                                                            )
                                                                        }
                                                                    </tr>
                                                                )) : (
                                                                    <tr>
                                                                        <td colSpan="4" style={{ textAlign: " center" }}>
                                                                            <b style={{ color: "grey" }}>No Data</b>
                                                                        </td>
                                                                    </tr>
                                                                ) : !isLoadingCustom && deletedTestList ? (searchedData?.data?.statusTrue?.tests?.length === 0 ? (
                                                                    <tr>
                                                                        <td colSpan="4" style={{ textAlign: " center" }}>
                                                                            <b style={{ color: "grey" }}>No Data</b>
                                                                        </td>
                                                                    </tr>
                                                                ) :
                                                                    searchedData?.data?.statusTrue?.tests?.length > 0 && (
                                                                        searchedData?.data?.statusTrue?.tests?.map((element, i) => (
                                                                            <tr className="tbl_row" key={i}>
                                                                                <th
                                                                                    className="first_text py-2 border-top"
                                                                                    value={title}
                                                                                    onClick={() => {
                                                                                        permissionList?.includes("65bb976653e762c89b9af17c") &&
                                                                                            navigate(
                                                                                                `/test/test-edit/${element._id}`, { state: location }
                                                                                            )
                                                                                    }
                                                                                    }

                                                                                >
                                                                                    {element.title}{" "}
                                                                                    <span
                                                                                        className="after_first"
                                                                                        value={testVersion}
                                                                                    >
                                                                                        v.{element.testVersion}
                                                                                    </span>{" "}
                                                                                </th>
                                                                                {element.questionsCount ?
                                                                                    <td className="py-2  border-top">
                                                                                        <b>{element.questionsCount}</b>
                                                                                        <span> Questions</span>
                                                                                    </td>
                                                                                    : <td className="py-2  border-top">
                                                                                        <b>0</b>
                                                                                        <span> Questions</span>
                                                                                    </td>
                                                                                }
                                                                                <td className="py-2  border-top">
                                                                                    {element?.updatedAt?.slice(0, 10)}
                                                                                </td>
                                                                                {permissionList?.includes("65bb976653e762c89b9af17c") &&
                                                                                    <td className="py-2  border-top">
                                                                                        <div className="table_icons">
                                                                                            <span
                                                                                                name={element._id}
                                                                                                className="btnclick"
                                                                                                onClick={() =>
                                                                                                    handleRetrieve(element._id)
                                                                                                }
                                                                                            >
                                                                                                {" "}
                                                                                                <button style={{ border: '0px', backgroundColor: 'transparent', fontSize: '27px' }} title="Retrieve">
                                                                                                    <i className="fa-solid fa-recycle text-primary"></i>
                                                                                                </button>
                                                                                            </span>


                                                                                            <button
                                                                                                className="custom_table_btn del_btn"
                                                                                                name={element._id}
                                                                                                value={element.title}
                                                                                                onClick={(e) => handleDeleteTest(e)}
                                                                                                title="Delete"
                                                                                            >
                                                                                                <img
                                                                                                    src="/logos/delete_logo.svg"
                                                                                                    alt="logo"
                                                                                                    name={element._id}
                                                                                                    value={element.title}
                                                                                                />
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                }
                                                                            </tr>
                                                                        ))
                                                                    )) :
                                                                    <tr>
                                                                        <td colSpan={5}>
                                                                            <div className='d-flex align-items-center justify-content-center' style={{ textAlign: "center", height: '100px' }}>
                                                                                <div className="loader"></div>
                                                                            </div>
                                                                        </td></tr>}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <>
                        {
                            (testType === 'logical-test') ? tests.length > 0 && (
                                <div className="parentPage">
                                    <Paginate currentPage={pagination?.page} totalPages={pagination?.totalPages} onPageChange={(e) => pageChange(e)} />
                                </div>
                            ) : (
                                <div className="parentPage">
                                    <Paginate currentPage={page} totalPages={searchedData?.data?.statusTrue?.totalPages} onPageChange={(e) => pageChange(e)} />
                                </div>
                            )
                        }
                    </>
                </div>
            </div>
        </div>
    );
}